import { Constants, InvoiceTypeCode, WebFormMode } from '@dx-ui/dx-common/src';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import { Alert, AlertTitle } from '@material-ui/lab';
import React, { useCallback, useEffect, useState } from 'react';

import {
  FormDataConsumer,
  Loading,
  SimpleForm,
  useTranslate,
} from 'react-admin';
import { useFormState } from 'react-final-form';
import { DataHelpers } from '../../services';
import WebFormPopup from './WebFormPopup';
import { FormField } from './fields/FormField';
import { DynamicFormToolbar } from './form-toolbar';
import { DynamicFormControllerProps } from './useDynamicFormController';

const useStyles = makeStyles(
  (theme) => ({
    form: {
      minWidth: 1040,
    },
    circularProgress: {
      position: 'relative',
      left: '45%',
      top: '50%',
    },
    paper: {
      padding: theme.spacing(2),
    },
    errorContainer: { display: 'flex', justifyContent: 'center' },
    errorIcon: {
      marginRight: 10,
    },
  }),
  { name: 'DynamicForm' }
);

const DynamicFormView = (props: DynamicFormControllerProps) => {
  const {
    resource,
    record,
    template,
    selectValues,
    error,
    loading,
    saving,
    sending,
    readOnly,
    previewComponent,
    onSave,
    onSend,
    onPreview,
    basePath,
    mode,
    getFormDataService,
  } = props;

  const classes = useStyles();
  const translate = useTranslate();

  const sanitizeFormProps = ({
    onSave,
    onSend,
    onPreview,
    previewComponent,
    selectValues,
    loading,
    sending,
    error,
    template,
    mode,
    getFormDataService,
    ...rest
  }) => rest;

  const sanitizeFormDataConsumerProps = ({
    id,
    margin,
    record,
    scopedFormData,
    formData,
    mode,
    getFormDataService,
    ...rest
  }: any) => rest;

  if (error) {
    return (
      // temporary implementation for an error message
      <div className={classes.errorContainer}>
        <ErrorIcon className={classes.errorIcon} />
        <Typography variant='h6'>{translate(error)}</Typography>
      </div>
    );
  } else if (loading) {
    return <Loading />;
  } else if (!record) {
    return null;
  } else {
    return (
      <SimpleForm
        {...sanitizeFormProps(props)}
        className={classes.form}
        toolbar={
          <DynamicFormToolbar
            basePath={basePath}
            documentSaving={saving}
            documentSending={sending}
            onPreview={onPreview}
            onSave={onSave}
            onSend={onSend}
            isReadOnly={readOnly}
            resource={resource}
            record={record}
          />
        }
        record={record}
        variant='standard'
        sanitizeEmptyValues={false}
      >
        {/* DXPOR-5476 : Display popup when creating invoice from scratch */}
        {DataHelpers.getDocumentSubtypeCode(record as any) ===
          InvoiceTypeCode.COMMERCIAL && // 380 invoice
          DataHelpers.getInvoiceType(record as any) ===
            Constants.INVOICE_TYPE_FMF &&
          DataHelpers.getRecipientId(record as any) !== '' && // NO EFACTURA O2C
          mode === WebFormMode.SCRATCH_INVOICE && <WebFormPopup />}
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            !saving && readOnly && previewComponent ? (
              React.createElement<any>(previewComponent, {
                record: formData,
                resource,
              })
            ) : template ? (
              <FormContent
                {...sanitizeFormDataConsumerProps(rest)} // injected by SimpleForm
                record={formData}
                template={template}
                selectValues={selectValues}
                readOnly={readOnly || saving}
                mode={mode}
                getFormDataService={getFormDataService}
              />
            ) : (
              //TODO: handle better this case
              <span>No form template was found...</span>
            )
          }
        </FormDataConsumer>
      </SimpleForm>
    );
  }
};

const sanitizeFormFieldProps = ({ getFormDataService, ...rest }) => rest;

const FormContent = (props) => {
  const { template, mode, record, getFormDataService } = props;
  const translate = useTranslate();
  const [needSaveAtInit, setNeedSaveAtInit] = useState(true);

  const formState = useFormState();

  const submitErrors: string[] | undefined = formState.submitError;

  const isScratchMode = useCallback(() => {
    let isScratchMode = false;
    switch (mode) {
      case WebFormMode.SCRATCH_INVOICE:
      case WebFormMode.SCRATCH_WAYBILL:
      case WebFormMode.SCRATCH_DESPATCH_ADVICE:
      case WebFormMode.SCRATCH_RECEIPT_ADVICE:
      case WebFormMode.SCRATCH_ORDER:
        isScratchMode = true;
        break;
      default:
        isScratchMode = false;
    }

    return isScratchMode;
  }, [mode]);

  useEffect(() => {
    const saveAtInit = async () => {
      // Save the document at init only in case of creation from scratch
      await getFormDataService().saveData(record);
      setNeedSaveAtInit(false);
    };

    if (isScratchMode() && needSaveAtInit) {
      saveAtInit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getFormDataService, isScratchMode, needSaveAtInit]);

  return (
    <>
      <Grid container spacing={2}>
        {template.model.map((field, idx) => {
          return (
            <FormField
              key={idx}
              {...sanitizeFormFieldProps(props)}
              source={field.source} // need to set it here
              field={field}
            />
          );
        })}
      </Grid>

      {submitErrors?.length && (
        <Alert severity='error'>
          <AlertTitle>
            {translate('dxMessages.webForm.sendErrorTitle')}
          </AlertTitle>
          {submitErrors.map((errMsg, idx) => (
            <div key={idx}>{errMsg}</div>
          ))}
        </Alert>
      )}
    </>
  );
};

export default DynamicFormView;

import moment from 'moment-timezone';
import process from 'process';
import {
  Constants,
  DocumentTypeCode,
  EmptyValue,
  ProcessStatus,
} from '../constants/Constants';
import { UserRoles } from '../security/UserRoles';

/**
 * removes unexpected character as in 2018-09-21T00:00:00-07:00 => 2018-09-21T00:00:00-0700
 * and Set date to midnigth UTC
 */
const formatDateToAlfresco = (date) => {
  if (!date) {
    return;
  }
  // /!\ Don't use tz conversions after this point.

  // To be compliant with Alfresco we need to remove the semi-colon ':' in the offset
  // i.e : 2018-09-21T00:00:00-07:00 must be changed to 2018-09-21T00:00:00-0700
  // Dealing with tz === UTC or GMT special case (i.e. Zero offset).
  return moment
    .tz(date, 'UTC')
    .format()
    .replace(/Z$/, '+00:00')
    .replace(/:([^:]*)$/, '$1');
};

/**
 * removes unexpected character as in 2018-09-21T00:00:00-07:00 => 2018-09-21T00:00:00-0700
 * and Set date according to timezone
 */
const formatDateToAlfrescoTZ = (date) => {
  if (!date) {
    return;
  }
  const tz = getTz();
  // To be compliant with Alfresco we need to remove the semi-colon ':' in the offset
  // i.e : 2018-09-21T00:00:00-07:00 must be changed to 2018-09-21T00:00:00-0700
  // Dealing with tz === UTC or GMT special case (i.e. Zero offset).
  return moment
    .tz(date, tz)
    .format()
    .replace(/Z$/, '+00:00')
    .replace(/:([^:]*)$/, '$1');
};

const widthPresets = {
  tiny: { flex: 0.5, minWidth: 64 },
  small: { flex: 0.5, minWidth: 104 },
  medium: { flex: 1, minWidth: 168 },
  large: { flex: 2, minWidth: 272 },
  huge: { flex: 2, minWidth: 440 },
};

const cellStyle = {
  overflowWrap: 'break-word', // both overflow-wrap and word-wrap act
  // the same but needed here for MS-Egde, Safari and Chrome compatibility
  wordWrap: 'break-word',
  wordBreak: 'break-all', // Firefox
};

const merge = (x, y) => (y ? (x ? { ...x, ...y } : y) : x);

const columnWidthStyles = (width = widthPresets.medium) => {
  // width can be null, which disables width styles
  if (!width) {
    return {};
  }

  if (typeof width === 'number') {
    return { width, flexGrow: 0, flexShrink: 0 };
  }

  const { minWidth = 0, flex = 1 } = width;
  return {
    width: minWidth,
    flexGrow: flex,
    flexShrink: 0,
  };
};

//TODO: use resolveBrowserLocale() from react-admin instead of this custom solution ?
const getTz = () => window.Intl.DateTimeFormat().resolvedOptions().timeZone;

/**
 * Translates the resource name in order to match the I18N namespace
 * @param {*} resource react-admin resource name
 */
const getMessagePrefix = (resource) => {
  let messagePrefix;
  switch (resource) {
    case Constants.RESOURCE_INVOICE:
      messagePrefix = 'invoices';
      break;
    case Constants.RESOURCE_RECEIPT_ADVICE:
      messagePrefix = 'receiptAdvices';
      break;
    case Constants.RESOURCE_ORDER:
      messagePrefix = 'orders';
      break;
    case Constants.RESOURCE_DESPATCH_ADVICE:
      messagePrefix = 'despatchAdvices';
      break;
    case Constants.RESOURCE_FEEDBACK_MESSAGE:
      messagePrefix = 'feedbackMessages';
      break;
    case Constants.RESOURCE_FORECAST:
      messagePrefix = 'forecast';
      break;
    case Constants.RESOURCE_CONTRACT:
      messagePrefix = 'contract';
      break;
    case Constants.RESOURCE_CATALOG:
      messagePrefix = 'catalog';
      break;
    case Constants.RESOURCE_ATTACHMENT:
      messagePrefix = 'attachments';
      break;
    case Constants.RESOURCE_WAYBILL:
      messagePrefix = 'waybill';
      break;
    default:
      messagePrefix = 'dxDocuments';
  }
  return messagePrefix;
};

/**
 * Translates the Document Type Code to resource name
 * @param {*} documentTypeCode react-admin resource name
 */
const documentTypeCodeToResource = (
  documentTypeCode: DocumentTypeCode | undefined,
  processDocumentFormatType?: string
) => {
  let resource;
  switch (documentTypeCode) {
    case DocumentTypeCode.INVOIC:
      resource = Constants.RESOURCE_INVOICE;
      if (
        processDocumentFormatType &&
        processDocumentFormatType ===
          Constants.PROCESS_DOCUMENT_FORMAT_TYPE_DRAFT
      ) {
        resource = Constants.RESOURCE_WEBINVOICE;
      }
      break;
    case DocumentTypeCode.RECADV:
      resource = Constants.RESOURCE_RECEIPT_ADVICE;
      if (
        processDocumentFormatType &&
        processDocumentFormatType ===
          Constants.PROCESS_DOCUMENT_FORMAT_TYPE_DRAFT
      ) {
        resource = Constants.RESOURCE_WEBRECEIPT_ADVICE;
      }
      break;
    case DocumentTypeCode.ORDERS:
      resource = Constants.RESOURCE_ORDER;
      if (
        processDocumentFormatType &&
        processDocumentFormatType ===
          Constants.PROCESS_DOCUMENT_FORMAT_TYPE_DRAFT
      ) {
        resource = Constants.RESOURCE_WEBORDER;
      }
      break;
    case DocumentTypeCode.DESADV:
      resource = Constants.RESOURCE_DESPATCH_ADVICE;
      if (
        processDocumentFormatType &&
        processDocumentFormatType ===
          Constants.PROCESS_DOCUMENT_FORMAT_TYPE_DRAFT
      ) {
        resource = Constants.RESOURCE_WEBDESPATCH_ADVICE;
      }
      break;
    case DocumentTypeCode.APERAK:
      resource = Constants.RESOURCE_FEEDBACK_MESSAGE;
      break;
    case DocumentTypeCode.DELFOR:
      resource = Constants.RESOURCE_FORECAST;
      break;
    case DocumentTypeCode.CNTCND:
      resource = Constants.RESOURCE_CONTRACT;
      break;
    case DocumentTypeCode.CATLOG:
      resource = Constants.RESOURCE_CATALOG;
      break;
    case DocumentTypeCode.ATTACH:
      resource = Constants.RESOURCE_ATTACHMENT;
      break;
    case DocumentTypeCode.WAYBIL:
      resource = Constants.RESOURCE_WAYBILL;
      if (
        processDocumentFormatType &&
        processDocumentFormatType ===
          Constants.PROCESS_DOCUMENT_FORMAT_TYPE_DRAFT
      ) {
        resource = Constants.RESOURCE_WEBWAYBILL;
      }
      break;
    default:
      resource = Constants.RESOURCE_DOCUMENTS;
  }
  return resource;
};

/**
 * Translates the resource to DocumentTypeCode
 * @param {*} resource react-admin resource name
 */
const resourceToDocumentTypeCode = (resource: string) => {
  let documentTypeCode;
  switch (resource) {
    case Constants.RESOURCE_INVOICE:
    case Constants.RESOURCE_WEBINVOICE:
      documentTypeCode = DocumentTypeCode.INVOIC;
      break;
    case Constants.RESOURCE_RECEIPT_ADVICE:
    case Constants.RESOURCE_WEBRECEIPT_ADVICE:
      documentTypeCode = DocumentTypeCode.RECADV;
      break;
    case Constants.RESOURCE_ORDER:
    case Constants.RESOURCE_WEBORDER:
      documentTypeCode = DocumentTypeCode.ORDERS;
      break;
    case Constants.RESOURCE_DESPATCH_ADVICE:
    case Constants.RESOURCE_WEBDESPATCH_ADVICE:
    case Constants.RESOURCE_DESADV:
      documentTypeCode = DocumentTypeCode.DESADV;
      break;
    case Constants.RESOURCE_FEEDBACK_MESSAGE:
      documentTypeCode = DocumentTypeCode.APERAK;
      break;
    case Constants.RESOURCE_FORECAST:
      documentTypeCode = DocumentTypeCode.DELFOR;
      break;
    case Constants.RESOURCE_CONTRACT:
      documentTypeCode = DocumentTypeCode.CNTCND;
      break;
    case Constants.RESOURCE_CATALOG:
      documentTypeCode = DocumentTypeCode.CATLOG;
      break;
    case Constants.RESOURCE_ATTACHMENT:
      documentTypeCode = DocumentTypeCode.ATTACH;
      break;
    case Constants.RESOURCE_WAYBILL:
    case Constants.RESOURCE_WEBWAYBILL:
      documentTypeCode = DocumentTypeCode.WAYBIL;
      break;
    default:
      documentTypeCode = undefined;
  }
  return documentTypeCode;
};

/**
 * Translates the nodeType to resource name
 * @param {*} nodeType react-admin resource name
 */
const nodeTypeToResource = (nodeType) => {
  let resource;
  switch (nodeType) {
    case Constants.NODE_TYPE_INVOIC:
      resource = Constants.RESOURCE_INVOICE;
      break;
    case Constants.NODE_TYPE_RECADV:
      resource = Constants.RESOURCE_RECEIPT_ADVICE;
      break;
    case Constants.NODE_TYPE_ORDERS:
      resource = Constants.RESOURCE_ORDER;
      break;
    case Constants.NODE_TYPE_DESADV:
      resource = Constants.RESOURCE_DESPATCH_ADVICE;
      break;
    case Constants.NODE_TYPE_APERAK:
      resource = Constants.RESOURCE_FEEDBACK_MESSAGE;
      break;
    case Constants.NODE_TYPE_DELFOR:
      resource = Constants.RESOURCE_FORECAST;
      break;
    case Constants.NODE_TYPE_CNTCND:
      resource = Constants.RESOURCE_CONTRACT;
      break;
    case Constants.NODE_TYPE_CATLOG:
      resource = Constants.RESOURCE_CATALOG;
      break;
    case Constants.NODE_TYPE_ATTACH:
      resource = Constants.RESOURCE_ATTACHMENT;
      break;
    case Constants.NODE_TYPE_WAYBIL:
      resource = Constants.RESOURCE_WAYBILL;
      break;
    default:
      resource = Constants.RESOURCE_DOCUMENTS;
  }
  return resource;
};

/**
 * Returns the current application or dxpurchase by default.
 * Store the default one if no application is saved in localStorage.
 */
const getDxApplication = () => {
  let dxApplication;
  if (typeof localStorage !== 'undefined') {
    try {
      // Retrieves the current user
      const user = localStorage.getItem('user');
      // Get its current application
      dxApplication = localStorage.getItem(
        user + '.' + Constants.DXAPPLICATION
      );
      if (
        dxApplication === null ||
        dxApplication === 'undefined' ||
        dxApplication === Constants.DXNO_APP
      ) {
        // Takes dxpurchase if allowed and if not, takes the first allowed application
        const permissions = JSON.parse(
          localStorage.getItem(user + '.' + Constants.PERMISSIONS) || ''
        );
        if (
          permissions[UserRoles.DXPURCHASE_PRODUCT] &&
          permissions[UserRoles.DXPURCHASE_PRODUCT].find(
            (p: string) => p === UserRoles.DXPURCHASE_ACCESS
          )
        ) {
          dxApplication = Constants.DXPURCHASE_APP;
        } else if (
          permissions[UserRoles.DXARCHIVE_PRODUCT] &&
          permissions[UserRoles.DXARCHIVE_PRODUCT].find(
            (p: string) => p === UserRoles.DXARCHIVE_ACCESS
          )
        ) {
          dxApplication = Constants.DXARCHIVE_APP;
        } else if (
          permissions[UserRoles.DXCATALOG_PRODUCT] &&
          permissions[UserRoles.DXCATALOG_PRODUCT].find(
            (p: string) => p === UserRoles.DXCATALOG_ACCESS
          )
        ) {
          dxApplication = Constants.DXCATALOG_APP;
        } else if (
          permissions[UserRoles.DXUPLOAD_PRODUCT] &&
          permissions[UserRoles.DXUPLOAD_PRODUCT].find(
            (p: string) => p === UserRoles.DXUPLOAD_ACCESS
          )
        ) {
          dxApplication = Constants.DXUPLOAD_APP;
        } else {
          dxApplication = Constants.DXPURCHASE_APP;
        }
        localStorage.setItem(
          user + '.' + Constants.DXAPPLICATION,
          dxApplication
        );
      }
    } catch (ex) {
      // Not really an issue for us ( ex: No more space in local storage)
      // but can be reported anyway as a warning.
      // eslint-disable-next-line no-console
      console.warn(
        'unable to get the current App from local storage. returns ' +
          Constants.DXPURCHASE_APP
      );
      dxApplication = Constants.DXPURCHASE_APP;
    }
  }
  return dxApplication;
};

/**
 * Returns the application display name
 */
const getDxApplicationDisplayName = (dxApplication) => {
  let dxApplicationDisplayName = '';
  switch (dxApplication) {
    case Constants.DXPURCHASE_APP:
      dxApplicationDisplayName = Constants.DXPURCHASE_APP_DISPLAY;
      break;
    case Constants.DXARCHIVE_APP:
      dxApplicationDisplayName = Constants.DXARCHIVE_APP_DISPLAY;
      break;
    case Constants.DXCATALOG_APP:
      dxApplicationDisplayName = Constants.DXCATALOG_APP_DISPLAY;
      break;
    case Constants.DXUPLOAD_APP:
      dxApplicationDisplayName = Constants.DXUPLOAD_APP_DISPLAY;
      break;
    default:
      dxApplicationDisplayName = Constants.DX_APP_DISPLAY;
      break;
  }

  return dxApplicationDisplayName;
};

/**
 * Deep copy of an object. Ensures no references at all to the object.
 * @param {object} obj complex or simple object to clone
 */
const clone = (obj) => {
  var copy;

  // Handle the 3 simple types, and null or undefined
  if (null == obj || 'object' !== typeof obj) return obj;

  // Handle Date
  if (obj instanceof Date) {
    copy = new Date();
    copy.setTime(obj.getTime());
    return copy;
  }

  // Handle Array
  if (obj instanceof Array) {
    copy = [];
    for (var i = 0, len = obj.length; i < len; i++) {
      copy[i] = clone(obj[i]);
    }
    return copy;
  }

  // Handle Object
  if (obj instanceof Object) {
    copy = {};
    for (var attr in obj) {
      if (obj.hasOwnProperty(attr)) copy[attr] = clone(obj[attr]);
    }
    return copy;
  }

  throw new Error("Unable to copy obj! Its type isn't supported.");
};

/**
 * Returns a value into a record entry according to the object hierarchy of source
 * @param {object} record
 * @param {*} source source ex: properties.edm:issueDate
 */
const deepReturnRecordValue = (record, source) => {
  let value = source.split('.');
  if (value.length === 1) {
    return record[value[0]];
  }
  for (let i = 0; i <= value.length; i++) {
    value = record[value[i]];
  }
  return value;
};

const isDev = () => {
  return !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
};

/**
 * Returns all the keys of a complex object.
 * @param {object} obj
 */
const getDeepKeys = (obj) => {
  let keys: any[] = [];
  for (const key in obj) {
    if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
      const subkeys = getDeepKeys(obj[key]);
      keys = keys.concat(subkeys.map((subkey) => `${key}.${subkey}`));
    } else if (Array.isArray(obj[key])) {
      for (let i = 0; i < obj[key].length; i++) {
        if (typeof obj[key][i] == 'string') {
          keys.push(key);
        } else {
          const subkeys = getDeepKeys(obj[key][i]);
          keys = keys.concat(subkeys.map((subkey) => `${key}[${i}].${subkey}`));
        }
      }
    } else {
      keys.push(key);
    }
  }
  return keys;
};

/**
 * replaces all occurences of a string
 * @param stringToModify
 * @param stringToFind
 * @param replacementString
 */
const replaceAll = (stringToModify, stringToFind, replacementString) => {
  if (stringToFind === replacementString) return stringToModify;
  var index = stringToModify.indexOf(stringToFind);
  while (index !== -1) {
    stringToModify = stringToModify.replace(stringToFind, replacementString);
    index = stringToModify.indexOf(stringToFind);
  }
  return stringToModify;
};

/**
 * Returns the user bspId
 * returns the default one (DocProcess) if none.
 */
const getBspId = () => {
  let bspId;
  if (typeof localStorage !== 'undefined') {
    try {
      // Retrieves the current user
      const user = localStorage.getItem('user');
      // Get its current application
      bspId = localStorage.getItem(user + '.' + Constants.BSP_ID);
      bspId = replaceAll(bspId, '"', '');
    } catch (ex) {
      bspId = Constants.DEFAULT_BSP_ID;
    }
  }
  return bspId ? bspId : Constants.DEFAULT_BSP_ID;
};

/**
 * @param value Converts the number to a string with the specified number of decimals
 */
const formatNumericValue = (
  value: number,
  locale: string,
  minDigits: number = 2,
  maxDigits: number = 2
): string => {
  let formattedValue = EmptyValue;
  if (!isNaN(value)) {
    formattedValue = Intl.NumberFormat(locale, {
      minimumFractionDigits: minDigits,
      maximumFractionDigits: maxDigits,
    }).format(value);
  }

  return formattedValue;
};

/**
 * Tells if the UBL of the non web document is available according to the current document V3 Process Status
 * @param processStatus V3 process status
 */
const isUblAvailable = (processStatus: ProcessStatus): boolean => {
  let ublAvailable = true;
  switch (processStatus) {
    case ProcessStatus.FORMAT_ERROR:
    case ProcessStatus.DELIVERED_FROM_FORMAT_ERROR:
      ublAvailable = false;
      break;
    default:
      ublAvailable = true;
  }
  return ublAvailable;
};

/**
 * Date for Ubl
 */
const formatDate = (date: any): any => {
  if (!date) {
    return;
  }
  const newDate = moment(date).format('YYYY-MM-DD');
  return newDate;
};

/**
 * Checks if a resource is a web one
 * @param {*} resource react-admin resource name
 */
const isWebResource = (resource: string) => {
  let isWebResource = false;
  switch (resource) {
    case Constants.RESOURCE_WEBINVOICE:
    case Constants.RESOURCE_WEBRECEIPT_ADVICE:
    case Constants.RESOURCE_WEBDESPATCH_ADVICE:
    case Constants.RESOURCE_WEBWAYBILL:
    case Constants.RESOURCE_WEBORDER:
      isWebResource = true;
      break;
    default:
      isWebResource = false;
  }
  return isWebResource;
};

export {
  cellStyle,
  clone,
  columnWidthStyles,
  deepReturnRecordValue,
  documentTypeCodeToResource,
  formatDate,
  formatDateToAlfresco,
  formatDateToAlfrescoTZ,
  formatNumericValue,
  getBspId,
  getDeepKeys,
  getDxApplication,
  getDxApplicationDisplayName,
  getMessagePrefix,
  getTz,
  isDev,
  isUblAvailable,
  isWebResource,
  merge,
  nodeTypeToResource,
  replaceAll,
  resourceToDocumentTypeCode,
  widthPresets,
};

import {
  Constants,
  Metadata,
  ProcessStatus,
  sendGAEvent,
  WebFormMode,
} from '@dx-ui/dx-common';
import { FORM_ERROR } from 'final-form';
import { get, set } from 'lodash';
import { ComponentType, useCallback, useEffect, useState } from 'react';
import {
  SimpleFormViewProps,
  useDataProvider,
  useGetIdentity,
  useLocale,
  useNotify,
  useRedirect,
  useTranslate,
} from 'react-admin';
import { UpdateFormTemplateAction } from '../../actions/UpdateFormTemplate';
import { GA_EVENTS } from '../../GAUtils';
import {
  CatalogService,
  DespatchAdviceServiceCreator,
  DocumentServiceFactory,
  InvoiceServiceCreator,
  OrderServiceCreator,
  ReceiptAdviceServiceCreator,
  WaybillServiceCreator,
} from '../../services';
import { IDocumentService } from '../../services/IDocumentService';
import { P2pData, Template, WebFormData } from '../types';

export interface DynamicFormCustomControllerProps {
  template: Template | undefined;
  selectValues: any;
  error: any;
  loading: boolean;
  saving: boolean;
  sending: boolean;
  readOnly: boolean;
  previewComponent: ComponentType;
  onSave: (formData: P2pData) => any;
  onSend: (formData: P2pData) => any;
  onPreview: () => any;
  mode: WebFormMode;
  getFormDataService: () => IDocumentService;
}

export interface DynamicFormControllerProps
  extends Omit<SimpleFormViewProps, 'saving'>,
    DynamicFormCustomControllerProps {}

export interface DynamicFormCustomProps {
  readOnly: boolean;
  previewComponent: ComponentType;
  mode: WebFormMode;
  showToolbar: boolean;
  template: Template | null;
  data?: WebFormData;
  updateFormTemplate: (
    newTemplate: Template | null
  ) => UpdateFormTemplateAction;
}

export interface DynamicFormProps
  extends SimpleFormViewProps,
    DynamicFormCustomProps {}

/**
 * Dynamic Form data controller
 */
const useDynamicFormController = (
  props: DynamicFormProps
): DynamicFormControllerProps => {
  const {
    record: raEditRecord,
    data: initialData,
    mode,
    updateFormTemplate,
    previewComponent,
  } = props;

  const dataProvider = useDataProvider();
  const translate = useTranslate();

  const notify = useNotify();
  const redirect = useRedirect();
  const locale = useLocale();

  const [data, setData] = useState<any>(initialData);
  const [template, setTemplate] = useState<Template | undefined>(undefined);
  const [selectValues, setSelectValues] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const [documentSaving, setDocumentSaving] = useState(false);
  const [documentSending, setDocumentSending] = useState(false);

  const [error, setError] = useState<any>(undefined);
  const [readOnly, setReadOnly] = useState(false);

  const { identity } = useGetIdentity();
  // @ts-ignore
  const account: Account = identity;

  const getFormDataService = useCallback(() => {
    let formDataService: IDocumentService;
    switch (mode) {
      case WebFormMode.DRAFT_INVOICE:
      case WebFormMode.SCRATCH_INVOICE:
        formDataService = DocumentServiceFactory.create(
          InvoiceServiceCreator,
          dataProvider
        );
        break;
      case WebFormMode.DRAFT_WAYBILL:
      case WebFormMode.SCRATCH_WAYBILL:
        formDataService = DocumentServiceFactory.create(
          WaybillServiceCreator,
          dataProvider
        );
        break;
      case WebFormMode.DRAFT_DESPATCH_ADVICE:
      case WebFormMode.SCRATCH_DESPATCH_ADVICE:
        formDataService = DocumentServiceFactory.create(
          DespatchAdviceServiceCreator,
          dataProvider
        );
        break;
      case WebFormMode.DRAFT_RECEIPT_ADVICE:
      case WebFormMode.SCRATCH_RECEIPT_ADVICE:
        formDataService = DocumentServiceFactory.create(
          ReceiptAdviceServiceCreator,
          dataProvider
        );
        break;
      case WebFormMode.DRAFT_ORDER:
      case WebFormMode.SCRATCH_ORDER:
        formDataService = DocumentServiceFactory.create(
          OrderServiceCreator,
          dataProvider
        );
        break;
      default:
        throw new Error(`Unsupported mode: (${mode})`);
    }

    return formDataService;
  }, [dataProvider, mode]);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);

      try {
        if (!raEditRecord) {
          throw new Error(
            'Input Record (from react admin) should not be empty!'
          );
        }

        const recipientId = get(raEditRecord.properties, Metadata.recipientId);

        const recordId = raEditRecord.id as string | undefined;

        const webFormData = await getFormDataService().getData(
          recordId,
          recipientId,
          locale,
          raEditRecord.properties
        );

        setData(webFormData.documentData);
        setTemplate(webFormData.template);
        setSelectValues(webFormData.selectValues);

        setLoading(false);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        const errMsg = 'dxMessages.error_messages.form_load_error';

        notify(errMsg, 'error');

        setError(errMsg);

        setLoading(false);
      }
    };

    getData();

    return () => {
      updateFormTemplate(null); // hack to reset the template in the redux store
      // Invalid Catalog
      const catalogService = CatalogService.getInstance(dataProvider);
      catalogService._currentBuyerId = undefined;
    };
  }, [
    dataProvider,
    locale,
    mode,
    notify,
    updateFormTemplate,
    getFormDataService,
    raEditRecord,
  ]);

  /**
   * When an onChangeFunction reloading the template is called,
   * the props template is used prior to the TemplateService first load
   */
  useEffect(() => {
    // TODO : Temporary solution which calls pre-processing data
    const updateTemplate = async () => {
      if (props.template !== null) {
        try {
          if (!raEditRecord) {
            throw new Error(
              'Input Record (from react admin) should not be empty!'
            );
          }

          // will have a value for creation and edit
          let recipientId = get(raEditRecord.properties, Metadata.recipientId);
          if (recipientId === undefined) {
            // template metadata can be used as a media for recipientId
            // ex: OnChangeFunction.changeSupplierOrder() <-> OrderService.getData()
            recipientId = props.template.metadata?.[Metadata.recipientId];
          }
          const recordId = raEditRecord.id as string | undefined;

          const webFormData = await getFormDataService().getData(
            recordId,
            recipientId,
            locale,
            raEditRecord.properties,
            props.template
          );

          setTemplate(webFormData.template);
          setSelectValues(webFormData.selectValues);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
          const errMsg = 'dxMessages.error_messages.form_load_error';

          notify(errMsg, 'error');

          setError(errMsg);
        }
      }
    };

    updateTemplate();
  }, [
    updateFormTemplate,
    props.template,
    raEditRecord,
    getFormDataService,
    locale,
    notify,
  ]);

  /**
   * Redirects to the right list view according to mode
   */
  const redirectTo = useCallback(
    (mode, resource) => {
      if (
        mode === WebFormMode.DRAFT_INVOICE ||
        mode === WebFormMode.SCRATCH_INVOICE
      ) {
        redirect(`/${Constants.RESOURCE_INVOICE}`);
      } else if (
        mode === WebFormMode.DRAFT_DESPATCH_ADVICE ||
        mode === WebFormMode.SCRATCH_DESPATCH_ADVICE
      ) {
        redirect(`/${Constants.RESOURCE_WEBDESPATCH_ADVICE}`);
      } else if (
        mode === WebFormMode.DRAFT_RECEIPT_ADVICE ||
        mode === WebFormMode.SCRATCH_RECEIPT_ADVICE
      ) {
        redirect(`/${Constants.RESOURCE_WEBRECEIPT_ADVICE}`);
      } else if (
        mode === WebFormMode.DRAFT_ORDER ||
        mode === WebFormMode.SCRATCH_ORDER
      ) {
        redirect(`/${Constants.RESOURCE_WEBORDER}`);
      } else {
        throw new Error(`Unhandled resource ${resource}`);
      }
    },
    [redirect]
  );

  const handlePreview = () => {
    sendGAEvent(
      GA_EVENTS.categories.FORM.name,
      GA_EVENTS.categories.FORM.actions.PREVIEW,
      account?.company?.cmsRootDir,
      raEditRecord ? raEditRecord.properties[Metadata.dxuid] : undefined
    );

    setReadOnly(!readOnly);
  };

  const saveFormData = useCallback(
    async (formData: P2pData) => {
      const { id, properties } = await getFormDataService().saveData(formData);
      formData.id = id;
      formData.properties = properties;
    },
    [getFormDataService]
  );

  const handleSave = useCallback(
    async (formData: P2pData) => {
      setDocumentSaving(true);

      sendGAEvent(
        GA_EVENTS.categories.FORM.name,
        GA_EVENTS.categories.FORM.actions.SAVE,
        account?.company?.cmsRootDir,
        formData ? formData.properties[Metadata.dxuid] : undefined
      );

      try {
        set(formData.properties, Metadata.processStatus, ProcessStatus.DRAFT);
        await saveFormData(formData);

        const documentId = get(formData.properties, Metadata.documentId);
        documentId
          ? notify(
              'dxMessages.webForm.saved',
              'success',
              {
                documentId,
              },
              false /* not undoable */,
              5000 /* autoHideDuration ms */
            )
          : notify(
              'dxMessages.webForm.savedWithNoId',
              'success',
              null /* no messageArgs */,
              false /* not undoable */,
              5000 /* autoHideDuration ms */
            ); //this should not happen?

        setDocumentSaving(false);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error while saving document :', error);
        notify('dxMessages.webForm.errorSaving', 'error');
        setDocumentSaving(false);
      }
    },
    [account?.company?.cmsRootDir, notify, saveFormData]
  );

  /**
   *   Parses the error object.
   *
   *   Error coming from the back-end for errors in the 'validation flow'. Error format:
   *
   *   {
   *     "status": "NOK" | "OK" | "INTERNAL_ERROR",
   *     "internalError": null | string
   *     "dxuid": "DXZyVfUg9qQ2PZP3SpFizK0A",
   *     "errors": [
   *       "ROIF12 - Numarul clientului la Registrul Comertului J40/372/2003 este incorect.",
   *       "ROIC1.52 - Numarul clientului la Registrul Comertului J40/372/2003 este incorect."
   *     ]
   *   }
   *
   *
   * @param error backend error on submit
   * @returns an array with errors to display
   */
  const getSubmitErrors = useCallback(
    (error) => {
      const errors: string[] = [];
      if (error?.body?.status && error?.body.status !== 'OK') {
        if (error?.body?.internalError === 'vBadUbl') {
          errors.push(translate('dxMessages.webForm.sendInvalidUblError'));
        }

        error?.body?.errors?.forEach((msg) => {
          errors.push(msg);
        });
      }

      // if nothing was sent by the backend put in a generic error
      if (!errors.length) {
        errors.push(translate('dxMessages.webForm.sendUnknownError'));
      }

      return errors;
    },
    [translate]
  );

  const handleSend = useCallback(
    async (formData: P2pData) => {
      setDocumentSending(true);

      sendGAEvent(
        GA_EVENTS.categories.FORM.name,
        GA_EVENTS.categories.FORM.actions.SEND,
        account?.company?.cmsRootDir,
        formData ? formData.properties[Metadata.dxuid] : undefined
      );

      try {
        set(formData.properties, Metadata.processStatus, ProcessStatus.SENT);

        await saveFormData(formData);
        const id = formData?.id;
        if (!id) {
          throw new Error('This should not happen. Id is null in saved data.');
        }

        await getFormDataService().sendData(id as string);

        setDocumentSending(false);
        notify(
          'dxMessages.webForm.sent',
          'success',
          null /* no messageArgs */,
          false /* not undoable */,
          5000 /* autoHideDuration ms */
        );
        redirectTo(mode, Constants.RESOURCE_WEBDOCUMENT);
      } catch (error) {
        setDocumentSending(false);

        // set form submitErrors
        return { [FORM_ERROR]: getSubmitErrors(error) };
      }
    },
    [
      account?.company?.cmsRootDir,
      saveFormData,
      getFormDataService,
      notify,
      redirectTo,
      mode,
      getSubmitErrors,
    ]
  );

  const formTemplate = props.template || template;

  const sanitizeControllerProps: any = ({ updateFormTemplate, ...rest }) =>
    rest;

  return {
    ...sanitizeControllerProps(props),
    record: data,
    template: formTemplate,
    selectValues,
    error,
    loading,
    saving: documentSaving,
    sending: documentSending,
    readOnly,
    previewComponent,
    onSend: handleSend,
    onPreview: handlePreview,
    onSave: handleSave,
    getFormDataService,
  };
};

export default useDynamicFormController;

import {
  cellStyle,
  colors,
  DateInput,
  DxTheme,
  GenericDateField,
  GenericSimpleField,
  MultiSelectInput,
  RegulatorExtraDetailsType,
  widthPresets,
} from '@dx-ui/dx-common';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { TextInput, useTranslate } from 'react-admin';
import { DataHelpers } from '../services';

const useStyles = makeStyles(
  (theme: DxTheme) => ({
    statusIcon: {
      fontSize: 'medium',
      marginRight: 3,
      verticalAlign: 'middle',
      strokeWidth: 2,
    },
  }),
  { name: 'RegulatorStatusIcon' }
);

export const RegulatorUploadDateField = (props) => (
  <GenericDateField {...props} />
);

export const RegulatorUploadIdField = (props) => (
  <GenericSimpleField {...props} />
);

export const RegulatorStatusCodeIcon = (props) => {
  const { status } = props;
  const classes = useStyles();

  switch (status) {
    case '0':
    case '101':
    case '107':
    case '109':
    case '110':
      return (
        <RadioButtonUncheckedIcon
          style={{
            color: colors.functionalGreen,
            stroke: colors.functionalGreen,
          }}
          className={classes.statusIcon}
        />
      );

    case '10':
    case '20':
    case '102':
    case '103':
    case '106':
    case '108':
    case '111':
      return (
        <RadioButtonUncheckedIcon
          style={{
            color: colors.red[50],
            stroke: colors.red[50],
          }}
          className={classes.statusIcon}
        />
      );
    case '40':
    case '104':
    case '105':
    case '112':
      return (
        <RadioButtonUncheckedIcon
          style={{
            color: colors.functionalOrange,
            stroke: colors.functionalOrange,
          }}
          className={classes.statusIcon}
        />
      );

    default:
      return <></>;
  }
};

/**
 * Renders the Regulator Status Code value as a Field
 * @param {*} translate I18N translate function
 * @param {*} record current row values
 */
export const RegulatorStatusCodeField = ({ record = { properties: [] } }) => {
  const translate = useTranslate();

  const regulatorStatusCode = record.properties['edm:regulatorStatusCode'];
  return (
    <Typography>
      {<RegulatorStatusCodeIcon status={regulatorStatusCode} />}
      <span>
        {translate(`dxMessages.regulatorStatusCode.${regulatorStatusCode}`, {
          _: '--',
        })}
      </span>
    </Typography>
  );
};

/**
 * Renders Regulator Id info as a Field
 * @param {*} className
 * @param {*} record current row values
 */
export const RegulatorIdField = (props) => <GenericSimpleField {...props} />;

/**
 * Renders Regulator Extra Details info as a Field
 * @param {*} className
 * @param {*} record current row values
 */
export const RegulatorExtraDetails = ({ record = { properties: [] } }) => {
  const translate = useTranslate();

  const regulatorExtraDetails = DataHelpers.regulatorExtraDetails(record);
  if (regulatorExtraDetails === RegulatorExtraDetailsType.EDI_OUTBOUND) {
    return (
      <Typography>
        <span>
          {translate(
            `dxMessages.regulatorExtraDetails.${RegulatorExtraDetailsType.EDI_OUTBOUND}`
          )}
        </span>
      </Typography>
    );
  } else if (regulatorExtraDetails === RegulatorExtraDetailsType.EDI_INBOUND) {
    return (
      <Typography>
        <span>
          {translate(
            `dxMessages.regulatorExtraDetails.${RegulatorExtraDetailsType.EDI_INBOUND}`
          )}
        </span>
      </Typography>
    );
  } else if (
    regulatorExtraDetails === RegulatorExtraDetailsType.EFACTURA_OUTBOUND
  ) {
    return (
      <Typography>
        <span>
          {translate(
            `dxMessages.regulatorExtraDetails.${RegulatorExtraDetailsType.EFACTURA_OUTBOUND}`
          )}
        </span>
      </Typography>
    );
  } else if (
    regulatorExtraDetails === RegulatorExtraDetailsType.EFACTURA_INBOUND
  ) {
    return (
      <Typography>
        <span>
          {translate(
            `dxMessages.regulatorExtraDetails.${RegulatorExtraDetailsType.EFACTURA_INBOUND}`
          )}
        </span>
      </Typography>
    );
  } else if (regulatorExtraDetails === RegulatorExtraDetailsType.PEPPOL) {
    return (
      <Typography>
        <span>
          {translate(
            `dxMessages.regulatorExtraDetails.${RegulatorExtraDetailsType.PEPPOL}`
          )}
        </span>
      </Typography>
    );
  } else if (
    regulatorExtraDetails === RegulatorExtraDetailsType.PEPPOL_INBOUND
  ) {
    return (
      <Typography>
        <span>
          {translate(
            `dxMessages.regulatorExtraDetails.${RegulatorExtraDetailsType.PEPPOL_INBOUND}`
          )}
        </span>
      </Typography>
    );
  } else {
    return (
      <Typography>
        <span>--</span>
      </Typography>
    );
  }
};

/**
 * common columns
 */
export const createColumnsRegulatorDetails = () => [
  {
    id: 'edm:regulatorId',
    label: 'dxMessages.headers.regulatorId',
    sortable: false, // Alfresco doesn't allow sorting on d:text
    hiddenByDefault: true,
    width: widthPresets.medium,
    renderCell: (props) => <RegulatorIdField {...props} />,
  },
  {
    id: 'edm:regulatorExtraDetails',
    label: 'dxMessages.headers.regulatorExtraDetails',
    sortable: false, // Alfresco doesn't allow sorting on d:text
    hiddenByDefault: true,
    width: widthPresets.medium,
    renderCell: (props) => <RegulatorExtraDetails {...props} />,
  },
  {
    id: 'edm:regulatorStatusCode',
    label: 'dxMessages.headers.regulatorStatusCode',
    sortable: false, // Alfresco doesn't allow sorting on d:text
    hiddenByDefault: true,
    width: widthPresets.medium,
    cellStyle: cellStyle,
    readOnly: true,
    renderCell: (props) => <RegulatorStatusCodeField {...props} />,
  },
  {
    id: 'edm:regulatorUploadId',
    label: 'dxMessages.headers.regulatorUploadId',
    sortable: false, // Alfresco doesn't allow sorting on d:text
    hiddenByDefault: true,
    width: widthPresets.medium,
    cellStyle: cellStyle,
    renderCell: (props) => <RegulatorUploadIdField {...props} />,
  },
  {
    id: 'edm:regulatorUploadDate',
    label: 'dxMessages.headers.regulatorUploadDate',
    sortable: true,
    hiddenByDefault: true,
    width: widthPresets.medium,
    cellStyle: cellStyle,
    renderCell: (props) => <RegulatorUploadDateField {...props} />,
  },
];

/**
 * Common filters
 */
export const createRegulatorFilters = () => [
  {
    id: 'edm:regulatorId',
    filter: (props) => (
      <TextInput
        source='edm:regulatorId'
        label='dxMessages.headers.regulatorId'
        resettable
        {...props}
      />
    ),
  },
  {
    id: 'edm:regulatorExtraDetails',
    filter: (props) => (
      <MultiSelectInput
        source='edm:regulatorExtraDetails'
        label='dxMessages.headers.regulatorExtraDetails'
        width={widthPresets.large}
        resettable
        {...props}
        options={[
          {
            id: RegulatorExtraDetailsType.EDI_OUTBOUND,
            label: `dxMessages.regulatorExtraDetails.${RegulatorExtraDetailsType.EDI_OUTBOUND}`,
          },
          {
            id: RegulatorExtraDetailsType.EDI_INBOUND,
            label: `dxMessages.regulatorExtraDetails.${RegulatorExtraDetailsType.EDI_INBOUND}`,
          },
          {
            id: RegulatorExtraDetailsType.EFACTURA_OUTBOUND,
            label: `dxMessages.regulatorExtraDetails.${RegulatorExtraDetailsType.EFACTURA_OUTBOUND}`,
          },
          {
            id: RegulatorExtraDetailsType.EFACTURA_INBOUND,
            label: `dxMessages.regulatorExtraDetails.${RegulatorExtraDetailsType.EFACTURA_INBOUND}`,
          },
          {
            id: RegulatorExtraDetailsType.PEPPOL,
            label: `dxMessages.regulatorExtraDetails.${RegulatorExtraDetailsType.PEPPOL}`,
          },
          {
            id: RegulatorExtraDetailsType.PEPPOL_INBOUND,
            label: `dxMessages.regulatorExtraDetails.${RegulatorExtraDetailsType.PEPPOL_INBOUND}`,
          },
        ]}
      />
    ),
  },
  {
    id: 'edm:regulatorStatusCode',
    filter: (props) => (
      <MultiSelectInput
        label='dxMessages.headers.regulatorStatusCode'
        source='edm:regulatorStatusCode'
        width={widthPresets.large}
        sortLabels={true}
        {...props}
        options={[
          {
            id: '0',
            label: 'dxMessages.regulatorStatusCode.0',
          },
          {
            id: '10',
            label: 'dxMessages.regulatorStatusCode.10',
          },
          {
            id: '20',
            label: 'dxMessages.regulatorStatusCode.20',
          },
          {
            id: '40',
            label: 'dxMessages.regulatorStatusCode.40',
          },
          {
            id: '101',
            label: 'dxMessages.regulatorStatusCode.101',
          },
          {
            id: '102',
            label: 'dxMessages.regulatorStatusCode.102',
          },
          {
            id: '103',
            label: 'dxMessages.regulatorStatusCode.103',
          },
          {
            id: '104',
            label: 'dxMessages.regulatorStatusCode.104',
          },
          {
            id: '105',
            label: 'dxMessages.regulatorStatusCode.105',
          },
          {
            id: '106',
            label: 'dxMessages.regulatorStatusCode.106',
          },
          {
            id: '107',
            label: 'dxMessages.regulatorStatusCode.107',
          },
          {
            id: '108',
            label: 'dxMessages.regulatorStatusCode.108',
          },
          {
            id: '109',
            label: 'dxMessages.regulatorStatusCode.109',
          },
          {
            id: '110',
            label: 'dxMessages.regulatorStatusCode.110',
          },
          {
            id: '111',
            label: 'dxMessages.regulatorStatusCode.111',
          },
          {
            id: '112',
            label: 'dxMessages.regulatorStatusCode.112',
          },
        ]}
      />
    ),
  },
  {
    id: 'edm:regulatorUploadId',
    filter: (props) => (
      <TextInput
        source='edm:regulatorUploadId'
        label='dxMessages.headers.regulatorUploadId'
        resettable
        {...props}
      />
    ),
  },
  {
    id: 'edm:regulatorUploadDate',
    filter: (props) => (
      <DateInput
        source={'edm:regulatorUploadDate'}
        label='dxMessages.headers.regulatorUploadDate'
        {...props}
      />
    ),
  },
];

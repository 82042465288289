import { DomainMessages } from './DomainMessages';
/**
 * Hebrew I18N messages.
 * In order to not alterate w/ react-admin messages, the application messages must not be defined
 * under ${locale}:ra namespace (where locale = en, ro fr , etc ..).
 * Moreover, ${locale}:resources is the namespace reserved to interact w/ react-admin resources label and title.
 * See : https://marmelab.com/react-admin/Translation.html#translating-resource-and-field-names
 */
export const heDomainMessages: DomainMessages = {
  dxMessages: {
    // Hebrew Application messages
    dashboard: {
      categories: {
        general: 'General',
        'nway-matching': 'N-way matching',
        'cash-collection': 'Cash collection',
      },
      ConfigurationDescription:
        'Customize your dashboard by dragging and dropping widgets from below to the main page, moving them around, and  removing them as needed.',
      ConfigurationSingletonDescription:
        'This icon on the top right corner of some widgets means you can add it ' +
        'more than once on the dashboard, setting different configurations so ' +
        'each one displays different data.',
      DesignYourHomePage: 'Design your home page',
      LearnMore: 'Learn more',
      noAccessRightForDocXchange:
        'You do not own enough access rights to access docXchange. ' +
        'Your administrator may have forgotten to switch it on into your account. ' +
        'Please, contact him if you think you should.',
      NoData: 'No data',
      NoWidgetToAdd: 'No widget to add, all of them are already on screen.',
      OnErrorContactSupport:
        'Oups, an error occured. Please, retry later on or contact the DocProcess ' +
        'support (support@doc-process.com) [%{error}]',
      Welcome: 'Welcome %{firstname}',
      widgets: {
        DocumentReceptions: {
          title: 'Documents, per day and type',
          during: 'Time period',
          NoReception: 'No documents for the selected criteria.',
        },
        InvoiceAcceptanceSummary: {
          AutoApproved: 'Auto. approved',
          ManuallyApproved: 'Manually approved',
          Rejected: 'Rejected',
          Cancelled: 'Cancelled',
          i2pr: 'Invoice/receipt advices matchings',
          i2o: 'Invoice/orders matchings',
          SetEarlierDate: 'Select an earlier date.',
          Since_le: 'Since',
        },
        InvoiceCount: {
          Count: 'Invoice (%{on})',
          On: 'On',
          rejected: '%{count} rej.',
        },
        DocumentCountPerDayAndStatus: {
          INVOICE: {
            title: 'Invoices, per day and status',
          },
          ORDER: {
            title: 'Orders, per day and status',
          },
          RECADV: {
            title: 'Receipt advices, per day and status',
          },
          DESADV: {
            title: 'Despatch advices, per day and status',
          },
          during: 'Time period',
          NoReception: 'No documents for the selected criteria.',
          status: {
            Accepted_by_Customer: 'Accepted',
            Content_valid: 'Content validated',
            Dispute: 'Dispute',
            Format_valid: 'Format validated',
            No_credit: 'No credit',
            No_status: 'Other',
            Outage: 'Outage',
            Pending_Enrichments: 'Pending enrichments',
            Paid_by_recipient: 'Paid',
            Pending_to_Customer: 'Pending on recipient side',
            Received_by_Customer: 'Received',
            Received_by_DX: 'Received by DocProcess',
            Rejected_by_Customer: 'Rejected',
            Received_by_Recipient: 'Received',
            Rejected_by_DX: 'Rejected by DocProcess',
            Sent: 'Sent',
            Transmitted_by_DX: 'Transmitted by DocProcess',
            Matching_in_Progress: 'Matching in progress',
          },
        },
        InvoiceWaitingForDocuments: {
          Count: 'Invoices waiting for documents',
        },
        InvoiceWaitingForApproval: {
          Count: 'Invoices waiting for approval',
        },
        InvoiceWaitingForYourApproval: {
          Count: 'Invoices waiting for your approval',
          overdue: 'overdue',
        },
        OrderCount: {
          Count: 'Orders (%{on})',
          On: 'On',
        },
        PendingTaskCount: {
          Count: 'Pending tasks',
          Since_le: 'Since',
        },
        RecadvCount: {
          Count: 'Receipt advices (%{on})',
          On: 'On',
        },
        DesadvCount: {
          Count: 'Despatch advices (%{on})',
          On: 'On',
        },
        TotalVariationOnAcceptedInvoiceLines: {
          title: 'Total variation on accepted amounts',
          helper:
            'A positive amount means that you paid more than the exact prices.',
          Since: 'Since',
          Since_helper: 'The date when to start computing the differences',
        },
        WelcomeBanner: { LastLogin: 'Your last login' },
        CashCollectionProjectionOverTime: {
          title: 'Projection of cash collected overtime',
          due: 'Due M',
          overdue: 'overdue',
          amount: 'Amount',
        },
        TotalAmountOwedTopTen: {
          title: 'Total amount owed (top 10 clients)',
          overdue: 'Overdue',
          amount: 'Amount',
          intervals: {
            current: 'due',
            overdue_1_30: 'overdue since 1 - 30 days',
            overdue_31_60: 'overdue since 31 - 60 days',
            overdue_61_90: 'overdue since 61 - 90 days',
            overdue_91_180: 'overdue since 91 - 180 days',
            overdue_beyond_180: 'overdue > 180 days',
          },
        },
        InformBanner: {
          title:
            'List of documents in processing may be different than that shown in chart.',
        },
        DocumentCompany: {
          INVOICE: 'Invoices for',
          ORDER: 'Orders for',
          RECADV: 'Receipt Advices for',
          DESADV: 'Despatch Advices for',
        },
        Tooltip: {
          message: 'click to see details per company',
        },
        Columns: {
          company: 'Company',
          status: 'Status',
          count: 'Count',
        },
        WidgetTitle: {
          name: 'Widget title',
        },
        ListButton: {
          title: 'Table',
        },
        SummaryTitle: {
          title: 'Summary for ',
        },
        Date: {
          month: 'Month',
          year: 'Year',
        },
        AppsWidget: {
          title: 'My applications',
        },
        Display: {
          title: 'Display',
          days: 'Days',
          months: 'Months',
          years: 'Years',
        },
        DateRange: {
          title: 'Date range',
          last7Days: 'Last 7 days',
          last14Days: 'Last 14 days',
          thisMonth: 'This month',
          lastMonth: 'Last month',
          selectMonth: 'Select month',
          thisQuarter: 'This quarter',
          lastQuarter: 'Last quarter',
          thisYear: 'This year',
          lastYear: 'Last year',
          selectYear: 'Select year',
          last3Years: 'Last 3 years',
        },
        TableButton: {
          title: 'Display as table',
        },
        SavedQueries: {
          title: 'My saved queries',
          business: {
            title: 'Business',
          },
        },
        DisplayDocuments: {
          title: 'Recent documents',
          INVOICE: {
            title: 'Latest invoices',
          },
          ORDER: {
            title: 'Latest orders',
          },
          DESADV: {
            title: 'Latest despatch advices',
          },
          RECADV: {
            title: 'Latest receipt advices',
          },
          documentType: {
            INVOIC: 'Invoice',
            ORDERS: 'Order',
            ORDER: 'Order',
            RECADV: 'Receipt Advice',
            DESADV: 'Despatch Advice',
          },
          recipient: 'Recipient',
          issuer: 'Issuer',
          status: 'Status',
          issueDate: 'Issue Date',
          document: 'Document type',
          button: 'open in search',
          clear: 'Clear',
          limitMessage: 'Note: displaying only last 100 documents per type',
        },
      },
    },
    landingPage: {
      empty: {
        click: 'Click',
        drop: 'and drop your widgets',
        openedConfigurationDrop: 'Drop your widgets',
      },
      message: {
        post: 'Posted',
      },
    },
    pspMessage: 'Are you sure you want to sync?',
    catalogList: {
      menu: 'Catalogs',
    },
    catalogEdit: {
      menu: 'Catalogs editing ',
    },
    v2administration: {
      menu: 'V2 Administration',
    },
    peppol: {
      menu: 'PEPPOL',
      accessPoint: {
        title: 'Peppol Participant ID',
      },
      schemaId: {
        title: 'Peppol: Schema ID',
      },
      value: {
        title: 'Peppol: Value',
      },
      company_identification: {
        title: "Recipient's VAT Code",
      },
      company_name: {
        title: "Recipient's Company Name",
      },
      issuer_identification: {
        title: "Issuer's VAT Code",
      },
      country: {
        title: "Recipient's Country",
      },
      error: 'Unable to delete.',
    },
    monitoring: {
      menuMonitoring: 'Monitoring',
      efactura: {
        incidentId: 'Incident Id',
        filename: 'Filename',
        reason: 'Reason',
        status: 'Status',
        userId: 'User Id',
        date: 'Date',
        eventDateStart: 'Event Date Start',
        eventDateEnd: 'Event Date End',
        ignore: 'Ignore',
        supplierNotified: 'Supplier Notify',
        createTask: 'Create Jira Task',
      },
    },
    companyManagement: {
      menu: 'Accounts',
      menuV2: 'User & Company (V2)',
      globalView: 'V2 Global View',
      documentSearch: 'V2 Document Search',
      redirect: 'Go to',
      contractedProducts: 'Contracted products',
      suppliers: 'Suppliers',
      customers: 'customers',
      contacts: 'Contacts',
      account: {
        title: 'Account',
        name: 'Company Name',
        vatCode: 'VAT Code',
        idstatus: 'State',
        idformattype: 'Invoice input format',
        idformattypedesadv: 'Desadv input format',
        registryNumber: 'Trade Registry number',
        corporateStock: 'Corporate Stock amount',
        uidIdentification: 'UID Identification',
        supplierId: 'Supplier Id',
        sync: 'SYNC',
        success: 'Company was synchronized successfully',
        error: 'Error in synchronization',
        isCompanyAdmin: 'Company admin',
        v3Synchronize: 'V3 Synchronize Planned',
        country: 'Country',
        detailsTooltip: 'This option is available only for ANAF companies.',
        detailsTooltipRo: 'This option is supported only in Romania.',
        getDetails: 'get details',
        downloadCsvTemplate: 'Download CSV template',
        processing: 'Processing ...',
        language: 'Language',
      },
      users: {
        userId: 'User Id',
        role: 'User Role',
        title: 'Users',
        help: 'A list with all the roles that can be assigned to the user. A role is available if the company has access to the feature and the user role is assigned.',
        isCompanyAdmin: 'Customer Administrator',
      },
      contact: {
        title: 'Contact',
        lastName: 'Name',
        firstName: 'First Name',
        phone: 'Phone',
        mail: 'Mail',
      },
      dropdowndef: {
        title: 'Dropdown Definition',
        description: 'Description',
        idtype: 'Id Type',
        value: 'Value',
        delete_dropdown_error:
          'Unable to delete, the selected item is already in use.',
      },
      formattype: {
        comment: 'Comment',
        description: 'Description',
        idDocumentType: 'Id Document Type',
        delete_formattype_error:
          'Unable to delete, the selected format type is already in use.',
      },
      dxrole: {
        name: 'Name',
        adminOnly: 'AdminOnly',
        delete_dxrole_error:
          'Unable to delete, the selected role is already in use.',
      },
      dxProduct: {
        title: 'DX Product',
        description: 'Description',
        comments: 'Comments',
        flgActive: 'flgActive',
        idProductGroup: 'idProductGroup',
        delete_product_error:
          'Unable to delete, the selected item is already in use.',
      },
      accountRelation: {
        title: 'Account relation',
        customer: 'Customer',
        customerId: 'Customer ID',
        supplier: 'Supplier',
        supplierId: 'Supplier ID',
        suppliercode4customer: 'Supplier code for customer',
        delete_account_error:
          'Unable to delete, the selected account is already in use.',
      },
      addresses: {
        title: 'Addresses',
        addressType: 'Address Type',
        street: 'Street',
        district: 'District',
        additionalStreet: 'Additional Street',
        buildingNumber: 'Building Number',
        postBox: 'Post Box',
        city: 'City',
        postalCode: 'Postal Code',
        financialAccount: 'IBAN',
        bank: 'Bank',
        gln: 'GLN',
        country: 'Country Code',
        flgBilling: 'Billing Address',
        flgShipping: 'Shipping Address',
        flgLogistic: 'Logistic Address',
      },
      addressBuffer: {
        delete: 'DELETE',
        message: 'Address was synchronized successfully',
      },
      productUsage: {
        product: 'Product',
        title: 'Contracted Products',
        totalusage: 'Total Usage',
        totalcredit: 'Total Credit',
        thresold: 'Threshold',
        advailible: 'Available',
      },
      supportedProducts: {
        product: 'Product',
        title: 'Supported Products',
        delete_supportedproduct_error:
          'Unable to delete, the selected product is already in use.',
      },
      audit: {
        tsChange: 'Last change at',
        userIdChange: 'Last change by',
      },
      commonDocumentColumns: {
        currentState: 'Current State',
        dxuid: 'DXUID',
        sourceCif: 'Source CIF',
        destinationCif: 'Destination CIF',
        originalFileName: 'Original Filename',
        ingestDate: 'Ingest Date',
        ingestDateBefore: 'Ingest Date Before',
        ingestDateAfter: 'Ingest Date After',
        invoiceId: 'Invoice ID',
        orderId: 'Order ID',
        receiptAdviceId: 'Receipt Advice ID',
        despatchAdviceId: 'Despatch Advice ID',
        issuingDate: 'Issuing date',
        issuingDateStart: 'Issuing date start',
        issuingDateEnd: 'Issuing date end',
        customerName: 'Customer',
        supplierName: 'Supplier',
      },
    },
    invoices: {
      title: 'חשבוניות',
      document_label: 'חשבונית',
      edit_title: ' עריכת חשבונית',
      create_title: 'יצירת חשבונית',
      show_title: 'חשבונית :',
      status_history_title: 'היסטוריית סטטוס',
      payment_method: 'אמצעי תשלום',
      payment_means_code: 'אמצעי תשלום',
      due_date: 'תאריך יעד',
      billing_period: 'תקופת תשלום',
      iban: 'IBAN',
      bank: 'בנק',
      initialInvoice: 'חשבונית מקור',
      lines: 'שורות',
      lineId: 'Order line ID',
      invoiceNumber: 'מספר חשבונית',
      delivery: 'משלוח',
      note: 'הערות',
      accounting_code_code: 'קוד מחיר הנה"ח',
      deliveryDate_IssueDate_error:
        'תאריך משלוח צריך להיות קטן או שווה לתאריך הנפקת חשבונית',
      issueDate_DueDate_error:
        'תאריך ידע צריך להיות גדול או שווה לתאריך הנפקת חשבונית',
      referenceIssueDate_issueDate_error:
        'תאריך הנפקה צריך להיות גדול או שווה לתאריך הנפקת חשבונית מקור',
      issueDate_error: 'תאריך הנפקה לא יכול להיות גדול מתאריך נוכחי',
      dateWithoutId_error: 'לא ניתן להגדיר את התאריך הזה ללא המזהה הקשור אליו',
      fmdlinenote_error:
        'Please add the value for the note as "Invoicing period: yyyyMMdd-yyyyMMdd" \n. Example. Invoicing period: 20200701-20200731',
      sgrWithoutRef_error: 'Please set the "Product ref" field',
      valueWithoutName_error:
        "This value can't be set without its related name",
      orderIdSalesOrderID_error:
        'Please set to "NA" Order ID (BT-13) in that case',
      tax_summary_vat_percent: 'מע"מ (%)',
      tax_summary_taxable_amount: 'סכום חייב במס (%{currencyID})',
      tax_summary_tax_amount: 'סך מיסים',
      tax_category_code: 'Tax category code',
      fetchBuyerDetailsButtonLabel: 'Complete the informations',
      fetchBuyerDetailsTooltip: 'Bring the information about company',
      taxCategory: {
        standard: '(S) Standard',
        reverse: '(AE) Reverse taxation',
        collection: 'Tax on collection',
        exempt: '(E) Tax exempt',
        freeExport: '(G) Free export item, tax not charged',
        exemptionWithDeductibility: 'Exemption with deductibility',
        exemptWithoutDeduction: 'Exempt without right of deduction',
        zeroVat: '(Z) Zero tax',
        triangleTransaction: 'Triangular transaction',
        proRata: 'Tax normal pro rata',
        specialRegime: 'Special regime (art. 1521, 1522)',
        specialExemption: 'Reg. special exemption art 311 CF',
        shorteningSpec: 'Reg. shortening spec 312 CF',
        purchaseWithInstallation:
          'EU purchases of goods with installation / assembly',
        outsideOfScope: '(O) Services outside scope of tax',
        EEAIntraCommunity:
          '(K) VAT exempt for EEA intra-community supply of goods and services',
        L: '(L) Canary Islands general indirect tax',
        M: '(M) Tax for production, services and importation in Ceuta and Melilla',
        B: '(B) Transferred (VAT), In Italy',
        '00': '(00) 0 %',
        '01': '(01) 6 %',
        '02': '(02) 12 %',
        '03': '(03) 21 %',
        '45': '(45) Reverse charge - Contractor',
        NA: '(NA) Exempt (miscelleanous)',
        FD: '(FD) Financial discount',
        SC: '(SC) Small company',
        '00_44': '(00/44) 0% Clause 44',
        '03_SE': '(03/SE) Standard exchange ',
        MA: '(MA) Margin',
        '46_GO': '(46/GO) ICD Goods',
        '47_TO': '(47/TO) ICD Manufacturing cost',
        '47_AS': '(47/AS) ICD Assembly',
        '47_DI': '(47/DI) ICD Distance',
        '47_SE': '(47/SE) ICD Services',
        '44': '(44) ICD Services B2B',
        '46_TR': '(46/TR) ICD Triangle a-B-c',
        '47_EX': '(47/EX) Export non E.U.',
        '47_EI': '(47/EI) Indirect export',
        '47_EE': '(47/EE) Export via E.U.',
        NS: '(NS) Not subject to VAT invoice/credit note',
        OSS_G: '(OSS-G) OSS Goods',
        OSS_S: '(OSS-S) OSS Services',
        OSS_I: '(OSS-I) OSS Import',
      },
      tax_reason_code: 'Tax reason code',
      taxReasonCode: {
        VATEX_EU_AE:
          '(AE) => VATEX-EU-AE: Reverse charge Supports EN 16931-1 rule BR-AE-10',
        VATEX_EU_D:
          '(E) => VATEX-EU-D: Intra-Community acquisition from second hand means of transport Second-hand means of transport',
        VATEX_EU_F:
          '(E) => VATEX-EU-F: Intra-Community acquisition of second hand goods Second-hand goods',
        VATEX_EU_G:
          '(G) => VATEX-EU-G: Export outside the EU Supports EN 16931-1 rule BR-G-10',
        VATEX_EU_I:
          '(E) =>  VATEX-EU-I: Intra-Community acquisition of works of art Works of art',
        VATEX_EU_IC:
          '(K) => VATEX-EU-IC: Intra-Community supply Supports EN 16931-1 rule BR-IC-10',
        VATEX_EU_O:
          '(O) => VATEX-EU-O: Not subject to VAT Supports EN 16931-1 rule BR-O-10',
        VATEX_EU_J:
          "(E) => VATEX-EU-J: Intra-Community acquisition of collectors items and antiques Collectors' items and antiques",
        VATEX_EU_79_C:
          '(E) => VATEX-EU-79-C: Exempt based on article 79, point c of Council Directive 2006/112/EC',
        VATEX_EU_132:
          '(E) => VATEX-EU-132: Exempt based on article 132 of Council Directive 2006/112/EC',
        VATEX_EU_143:
          '(E) => VATEX-EU-143: Exempt based on article 143 of Council Directive 2006/112/EC',
        VATEX_EU_148:
          '(E) => VATEX-EU-148: Exempt based on article 148 of Council Directive 2006/112/EC',
        VATEX_EU_151:
          '(E) => VATEX-EU-151: Exempt based on article 151 of Council Directive 2006/112/EC',
        VATEX_EU_309:
          '(E) => VATEX-EU-309: Exempt based on article 309 of Council Directive 2006/112/EC',
        VATEX_FR_FRANCHISE:
          '(E) => VATEX-FR-FRANCHISE: France domestic VAT franchise in base',
        VATEX_FR_CNWVAT:
          'VATEX-FR-CNWVAT: France domestic Credit Notes without VAT',
        BETE_45: 'BETE-45: Reverse charge - Contractor',
        BETE_EX: ' BETE-EX: Exempt',
        BETE_FD: 'BETE-FD: Financial discount',
        BETE_SC: 'BETE-SC: Small company',
        BETE_00_44: 'BETE-00/44: 0% Clause 44',
        BETE_03_SE: 'BETE-03/SE: Standard exchange',
        BETE_MA: 'BETE-MA: Margin',
        BETE_46_GO: 'BETE-46/GO: Intra-community supply - Goods',
        BETE_47_TO: 'BETE-47/TO: Intra-community supply - Manufacturing cost',
        BETE_47_AS: 'BETE-47/AS: Intra-community supply - Assembly',
        BETE_47_DI: 'BETE-47/DI: Intra-community supply - Distance',
        BETE_47_SE: 'BETE-47/SE: Intra-community supply - Services',
        BETE_44: 'BETE-44: Intra-community supply - Services B2B',
        BETE_46_TR: 'BETE-46/TR: Intra-community supply - Triangle a-B-c',
        BETE_47_EX: 'BETE-47/EX: Export non E.U.',
        BETE_47_EI: 'BETE-47/EI: Indirect export',
        BETE_47_EE: 'BETE-47/EE: Export via E.U.',
        BETE_NS: 'BETE-NS: Not subject to VAT',
      },
      taxReasonCodeTooltip: {
        VATEX_EU_AE:
          'Reverse charge Supports EN 16931-1 rule BR-AE-10 (VATEX-EU-AE)' +
          ' - Only use with VAT category code AE',
        VATEX_EU_D:
          'Intra-Community acquisition from second hand means of transport Second-hand means of transport (VATEX-EU-D)' +
          ' - Indication that VAT has been paid according to the relevant transitional arrangements - Only use with VAT category code E',
        VATEX_EU_F:
          'Intra-Community acquisition of second hand goods Second-hand goods (VATEX-EU-F)' +
          ' - Indication that the VAT margin scheme for second-hand goods has been applied. - Only use with VAT category code E',
        VATEX_EU_G:
          'Export outside the EU Supports EN 16931-1 rule BR-G-10 (VATEX-EU-G) - Only use with VAT category code G',
        VATEX_EU_I:
          'Intra-Community acquisition of works of art Works of art (VATEX-EU-I)' +
          ' - Indication that the VAT margin scheme for works of art has been applied. - Only use with VAT category code E',
        VATEX_EU_IC:
          'Intra-Community supply Supports EN 16931-1 rule BR-IC-10 (VATEX-EU-IC) - Only use with VAT category code K',
        VATEX_EU_O:
          'Not subject to VAT Supports EN 16931-1 rule BR-O-10 (VATEX-EU-O) - Only use with VAT category code O',
        VATEX_EU_J:
          'Intra-Community acquisition of collectors items and antiques Collector’s items and antiques (VATEX-EU-J)' +
          ' - Indication that the VAT margin scheme for collector’s items and antiques has been applied. - Only use with VAT category code E',
        VATEX_EU_79_C:
          'Exempt based on article 79, point c of Council Directive 2006/112/EC (VATEX-EU-79-C) - Only use with VAT category code E',
        VATEX_EU_132:
          'Exempt based on article 132 of Council Directive 2006/112/EC (VATEX-EU-132) - Only use with VAT category code E',
        VATEX_EU_143:
          'Exempt based on article 143 of Council Directive 2006/112/EC (VATEX-EU-143) - Only use with VAT category code E',
        VATEX_EU_148:
          'Exempt based on article 148 of Council Directive 2006/112/EC (VATEX-EU-148) - Only use with VAT category code E',
        VATEX_EU_151:
          'Exempt based on article 151 of Council Directive 2006/112/EC (VATEX-EU-151) - Only use with VAT category code E',
        VATEX_EU_309:
          'Exempt based on article 309 of Council Directive 2006/112/EC (VATEX-EU-309) - Only use with VAT category code E',
        VATEX_FR_FRANCHISE:
          'France domestic VAT franchise in base (VATEX-FR-FRANCHISE). For domestic invoicing in France',
        VATEX_FR_CNWVAT:
          'France domestic Credit Notes without VAT, due to supplier forfeit of VAT for discount (VATEX-FR-CNWVAT). For domestic Credit Notes only in France',
        BETE_45: 'Reverse charge - Contractor (BETE-45)',
        BETE_EX: 'Exempt (BETE-EX)',
        BETE_FD: 'Financial discount (BETE-FD)',
        BETE_SC: 'Small company (BETE-SC)',
        BETE_00_44: '0% Clause 44 (BETE-00/44)',
        BETE_03_SE: 'Standard exchange (BETE-03/SE)',
        BETE_MA: 'Margin (BETE-MA)',
        BETE_46_GO: 'Intra-community supply - Goods (BETE-46/GO)',
        BETE_47_TO: 'Intra-community supply - Manufacturing cost (BETE-47/TO)',
        BETE_47_AS: 'Intra-community supply - Assembly (BETE-47/AS)',
        BETE_47_DI: 'Intra-community supply - Distance (BETE-47/DI)',
        BETE_47_SE: 'Intra-community supply - Services (BETE-47/SE)',
        BETE_44: 'Intra-community supply - Services B2B (BETE-44)',
        BETE_46_TR: 'Intra-community supply - Triangle a-B-c (BETE-46/TR)',
        BETE_47_EX: 'Export non E.U. (BETE-47/EX)',
        BETE_47_EI: 'Indirect export (BETE-47/EI)',
        BETE_47_EE: 'Export via E.U. (BETE-47/EE)',
        BETE_NS: 'Not subject to VAT (BETE-NS)',
      },
      charge_reason_code: 'Charge reason code',
      chargeReasonCode: {
        AA: 'AA - Advertising',
        AAA: 'AAA - Telecommunication',
        AAC: 'AAC - Technical modification',
        AAD: 'AAD - Job-order production',
        AAE: 'AAE - Outlays',
        AAF: 'AAF - Off-premises',
        AAH: 'AAH - Additional processing',
        AAI: 'AAI - Attesting',
        AAS: 'AAS - Acceptance',
        AAT: 'AAT - Rush delivery',
        AAV: 'AAV - Special construction',
        AAY: 'AAY - Airport facilities',
        AAZ: 'AAZ - Concession',
        ABA: 'ABA - Compulsory storage',
        ABB: 'ABB - Fuel removal',
        ABC: 'ABC - Into plane',
        ABD: 'ABD - Overtime',
        ABF: 'ABF - Tooling',
        ABK: 'ABK - Miscellaneous',
        ABL: 'ABL - Additional packaging',
        ABN: 'ABN - Dunnage',
        ABR: 'ABR - Containerisation',
        ABS: 'ABS - Carton packing',
        ABT: 'ABT - Hessian wrapped',
        ABU: 'ABU - Polyethylene wrap packing',
        ACF: 'ACF - Miscellaneous treatment',
        ACG: 'ACG - Enamelling treatment',
        ACH: 'ACH - Heat treatment',
        ACI: 'ACI - Plating treatment',
        ACJ: 'ACJ - Painting',
        ACK: 'ACK - Polishing',
        ACL: 'ACL - Priming',
        ACM: 'ACM - Preservation treatment',
        ACS: 'ACS - Fitting',
        ADC: 'ADC - Consolidation',
        ADE: 'ADE - Bill of lading',
        ADJ: 'ADJ - Airbag',
        ADK: 'ADK - Transfer',
        ADL: 'ADL - Slipsheet',
        ADM: 'ADM - Binding',
        ADN: 'ADN - Repair or replacement of broken returnable package',
        ADO: 'ADO - Efficient logistics',
        ADP: 'ADP - Merchandising',
        ADQ: 'ADQ - Product mix',
        ADR: 'ADR - Other services',
        ADT: 'ADT - Pick-up',
        ADW: 'ADW - Chronic illness',
        ADY: 'ADY - New product introduction',
        ADZ: 'ADZ - Direct delivery',
        AEA: 'AEA - Diversion',
        AEB: 'AEB - Disconnect',
        AEC: 'AEC - Distribution',
        AED: 'AED - Handling of hazardous cargo',
        AEF: 'AEF - Rents and leases',
        AEH: 'AEH - Location differential',
        AEI: 'AEI - Aircraft refueling',
        AEJ: 'AEJ - Fuel shipped into storage',
        AEK: 'AEK - Cash on delivery',
        AEL: 'AEL - Small order processing service',
        AEM: 'AEM - Clerical or administrative services',
        AEN: 'AEN - Guarantee',
        AEO: 'AEO - Collection and recycling',
        AEP: 'AEP - Copyright fee collection',
        AES: 'AES - Veterinary inspection service',
        AET: 'AET - Pensioner service',
        AEU: 'AEU - Medicine free pass holder',
        AEV: 'AEV - Environmental protection service',
        AEW: 'AEW - Environmental clean-up service',
        AEX: 'AEX - National cheque processing service outside account area',
        AEY: 'AEY - National payment service outside account area',
        AEZ: 'AEZ - National payment service within account area',
        AJ: 'AJ - Adjustments',
        AU: 'AU - Authentication',
        CA: 'CA - Cataloguing',
        CAB: 'CAB - Cartage',
        CAD: 'CAD - Certification',
        CAE: 'CAE - Certificate of conformance',
        CAF: 'CAF - Certificate of origin',
        CAI: 'CAI - Cutting',
        CAJ: 'CAJ - Consular service',
        CAK: 'CAK - Customer collection',
        CAL: 'CAL - Payroll payment service',
        CAM: 'CAM - Cash transportation',
        CAN: 'CAN - Home banking service',
        CAO: 'CAO - Bilateral agreement service',
        CAP: 'CAP - Insurance brokerage service',
        CAQ: 'CAQ - Cheque generation',
        CAR: 'CAR - Preferential merchandising location',
        CAS: 'CAS - Crane',
        CAT: 'CAT - Special colour service',
        CAU: 'CAU - Sorting',
        CAV: 'CAV - Battery collection and recycling',
        CAW: 'CAW - Product take back fee',
        CAX: 'CAX - Quality control released',
        CAY: 'CAY - Quality control held',
        CAZ: 'CAZ - Quality control embargo',
        CD: 'CD - Car loading',
        CG: 'CG - Cleaning',
        CS: 'CS - Cigarette stamping',
        CT: 'CT - Count and recount',
        DAB: 'DAB - Layout/design',
        DAC: 'DAC - Assortment allowance',
        DAD: 'DAD - Driver assigned unloading',
        DAF: 'DAF - Debtor bound',
        DAG: 'DAG - Dealer allowance',
        DAH: 'DAH - Allowance transferable to the consumer',
        DAI: 'DAI - Growth of business',
        DAJ: 'DAJ - Introduction allowance',
        DAK: 'DAK - Multi-buy promotion',
        DAL: 'DAL - Partnership',
        DAM: 'DAM - Return handling',
        DAN: 'DAN - Minimum order not fulfilled charge',
        DAO: 'DAO - Point of sales threshold allowance',
        DAP: 'DAP - Wholesaling discount',
        DAQ: 'DAQ - Documentary credits transfer commission',
        DL: 'DL - Delivery',
        EG: 'EG - Engraving',
        EP: 'EP - Expediting',
        ER: 'ER - Exchange rate guarantee',
        FAA: 'FAA - Fabrication',
        FAB: 'FAB - Freight equalization',
        FAC: 'FAC - Freight extraordinary handling',
        FC: 'FC - Freight service',
        FH: 'FH - Filling/handling',
        FI: 'FI - Financing',
        GAA: 'GAA - Grinding',
        HAA: 'HAA - Hose',
        HD: 'HD - Handling',
        HH: 'HH - Hoisting and hauling',
        IAA: 'IAA - Installation',
        IAB: 'IAB - Installation and warranty',
        ID: 'ID - Inside delivery',
        IF: 'IF - Inspection',
        IR: 'IR - Installation and training',
        IS: 'IS - Invoicing',
        KO: 'KO - Koshering',
        L1: 'L1 - Carrier count',
        LA: 'LA - Labelling',
        LAA: 'LAA - Labour',
        LAB: 'LAB - Repair and return',
        LF: 'LF - Legalisation',
        MAE: 'MAE - Mounting',
        MI: 'MI - Mail invoice',
        ML: 'ML - Mail invoice to each location',
        NAA: 'NAA - Non-returnable containers',
        OA: 'OA - Outside cable connectors',
        PA: 'PA - Invoice with shipment',
        PAA: 'PAA - Phosphatizing (steel treatment)',
        PC: 'PC - Packing',
        PL: 'PL - Palletizing',
        RAB: 'RAB - Repacking',
        RAC: 'RAC - Repair',
        RAD: 'RAD - Returnable container',
        RAF: 'RAF - Restocking',
        RE: 'RE - Re-delivery',
        RF: 'RF - Refurbishing',
        RH: 'RH - Rail wagon hire',
        RV: 'RV - Loading',
        SA: 'SA - Salvaging',
        SAA: 'SAA - Shipping and handling',
        SAD: 'SAD - Special packaging',
        SAE: 'SAE - Stamping',
        SAI: 'SAI - Consignee unload',
        SG: 'SG - Shrink-wrap',
        SH: 'SH - Special handling',
        SM: 'SM - Special finish',
        SU: 'SU - Set-up',
        TAB: 'TAB - Tank renting',
        TAC: 'TAC - Testing',
        TT: 'TT - Transportation - third party billing',
        TV: 'TV - Transportation by vendor',
        V1: 'V1 - Drop yard',
        V2: 'V2 - Drop dock',
        WH: 'WH - Warehousing',
        XAA: 'XAA - Combine all same day shipment',
        YY: 'YY - Split pick-up',
        ZZZ: 'ZZZ - Mutually defined',
      },
      discount_reason_code: 'Discount reason code',
      discountReasonCode: {
        '41': '41 - Bonus for works ahead of schedule',
        '42': '42 - Other bonus',
        '60': '60 - Manufacturer’s consumer discount',
        '62': '62 - Due to military status',
        '63': '63 - Due to work accident',
        '64': '64 - Special agreement',
        '65': '65 - Production error discount',
        '66': '66 - New outlet discount',
        '67': '67 - Sample discount',
        '68': '68 - End-of-range discount',
        '70': '70 - Incoterm discount',
        '71': '71 - Point of sales threshold allowance',
        '88': '88 - Material surcharge/deduction',
        '95': '95 - Discount',
        '100': '100 - Special rebate',
        '102': '102 - Fixed long term',
        '103': '103 - Temporary',
        '104': '104 - Standard',
        '105': '105 - Yearly turnover',
      },
      taxes: {
        label: 'Unitary tax',
        SGT_Amount: 'Sugar Tax',
        SGT_Percent: 'Sugar Tax %',
        GT_Amount: 'Green Tax',
        GT_Percent: 'Green Tax %',
      },
      commercialInvoice: '380 - Commercial invoice',
      cancelInvoice: '381 - Cancel invoice',
      correctiveInvoice: '384 - Corrective invoice',
      selfbillingInvoice: '389 - Self-billing invoice',
      accountingInvoice: '751 - Invoice information for accounting purposes',
      missingExchangeRate: 'Please set currency exchange rate as well',
      missingTargetCurrencyCode: 'Please set target currency code as well',
      applyExchangeRateButtonLabel: 'Apply Exchange Rate',
      applyExchangeRateTooltip: 'Apply exchange rate on all invoice lines',
      efactura: {
        efactura_choice_label: 'e-Factura options',
        efactura_send: 'send to e-Factura',
        efactura_no_send: 'No e-Factura',
        efactura_only: 'only e-Factura',
      },
      custom: {
        multimedia: {
          internal_transaction: 'Internal transaction',
          intra_community: 'Intra-community purchase',
          import_services: 'Import services',
          transport_invoice: 'Transport invoice chargeable on DVI',
        },
      },
      businessProcessType: {
        '1': 'Merchandise',
        '2': 'Services or other acquisitions',
        '3': 'Trade and financial discounts',
      },
      taxRepresentative: 'Tax representative',
      documentLevelCharges: 'Document level charges',
      documentLevelDiscounts: 'Document level discounts',
      primaryAccountNumberID: 'Primary account number of card',
      orderReferenceSalesOrderId: 'Sales order reference',
    },
    customers: {
      title: 'לקוחות',
    },
    issuers: {
      title: 'מנפיקים',
    },
    role: {
      meniu: 'Roles',
      domain: 'Domain',
      document: 'Documents',
    },
    despatchAdvices: {
      title: 'הודעות משלוח',
      document_label: 'הודעת משלוח',
      edit_title: 'עריכת הודעת משלוח ',
      create_title: 'יצירת הודעת משלוח',
      show_title: 'הודעת משלוח : ',
      number: 'מספר :',
      despatchAdviceNumber: 'מספר הודעת משלוח',
      shippingContactName: 'שם איש קשר',
      shippingContactPhone: 'טלפון איש קשר',
      shippingContactEmail: 'אימייל איש קשר',
      despatchSupplierPartyID: 'מספר הודעת משלוח',
      shipmentId: 'מספר משלוח',
      shipment: 'משלוח',
      grossVolumeMeasure: 'נפח כולל',
      grossVolumeMeasureUnitCode: 'יחידת מידה',
      netVolumeMeasure: 'נפח נטו',
      netVolumeMeasureUnitCode: 'יחידת מידה',
      totalNumberofGoods: 'מספר כולל של יחידות',
      totalNumberofGoodsUnitCode: 'קוד יחידת מידה',
      customsDeclaredValueAmount: 'ערך מכס שהוצהר',
      customsDeclaredValueAmountCurrency: 'מטבע',
      specialInstructions: 'הוראות מיוחדות',
      exportCountryCode: 'ארץ יצוא',
      shipmentStageId: 'מספר שלב משלוח',
      transportModeCode: 'קוד סוג שילוח',
      transportMeansTypeCode: 'סוג שילוח לשלב הובלה',
      transportMeansTypeCodeListId: 'סוג שילוח רשימת זהות',
      carrierCode: 'קוד מוביל',
      carrierName: 'שם מוביל',
      trackingId: 'מספר עקיבה הובלה',
      actualDeliveryDate: 'תאריך משלוח נוכחי',
      actualDeliveryTime: 'שעת שילוח נוכחית',
      requestedDeliveryDate: 'תאריך משלוח מבוקש',
      requestedDeliveryTime: 'שעת משלוח מבוקשת',
      estimatedArrivalDate: 'תאריך משלוח משוער',
      estimatedArrivalTime: 'שעת משלוח משוערת',
      handlingUnitTypeCode: 'יחידת מידה של אריזה',
      handlingUnitShippingMarks: 'סוג סימון',
      handlingUnitPackageQuantity: 'מספר יחידות',
      handlingUnitPackageQuantityUnitCode: 'יחידת מידה',
      handlingUnitPackageReturnableMaterialIndicator:
        'סוג אריזה (ניתן להחזרה או לא(',
      handlingUnitPackagePackingLevelCode: 'קוד סוג אריזה',
      handlingUnitPackagePackagingTypeCode: 'סוג זיהוי אריזה',
      missingLineOrderIDref: 'אנא הגדר מזהה הזמנה',
      logisticOnly: 'Logistic Only',
      logisticOnlyMessage:
        'This is a logistic despatch advice, so it will not reach the buyer via EDI channel. The only purpose for this is to allow the despatch to be issued and printed',
    },
    receiptAdvices: {
      title: 'הודעות קבלה',
      document_label: 'הודעת קבלה',
      document_label_return: 'Goods return advice',
      document_label_ack: 'Acknowledgement of receipt',
      palletReceiptNotice: '352 - Pallet receipt notice',
      receiptAdviceSubType: '632 - Receipt Advice',
      goodsReturnSubType: '35E - Goods return advice',
      ackReceiptSubType: '352 - Acknowledgement of receipt',
      create_title: 'Create Receipt Advice',
      edit_title: 'Edit Receipt Advice',
      create_goods_return_title: 'Create Goods Return',
      edit_goods_return_title: 'Edit Goods Return',
      show_title: 'הודעת קבלה : ',
      number: 'מספר : ',
      goodsReturnID: 'Goods return ID',
      receiptAdviceID: 'Receipt advice ID',
      goodsReturnIssueDate: 'Issue date',
      receiptAdviceType: 'Receipt advice type',
      goodsReturnReason: 'Goods return reason',
      licensePlateId: 'License plate ID',
      productService: 'Product / Service',
      customerContact: 'Customer contact',
      supplierContact: 'Supplier contact',
      returnReasonAlteredMerchandise: 'Altered Merchandise',
      returnReasonMissingMerchandise: 'Missing Merchandise',
      returnReasonExtraQuantity: 'Extra Quantity',
    },
    feedbackMessages: {
      title: 'הודעות',
      document_label: 'הודעה',
      edit_title: 'עריכת הודעה ',
      show_title: 'הודעה : ',
      no_docs: 'No messages',
    },
    linkedDocuments: {
      no_docs: 'No documents',
    },
    attachments: {
      title: 'מסמכים מצורפים',
      document_label: 'מסמך מצורף',
      edit_title: 'ערוך מסמך מצורף',
      show_title: 'מסמך מצורף : ',
      no_docs: 'No attachments',
    },
    orders: {
      title: 'הזמנות',
      document_label: 'הזמנה',
      edit_title: 'ערוך הזמנה ',
      create_title: 'צור סדר',
      show_title: 'הזמנה : ',
      orderNumber: 'מספר הזמנה ',
      initialOrder: '105 - Initial order',
      updatedOrder: '231 - Updated order',
      updatedOrderNotModified: '320 - Acknowledgement of order',
      finalOrder: 'הזמנה סופית',
      crossDocking: 'עגינה צולבת',
      goodsReturn: 'הסחורה חוזרת',
      orderType: 'סוג הזמנה',
    },
    forecast: {
      title: 'תחזיות',
      document_label: 'תחזית',
      edit_title: 'עריכת תחזית ',
      show_title: 'תחזית : ',
    },
    contract: {
      title: 'חוזים',
      document_label: 'חוזה',
      edit_title: 'עריכת חוזה',
      show_title: 'חוזה : ',
    },
    catalog: {
      title: 'קטלוגים',
      document_label: 'קטלוג',
      edit_title: 'עריכת קטלוג ',
      show_title: 'קטלוג : ',
    },
    apm: {
      title: 'Head over to the Expense Classification Cockpit to see this task',
      subheader: 'Please talk to your sales advisor for more information',
    },
    apmValidation: {
      title: 'Head over to the Validation Cockpit to see this task',
      subheader: 'Please, approve or reject the invoice below:',
      open_cockpit: 'Open the cockpit',
    },
    waybill: {
      title: 'CMRs (tous)',
      document_label: 'CMR',
      document_sublabel:
        'Convention on the Contract for the International Carriage of Goods by Road',
      edit_title: 'עריכת CMR',
      create_title: 'יצירת CMR',
      show_title: 'CMR : ',
      number: 'מספר מסמך',
      carrierParty: 'זהות המוביל',
      consigneeParty: 'צד מקבל',
      consignorParty: 'צד שולח',
      freightForwarderParty: 'צד משלח בינלאומי',
      note: 'הערות',
      partyTile: {
        vatIdentification: 'זהות מע״מ',
        name: 'שם',
        street: 'רחוב',
        additionalStreet: 'רחוב נוסף',
        number: 'רחוב/מספר בית',
        city: 'עיר',
        postalCode: 'מיקוד',
        countrySubEntityCode: 'קוד מדינה',
        countryCode: 'קוד מדינה',
        contactName: 'איש קשר',
        phone: 'טלפון',
        email: 'דוא״ל',
      },
      loadingTable: {
        serviceDescription: 'סוג שילוח',
        loadingNumber: 'מספר טעינה',
        licensePlate: 'לוחית רישוי רכב/ים',
        marksAndNumber: 'סוגים ומספרים',
        packageNumber: 'מספר אריזות',
        packingMethod: 'סוג אריזה',
        goodsNature: 'אופי הסחורה',
        remarks: 'הערות',
        loadingLocation: 'מיקום הטעינה',
        unloadingLocation: 'מיקום הפריקה',
        loadingDate: 'תאריך טעינה',
        unloadingDate: 'תאריך פריקה',
        costs: {
          transportCostBeforeFees: 'מחיר שילוח לפני מיסים',
          transportFees: 'עלות שילוח',
          transportCostIncludingFees: 'סה״כ עלות שילוח כולל מיסים',
        },
        addressName: 'שם כתובת',
      },
      serviceDescription: {
        glassSale: 'מכירת זכוכית',
        glassAcquisition: 'רכישת זכוכית',
        culletsReturn: 'החזרת זכוכית',
        shelvesReturn: 'החזרת מדפים',
        fees: 'עלויות',
      },
      issueDate_loadingDate_error:
        'צריך להיות קטן או שווה לתאריך הנפקת חשבונית',
      unloadingDate_loadingDate_error: 'צריך להיות גדול או שווה לתאריך טעינה',
    },
    dxDocuments: {
      title: 'כל המסמכים',
      document_label: 'מסמך',
      edit_title: 'עריכת מסמך ',
      show_title: 'מסמך : ',
    },
    state: {
      completed: 'הסתיים',
      suspended: 'מוקפא',
      active: 'פעיל',
    },
    appSwitcher: {
      DocProcess: {
        label: 'Manage your documents and business workflows',
      },
      DxArchive: {
        label: 'Archive your documents',
      },
      DxCatalog: {
        label: 'Manage your product catalogs',
      },
      ExpenseClassificationCockpit: {
        label: 'Allocate expenses to your invoices',
      },
      ReconciliationCockpit: {
        label: 'Manage your Accounts Payable',
      },
      DxContract: {
        label: 'Manage your contracts',
      },
      DxUpload: {
        label: 'DocProcess smart connector',
      },
      EtransportCockpit: {
        label: 'Declare your road transport in Romania',
      },
      C4Cockpit: {
        label: 'Manage your efactura documents',
      },
      EmagCockpit: {
        label: 'Manage your efactura documents',
      },
      CoraCockpit: {
        label: 'Manage your efactura documents',
      },
    },
    user_menu: {
      settings: 'הגדרות',
      language: 'שפה',
      select_language: 'בחירת שפה',
      account_preferences: 'החשבון שלי',
      AccountNotFound: 'Account not found!',
      AccountToImpersonate: 'Account to impersonate',
      AccountToImpersonateHelper: 'email or login <ENTER>',
      SwitchCompany: 'Switch company',
    },
    help_menu: {
      title: 'תפריט עזרה',
      userGuide: 'מדריך משתמש',
      feedbacks: 'תן משוב',
      mailto: 'mailto:feedback@doc-process.com?subject=Feedback on %{DxApp}',
    },
    menu: {
      home: 'בית',
      documents: 'מסמכים',
      create: 'יצירת מסמך',
      administration: 'ניהול',
      tasks: 'משימות',
      tooltip: 'Documents view',
    },
    buttons: {
      unselect: 'בטל בחירה',
      preview: 'תצוגה מקדימה',
      more: 'עוד...',
      related_documents: 'מסמכים קשורים והודעות',
      download: 'הורדה',
      ediDocument: 'EDI Document',
      createNewDocument: 'Create',
      cloneInvoice: 'שכפול חשבונית',
      cancelInvoice: 'ביטול חשבונית',
      correctiveInvoice: 'תיקון חשבונית',
      send: 'שלח',
      convertToInvoice: 'המרה לחשבונית',
      convertToDespatchAdvice: 'המרה להודעת משלוח',
      convertToReceiptAdvice: 'Convert to receipt Advice',
      editInvoice: 'עריכת חשבונית',
      editDespatchAdvice: 'עריכת הודעת משלוח',
      editWayBill: 'עריכת CMR',
      editReceiptAdvice: 'Edit receipt advice',
      editGoodsReturn: 'Edit goods return',
      editOrder: 'ערוך סדר',
      cloneWayBill: 'שכפול CMR',
      cloneDespatchAdvice: 'Clone the Despatch advice',
      cloneDocument: 'Clone document',
      appsSwitcher: 'Applications switcher',
      messagesCenter: 'Messages center',
    },
    inputs: {
      min_input_length: 'Minimum %{minLength} characters',
      add_new_entry: 'Add "%{entry}"',
    },
    error_messages: {
      must_be_greater: 'צריך להיות גדול מ- %{target}',
      phone_number_invalid: 'מספר טלפון שגוי',
      generic_error: 'שגיאה בלתי צפויה. אנא נסה שוב מאוחר יותר.',
      form_load_error: 'שגיאה התרחשה בעת טעינת נתונים',
      form_save_error: 'שגיאה התרחשה בעת שמירת נתונים',
      form_invalid_template: 'לא ניתן להציג את הטופס. צור קשר עם תמיכה טכנית.',
      max_decimals: 'השתמש במקסימום %{decimals} ספרות אחרי הנקודה העשרונית',
      type_number: 'ערך חייב להיות מספר',
      unable_to_display: 'המסמך עדיין אינו זמין. אנא נסה שוב מאוחר יותר.',
      quantities_use_same_sign:
        'הכמויות חייבות להיות כולן חיוביות או כולן שליליות',
      non_zero: 'ערך לא יכול להיות שווה ל-0',
      negative: 'הערך חייב להיות שלילי.',
      invalid_DXUID: 'invalid DXUID',
      invalid: 'Invalid',
      fixed_length: 'Must contain exactly %{fixedLength} digits',
      line_error: 'This line contains one or more errors.',
      OnError_contact_the_support:
        'Oups, an error occured. Please, retry later on or contact the DocProcess ' +
        'support (support@doc-process.com) [%{error}]',
      address_already_exist: 'כתובת כבר קיימת עבור GLN זה',
      company_already_exists: 'The company name or the VAT code already exist.',
      company_info_not_found: 'Company information not found',
      email_already_exists: 'This email address is already registered.',
      product_usage_already_exists: 'This product is already registered.',
      impossible_delete_company:
        'Impossible to delete company, dependencies need to be removed first',
      vat_not_found: 'Warning, VAT code not found in public directory',
      not_enough_rights: 'Not enough rigths for this operation',
      invalid_character: 'Invalid character: %{unwanted}',
      fieldTobeSet: '%{label} needs to be also set',
    },
    processStatus: {
      ACCEPTED_BY_CUSTOMER: 'התקבל ע״י נמען',
      ACCEPTED_BY_RECIPIENT: 'התקבל ע״י נמען',
      ACCOUNT_CHECKED: 'התקבל ע״י DX',
      ALLOCATION_IN_PROGRESS: 'Allocation in progress',
      CONTENT_ERROR: 'שגיאת תוכן',
      DELIVERED: 'נשלח',
      DELIVERED_FROM_CONTENT_ERROR: 'שגיאת תוכן',
      DELIVERED_FROM_FORMAT_ERROR: 'שגיאת פורמט',
      ENRICHED: 'התקבל ע״י DX',
      FORMAT_ERROR: 'שגיאת תוכן',
      INVALID_MATCHING: 'Unmatched',
      LOCALLY_DELIVERED: 'התקבל ע״י DX',
      MATCHING_ERROR: 'Matching failed',
      MATCHING_IN_PROGRESS: 'בתהליך התאמה',
      NEW: 'התקבל ע״י DX',
      NO_CREDIT: 'אין קרדיט',
      NORMALIZED: 'התקבל ע״י DX',
      NOTIFIED: 'נשלח',
      PAID_BY_RECIPIENT: 'שולם ע״י נמען',
      PARTIAL_MATCHED: 'Partial matched',
      PARTIALLY_PAID_BY_RECIPIENT: 'Partially paid by recipient',
      PENDING_ENRICHMENTS: 'ממתין להעשרה',
      PENDING_RECADV: 'ממתין להעשרה',
      PENDING_SCHEDULED_DELIVERY: 'נשלח',
      PENDING_TO_CUSTOMER: 'ממתין לנמען',
      PENDING_CORRECTION: 'Pending correction',
      RECEIVED_BY_CUSTOMER: 'התקבל ע״י נמען',
      RECEIVED_BY_DX: 'התקבל ע״י DX',
      RECEIVED_BY_RECIPIENT: 'התקבל ע״י נמען',
      RECEIVED_BY_SUPPLIER: 'Received by supplier',
      REJECTED_BY_CUSTOMER: 'נדחה ע״י נמען',
      REJECTED_BY_RECIPIENT: 'נדחה ע״י נמען',
      REJECTED_BY_SUPPLIER: 'Rejected by supplier',
      RECEIVED_BY_BSP: 'Received by Service Provider',
      RECEIVED_AND_VALIDATED_BY_BSP:
        'Received and validated by Service Provider',
      REJECTED_BY_BSP: 'Rejected by Service Provider',
      SUCCESSFUL_MATCHED: 'Matched',
      UNDER_QUERY: 'Pending info',
      CONDITIONALLY_ACCEPTED: 'Conditionally accepted',
      IN_PROCESS: 'In Process',
      SENT: 'נשלח',
      SUSPENDED: 'אין קרדיט',
      VALIDATED: 'התקבל ע״י DX',
      WAITING_FOR_RECIPIENT: 'ממתין לנמען',
      DRAFT: 'טיוטה',
      openAuditTrail: {
        tooltip:
          'Click to open the processing details drawer and have more information on the document status',
      },
    },
    readStatus: {
      NEW: 'חדש',
      DOWNLOADED: 'מסמך הורד',
      READ: 'נקרא',
      CONVERTED: 'הומר',
    },
    invoiceTypes: {
      FAM: 'FAM : חשבונית עצמית לסחורה',
      FAS: 'FAS : חשבונית עצמית לשירותים',
      FCG: 'FCG : חשבונית הוצאות כלליות',
      FIM: 'FIM : חשבונית נכסים',
      FMD: 'FMD : חשבונית הנחה',
      FME: 'FME : חשבונית סחורה חיצונית',
      FMF: 'FMF : חשבונית סחורה',
      FSR: 'FSR : חשבונית שירותים',
      FGO: 'FGO : General expenses invoice with order',
    },
    processDocumentFormatTypes: {
      ORIGINAL: 'מקור',
      ISSUER_ORIGINAL: 'מקור',
      IMAGE: 'תמונה',
      ISSUER_IMAGE: 'תמונה',
      RECIPIENT_IMAGE: 'תמונה',
      TARGET: 'יעד',
      DX: 'UBL',
    },
    archivePeriod: {
      1: '1',
      10: '10',
      Permanent: 'קבוע',
    },
    documentTypeCode: {
      APERAK: 'הודעה',
      CATLOG: 'קטלוג',
      CNTCND: 'חוזה',
      DESADV: 'הודעת משלוח',
      DELFOR: 'תחזית',
      INVOIC: 'חשבונית',
      INVOICE: 'חשבונית',
      ORDERS: 'הזמנה',
      ORDER: 'הזמנה',
      RECADV: 'הודעת קבלה',
      ATTACH: 'מסמך מצורף',
      WAYBIL: 'CMR',
    },
    regulatorExtraDetails: {
      '1': 'E-Factura outbound',
      '10': 'E-Factura inbound',
      '2': 'PEPPOL outbound',
      '20': 'PEPPOL inbound',
      '3': 'EDI outbound',
      '30': 'EDI inbound',
    },
    regulatorStatusCode: {
      '0': 'Accepted by Fiscal authority',
      '10': 'Rejected by Fiscal authority',
      '20': 'Fiscal authority internal error',
      '40': 'Pending to Fiscal authority',
      '101': 'e-factura Downloaded from SPV',
      '102': 'Fiscal error',
      '103': 'No EDI',
      '104': 'Waiting for EDI',
      '105': 'Waiting for manual completion',
      '106': 'Content uncompliant',
      '107': 'EDI invoice Paired',
      '108': 'EDI invoice Unpaired',
      '109': 'e-factura Delivered to customer',
      '110': 'e-factura Accepted by customer',
      '111': 'e-factura Rejected by customer',
      '112': 'e-factura Pending to customer',
    },
    taxPointDateCode: {
      '3': 'Invoice issue date',
      '35': 'Actual date of delivery',
      '432': 'Amount paid on the same day',
    },
    headers: {
      accountingCost: 'Accounting cost',
      companyLegalForm: 'Company legal form',
      id: 'ID',
      manufacturersItemIdentification: 'Manufacturers Code',
      transactionType: 'Transaction type',
      packageSequence: 'Package sequence ID',
      numberOfPackages: 'Number of packages',
      packingLevelCode: 'Package sequence',
      packagingTypeCode: 'Type of package',
      packageID: 'Package ID (SSCC)',
      certNumberBioProduct: 'Prod cert no for BIO',
      deliveryNoteReferenceNumber: 'Delivery Note number',
      packageIDC: 'SSCC code',
      packageMarkerID: 'Package marker ID',
      shippingContainer: 'Serial shipping container',
      bestBefore: 'Best Before',
      expiryDate: 'Expiry Date',
      lotNumberID: 'Batch number',
      tradeCode: 'Trade register number',
      supplierBank: 'Supplier Bank',
      clientBank: 'Client Bank',
      packingType: 'Type of packaging',
      UmUnitPriceBase: 'Base measure unit for unit price',
      unitPriceBase: 'Basis for the unit price',
      deliveryQuantityCode: 'Delivery quantity code',
      deliveryQuantity: 'Delivered quantity',
      unitsNumber: 'Number of units per box',
      invoiceTypeCode: 'Invoice Type Code',
      duration: 'Duration',
      periodType: 'Period Type',
      referenceReporting: 'Reference Reporting',
      referenceEvent: 'Reference Event',
      paymentDeadline: 'Payment Deadline',
      paymentMeansCode: 'Payment Type UN/ECE 4461',
      paymentMeansID: 'Payment Term (TP)',
      addition: 'Additional Information',
      customer: 'לקוח',
      customerId: 'מספר לקוח',
      status: 'סטטוס',
      documentName: 'שם מסמך',
      documents: 'מסמכים מצורפים',
      documentNumber: 'מספר מסמך',
      issueDate: 'תאריך הנפקה',
      sender: 'שולח',
      senderId: 'מספר שולח',
      recipientName: 'נמען',
      recipientId: 'מספר נמען',
      receiverId: 'Receiver ID',
      createdBy: 'נוצר ע״י',
      modifiedBy: 'שונה ע״י',
      modificationDate: 'תאריך שינוי',
      modifiedAt: 'תאריך שינוי',
      creationDate: 'תאריך יצירה',
      createdAt: 'תאריך יצירה',
      documentTypeCode: 'סוג מסמך',
      documentId: 'מספר מסמך',
      document: 'מסמך',
      excisePayerCode: 'Excise payer code',
      issuerName: 'מנפיק',
      archivePeriod: 'תקופת ארכיון',
      archiveDate: 'תאריך העברה לארכיון',
      supplier: 'ספק',
      supplierId: 'מספר ספק',
      buyer: 'קונה',
      buyerId: 'מספר קונה',
      buyerVatIdentifier: 'Customer VAT ID',
      buyerReference: 'Buyer reference',
      startedAt: 'תאריך התחלה',
      deliveryDate: 'תאריך משלוח',
      actualDeliveryDate: 'תאריך משלוח נוכחי',
      deliveryHour: 'שעת משלוח',
      parties: 'צדדים',
      orders: 'הזמנות',
      number: 'מספר',
      totalWithVat: 'סה״כ כולל מע״מ',
      totalWithoutVat: 'סה״כ ללא מע״מ',
      totalVat: 'סה״כ מע״מ',
      vat: 'מע״מ',
      vatPercent: 'VAT (%)',
      quantity: 'כמות',
      quantityLidl: 'Number of cartons/boxes',
      price: 'מחיר',
      invoiceNumber: 'מספר חשבונית',
      invoiceId: 'מספר חשבונית',
      invoiceDate: 'Invoice Date',
      orderId: 'מספר הזמנה',
      orderDate: 'תאריך הזמנה',
      forecastId: 'מספר תחזית',
      attachment: 'מסמך מצורף',
      invoiceType: 'סוג משבונית',
      processDocumentFormatType: 'סוג פורמט מסמך',
      originalFileName: 'שם קובץ מקור',
      adherentUniqueIdentifier: 'מספר יחודי של נאמן',
      issuerId: 'מספר מנפיק',
      gln: 'קוד GLN',
      deliveryLocationName: 'שם מיקום משלוח',
      locationAddress: 'כתובת מיקום משלוח',
      address: 'כתובת',
      currency: 'מטבע',
      processStatus: 'סטטוס תהליך',
      readStatus: 'סטטוס קריאה',
      receiptAdviceId: 'מספר הודעת קבלה',
      receiptAdviceDate: 'תאריך הודעת קבלה',
      despatchAdviceId: 'מספר הודעת משלוח',
      despatchAdviceDate: 'תאריך הודעת משלוח',
      contractId: 'מספר חוזה',
      contractDate: 'תאריך חוזה',
      dxuid: 'DXUID',
      documentReferenceDxuid: 'מסמך קשור DXUID',
      attachmentParentDxuid: 'קובץ מצורף הורה DXUID',
      show_metadata: 'נתונים',
      description: 'תיאור',
      referenceInvoiceId: 'חשבונית קשורה',
      referenceInvoiceDate: 'תאריך חשבונית קשורה',
      codeClient: 'קוד לקוח',
      codeStandard: 'EAN קוד',
      itemClassificationCode: 'קוד סיווג פריט',
      codeSupplier: 'קוד ספק',
      codeBuyer: 'Buyer code',
      productCodeRef: 'product ref',
      um: 'יחידות',
      messageDetails: 'פרטי הודעה',
      buyerCustomer: 'הוזמן ע״י',
      supplierReceiverCode: 'קוד ספק',
      supplierName: 'Name',
      numberOfLines: 'מספר שורות',
      user: 'משתמש',
      streetName: 'רחוב',
      additionalStreet: 'Additional street',
      buildingNumber: 'מספר בית',
      cityName: 'עיר',
      postalZone: 'מיקוד',
      countryCode: 'ארץ',
      discount: 'הנחה (%)',
      discountAmount: 'Discount amount',
      taxAmount: 'Tax amount',
      taxType: 'Tax type',
      greenTax: 'מס ירוק',
      sugarTax: 'Sugar tax',
      sgrTax: 'D.R.S.',
      unitaryGreenTax: 'Unitary green tax',
      paymentTermsQualifier: 'מזהה',
      paymentTermsStartEvent: 'תקופת התחלה',
      paymentTermsReferenceEventCode: 'קוד תקופה קשורה',
      paymentTermsSettlementPeriodCode: 'קוד תקופה',
      paymentType: 'Means of payment code UN / ECE 4461',
      paymentTermsSettlementPeriodDurationMeasure: 'משך',
      paymentTerms: 'תנאי תשלום',
      identification: 'זיהוי',
      linkedDocuments: 'מסמכים קשורים',
      carrierPartyName: 'שם מוביל',
      consignorPartyName: 'שם השולח',
      loadingLocationAddress: 'כתובת טעינה',
      loadingLocationGln: 'מיקום טעינה GLN',
      loadingLocationName: 'שם מיקום טעינה',
      unloadingLocationAddress: 'כתובת פריקה',
      unloadingLocationGln: 'מיקום פריקה GLN',
      unloadingLocationName: 'שם מיקום פריקה',
      extendedInvoiceId: 'מספר חשבונית',
      extendedInvoiceIssueDate: 'תאריך הנפקת חשבונית',
      extendedInvoiceDxuid: 'חשבוננית DXUID',
      paymentExchangeRateSourceCurrencyCode: 'קוד מטבע מקור',
      paymentExchangeRateTargetCurrencyCode: 'קוד מטבע יעד',
      paymentExchangeRateCalculationRate: 'שער חליפין',
      shippingLocation: 'מיקום משלוח',
      shipment: 'משלוח',
      shipmentInformation: 'מידע איש לקשר למשלוח',
      shipmentStage: 'שלב שילוח',
      shipmentDelivery: 'הובלת משלוח',
      shipmentTransportHandlingUnit: 'יחידת מידה לשילוח',
      orderReferenceSalesOrderId: 'מספר זהות אצל הספק',
      orderLineReferenceLineId: 'מספר שורה בהזמנה',
      orderTypeCode: 'קוד סוג משלוח',
      unitaryPrice: 'Unitary price',
      fiscalCode: 'Fiscal code',
      contactName: 'שֵׁם מִשׁפָּחָה',
      contact: 'איש קשר',
      contactPhone: 'טלפון',
      contactNote: 'פתק',
      contactIDCardSeries: 'ID card series',
      contactIDCardNumber: 'ID card number',
      contactIDCardIssuer: 'ID card issuer',
      measureUnit: 'Measure unit',
      documentSubTypeCode: 'Document Subtype',
      name: 'שֵׁם מִשׁפָּחָה',
      regulatorId: 'Fiscal authority download ID',
      regulatorStatusCode: 'Fiscal authority status code',
      regulatorExtraDetails: 'Sent/Received',
      regulatorUploadId: 'Fiscal authority upload ID',
      regulatorUploadDate: 'Fiscal authority upload date',
      grossWeightMeasure: 'Gross Weight Measure',
      grossVolumeMeasure: 'Gross Volume Measure',
      heightMeasure: 'Height Measure',
      grossWeightMeasureUm: 'Weight Measure U.M.',
      grossVolumeMeasureUm: 'Volume Measure U.M.',
      heightMeasureUm: 'Height Measure U.M.',
      heightMeasureAttributeId: 'Height Measure Attribute ID',
      grossWeightMeasureAttributeId: 'Gross Weight Measure Atribute ID',
      grossVolumeMeasureAttributeId: 'Gross Volume Measure Attribute ID',
      quantityPerPallet: 'Maximum number of cartons/boxes per pallet',
      quantityUnitPerPallet: 'U.M. Quantity',
      customizationID: 'Customization ID',
      profileID: 'Profile ID',
      projectReferenceID: 'Project reference',
      contactElectronicMail: 'Email',
      sendPDFToElectronicMail: 'Email for sending PDF',
      taxPointDate: 'VAT validity date',
      taxPointDateCode: 'VAT validity date code',
      businessProcessType: 'Business process type',
      payeeParty: 'Payee party name',
      taxRepresentativeCompanyID: 'Seller tax representative tax number',
      taxRepresentativeName: 'Seller tax representative name',
      additionalDocumentReference: 'Additional documents references',
      addressLine: 'Additional address line',
      invoicePeriodEndDate: 'End date of Invoicing period',
      invoicePeriodStartDate: 'Start date of invoicing period',
      additionalItemProperties: 'Additional item attributes',
      additionalItemPropertyName: 'Item attribute name',
      additionalItemPropertyValue: 'Item attribute value',
      baseQuantity: 'Base quantity',
      tradeRegistryNumber: 'Trade registry number',
    },
    list: {
      columns: 'עמודות',
      search: 'חיפוש',
      default: 'ברירת מחדל',
      addFilter: 'מסננים נוספים',
      clearAll: 'נקה הכל',
      goToPage: 'Go to page',
      paginationHelp:
        'Hint: You can use the LEFT and RIGHT keys to navigate between the pages or press the "g" key to go to a specific page',
    },
    filter: {
      date: {
        datePicker: 'בחר תאריכים',
        today: 'הים',
        allTime: 'איפוס תאריך',
        yesterday: 'אתמול',
        thisWeek: 'שבוע נוכחי',
        last7days: '7 ימים אחרונים',
        lastWeek: 'שבוע שעבר',
        last14days: '14 ימים אחרונים',
        thisMonth: 'חודש נוכחי',
        lastMonth: 'חודש שעבר',
        last30days: '30 ימים אחרונים',
      },
      numberRange: {
        from: 'מ',
        to: 'עד',
      },
      issuerLabels: {
        name: 'שם מנפיק',
        id: 'מספר מנפיק',
      },
      recipientLabels: {
        name: 'שם נמען',
        id: 'מספר נמען',
      },
      receiver: {
        id: 'Receiver ID',
      },
      savedQueriesSaveBtn: 'שמור שאילתא',
      savedQueriesMenu: 'שאילתות שמורות',
      savedQueriesSaveText: 'שמור שאילתא נוכחית בשם',
      savedQueriesDeleteTitle: '%{savedQuery} :מחק',
      removeQueryRemoveText: 'האם ברצונך למחוק שאילתא זו?',
      dateRange: {
        from: 'From:',
        to: 'To: ',
        invalidRange: 'Invalid date range',
      },
      apply: 'Apply',
    },
    pdf: {
      loadError: 'לא ניתן להראות תצוגה מקדימה של המסמך',
      page: 'עמוד',
      of: 'מתוך',
    },
    showMetadata: {
      error: 'לא ניתן להציג נתונים',
      aperakDetailsError: 'לא ניתן להציג פרטי הודעה',
    },
    download: {
      error: 'תקלה: לא ניתן לטעון תצוגות מסמך',
      errorBulk: 'שגיאה: לא ניתן להוריד מסמכים שנבחרו',
      inProgress: 'במהלך הורדה...',
      completed: 'הורדה הושלמה.',
      warningLimit: 'נא הגבל את הבחירה ל-200 מסמכים',
    },
    delete: {
      error: 'שגיאה: לא ניתן למחוק מסמכים שנבחרו',
      errorBulk: 'שגיאה: לא ניתן למחוק מסמכים שנבחרו',
      inProgress: 'במהלך מחיקה...',
      completed: 'המחיקה הושלמה.',
      warningLimit: 'נא הגבל את הבחירה ל-200 מסמכים',
    },
    messages: {
      update_profile_title: 'עדכון פרופיל',
      no_apps_purchased: 'האם רכשת אפליקציה?',
    },
    webForm: {
      saved: 'מסמך מספר *** %{documentId} *** נשמר ...',
      savedWithNoId: 'מסמך נשמר...',
      errorSaving: 'לא ניתן לשמור טיוטה...',
      noDocumentId: 'נא זהה את המסמך ראשית',
      sent: 'המסמך נשלח...',
      errorSending: 'לא ניתן לשלוח מסמך',
      errorLoadTemplate: 'לא ניתן למצוא תבנית טופס מתאימה',
      saveConfirmTitle: 'שמור מסמך',
      saveConfirmMessage:
        'הטופס מכיל שדות לא חוקיים. האם אתה בטוח שאתה רוצה לשמור?',
      sendUnknownError: 'אירעה שגיאה לא ידועה. אנא צור קשר עם התמיכה.',
      sendInvalidUblError: 'פורמט מסמך לא חוקי. אנא צור קשר עם התמיכה.',
      sendErrorTitle: 'שְׁגִיאָה',
      deleteLine: 'מחק את מספר שורה %{lineNumber}',
      deleteLineTooltip: 'מחק',
      deleteLineConfirm: 'האם אתה בטוח?',
      deleteLineNotifySuccess: 'מספר שורה %{lineNumber} נמחק',
      popup: {
        title: 'Did you know ?',
        content:
          'Did you know that you can convert an order to an invoice from the "Orders" menu ?',
        moreInfoTextLink: 'Click here to learn how',
        moreInfoTextLinkUrl:
          'https://support.doc-process.com/hc/en-us/articles/8880637306397-Did-you-know-you-can-automatically-convert-an-order-to-an-invoice-',
        checkboxDismissText: "Don't show this again",
      },
      unitCode: {
        EA: 'EA - unit',
        '27': '27 - theoretical ton',
        DAY: 'DAY - Day',
        GRM: 'GRM - Gram',
        H87: 'H87 - Piece',
        KGM: 'KGM - Kilogram',
        KMT: 'KMT - Kilometer',
        LM: 'LM - Metre',
        LTR: 'LTR - Litre',
        MLT: 'MLT - Millilitre',
        MTK: 'MTK - Square metre',
        MTQ: 'MTQ - Cubic metre',
        SET: 'SET - Set',
        XBG: 'XBG - Bag',
        XBX: 'XBX - Box',
        XPA: 'XPA - Packet',
        XPP: 'XPP - Unpacked article',
        XPX: 'XPX - Pallet',
      },
    },
    export: {
      visibleColumns: 'יצוא עמודות מוצגות',
      allColumns: 'יצוא כל העמודות',
    },
    auth: {
      companySelectionAtLogin:
        'You belongs to more than one companies. Select the one you want to log in to.',
      crt_password: 'סיסמא נוכחית',
      crt_password_required: 'נא הקש סיסמא נוכחית',
      crt_password_invalid: 'סיסמא שגויה.',

      new_password: 'סיסמא חדשה',
      new_password_required: 'נא הקש סיסמא חדשה',

      confirm_password: 'הקש שוב סיסמא חדשה',
      confirm_password_required: 'נא הקש שוב סיסמא חדשה',

      lost_password_title: 'שכחת את הסיסמא?',
      lost_password_desc: 'נא הקש שם משתמש כדי לאפס סיסמא',
      lost_password_email_notif:
        'אם קיים חשבון עבור משתמש זה, אימייל ישלח עם הוראות להמשך',
      lost_password_error: 'שגיאה התרחשה בעת משלוח הוראות לשחזור סיסמא',

      change_password_title: 'שינוי סיסמא',
      change_password_confirmation:
        'הסיסמא שונתה בהצלחה. אנא היכנס באמצעות הסיסמא החדשה.',
      change_password_expired:
        'פג תוקפו של לינק לאיפוס סיסמא. הסיסמא לא שונתה.',
      change_password_error: 'לא ניתן לשנות סיסמא עקב תקלה.',
      change_password_match_error: 'הסיסמאות אינן תואמות. אנא נסה שוב.',

      password_criteria_error: 'הסיסמא החדשה אינה חזקה מספיק. ראה הנחיות.',
      password_criteria_alert_title: 'מדיניות סיסמאות של',
      password_criteria_text:
        'על מנת שתהיה חזקה, על הסיסמא למלא את ההנחיות הבאות:',
      password_criteria_length: 'אורך של 8 תווים לפחות',
      password_criteria_lowercase: 'לכלול אות קטנה',
      password_criteria_uppercase: 'לכלול אות גדולה',
      password_criteria_digit: 'לכלול סיפרה',
      password_criteria_special:
        'לכלול לפחות אחד מהתווים הבאים: ! & @ # : ? + * - /  $ ( )',
      token_too_many_attempts: 'יותר מדי נסיונות. אנא נסה שוב בעוד 30 שניות.',
      token_invalid_or_already_used: 'בקשתך אינה תקינה או שתוקפה פג.',
    },
    myAccount: {
      email: 'אימייל',
      firstName: 'שם פרטי',
      enabled: 'Enabled',
      lastName: 'שם משפחה',
      phoneNumber: 'מספר טלפון',
      roles: 'הרשאות',
      profile_update_success:
        'נתוני משתמש (למעט כתובת אימייל) עודכנו בהצלחה. אם כתובת אימייל שונתה, נדרש אישור. בדוק את הדואר שלך לקבלת הנחיות.',
      profile_update_error: 'לא ניתן לעדכן פרופיל משתמש. אירעה שגיאה.',
      create_user_success:
        'The user has been  successfully created. An email has been sent to the address given for more instructions.',
      create_user_success_synchronize:
        'The user has been successfully created. An email will be sent to the address given, once company is synched with V3.',
      create_user_error: 'Unable to create the user',
      delete_user_error: 'Unable to delete this user : %{error}',
      delete_customer_admin_error:
        'It is required to have at least one administrator per company.',
      email_update_success: 'כתובת אימייל עודכנה בהצלחה.',
      email_update_error: 'לא ניתן לעדכן כתובת אימייל. אירעה שגיאה.',
      companyName: 'Company',
      generate_password_reset_link_button: 'Generate reset password link',
    },
    task: {
      documentId: 'מספר מסמך',
      documentType: 'סוג',
      due: 'תוקף',
      start_after: 'מתחיל אחרי',
      start_before: 'מתחיל לפני',
      description: 'תיאור',
      start_since: 'התחיל מאז',
      due_in: 'נדרש ב',
      orderId: 'Order ID',
      now: 'עכשיו',
      anyInvoice: 'חשבונית',
      commercialInvoice: 'חשבונית מכירות',
      cancelInvoice: 'חשבונית ביטול',
      correctiveInvoice: 'חשבונית תיקון',
      serviceInvoice: 'FSR חשבונית',
      order: 'להזמין',
      order_negotiation: 'Order Negotiation',
      in_one_day: 'בתוך יום',
      in_three_days: 'בתוך שלושה ימים',
      in_one_week: 'בתוך שבוע',
      in_two_weeks: 'בתוך שבועיים',
      in_one_month: 'בתוך חודש',
      in_three_months: 'בתוך שלושה חודשים',
      in_six_months: 'בתוך שישה חודשים',
      in_one_year: 'בתוך שנה',
      button: {
        approve: 'אישור',
        reject: 'דחייה',
        open: 'פתיחת מטלה',
      },
      invoiceToCancel: 'חשבונית לביטול',
      invoiceMatched: 'חשבונית הותאמה',
      invoiceNotMatched: 'משבונית לא תואמת',
      title: 'Trebuie să aprobați sau să refuzați factura',
      matched: 'צביר התאמה',
      request: {
        success: 'הצלחה!',
      },
      comment: {
        message: 'נא הקש הערה',
      },
      taskCountError: 'ניהול מטלות לא זמין',
      saintgobainui: {
        rejectReason: {
          incorrect_order_number: 'מספר הזמנה שגוי',
          incorrect_deliverynote_number: 'מספר משלוח שגוי',
          incorrect_loading_location: 'מיקום טעינה שגוי',
          incorrect_unloading_location: 'מיקום פריקה שגוי',
          incorrect_transport_cost: 'מחיר שילוח שגוי',
          incorrect_invoice_currency: 'מטבע חשבונית שגוי',
        },
        label: 'סיבת דחייה',
        title: 'חשבונית ו-CMRs קשורים',
        additionalInfo: 'מידע נוסף',
        errorMsg: {
          missing_sap_id: 'SAP ID חסר. יש להוסיף אחד כדי לאשר את המטלה',
          max_sap_id_length: 'אורך מקסימלי של SAP ID הוא 10 תווים',
          missing_rej_reason: 'סיבת דחייה חסרה. נא בחר אחת מהרשימה',
          rej_reason_with_accept:
            'לא ניתן לספק סיבת דחייה או מידע נוסף בעת אישור חשבונית. נא מחק על מנת להמשיך',
          sapid_with_rej:
            'לא ניתן לספק SAP ID בעת אישור חשבונית. נא מחק על מנת להמשיך',
        },
      },
      multimedia: {
        card: {
          title: 'Invoice Validation',
          subheader: 'Please, approve or reject the invoice below:',
        },
      },
      reginamaria: {
        card: {
          supplier: {
            title: 'Supplier Order Negotiation',
            subheader:
              'Please make changes to the order or send it without modifications',
          },
          client: {
            title: 'Client Order Negotiation',
            subheader: 'Please approve or reject the order',
          },
        },
        table: {
          title: 'Amend Order #%{orderId}',
          deliveryTitle: 'Delivery',
          orderTitle: 'Order Items',
          startDate: 'Requested Start Date',
          endDate: 'Requested End Date',
          actualDeliveryDate: 'Actual Delivery Date',
          quantity: 'Quantity',
          unitCode: 'U.M.',
          itemId: 'Item Id',
          itemDesc: 'Item Description',
          buyerIdentification: 'Buyer Identification',
          price: 'Unit price without VAT',
          expirationDate: 'Expiration Date',
          numberOfDeliveries: 'Number of Deliveries',
          deliveryId: 'Delivery Id',
          comment: 'Comment',
          emptyDeliveryTable: 'No delivery data.',
          emptyOrderTable: 'No data found in order.',
          deleteVerification: 'Are you sure you want to delete this row?',
          bulkButton: 'Apply to all',
          modificationsApplied: 'Modifications applied',
          addRemoveColumns: 'Add or remove columns',
          nRowsSelected: '{0} row(s) selected',
          searchTooltip: 'Search',
          searchPlaceholder: 'Search',
          showColumnsTitle: 'Show Columns',
          labelRowsSelect: 'rows',
          firstTooltip: 'First Page',
          previousTooltip: 'Previous Page',
          nextTooltip: 'Next Page',
          lastTooltip: 'Last Page',
          labelDisplayedRows: '{from}-{to} of {count}',
          addTooltip: 'Add',
          deleteTooltip: 'Delete',
          editTooltip: 'Edit',
          cancelTooltip: 'Cancel',
          saveTooltip: 'Save',
          deleteText: 'Are you sure delete this row?',
          lineStatusCode: 'Line status',
        },
        lineStatusCode: {
          NO_CHANGE: 'Unchanged',
          NOT_ACCEPTED: 'Not accepted',
          UPDATED: 'Updated',
        },
        client: {
          tooltip:
            'If the lines are not highlighted green, the process added the missing data per line',
        },
        toolbar: {
          accept: 'Accept',
          send: 'Send',
          reject: 'Reject',
        },
        buttons: {
          undo: 'Undo delete',
        },
        key: {
          infoSupplier:
            'Please add any changes to the order lines in the table below. Once finished, you can send the order or if you are not happy, you can reject it.',
          infoClient:
            'These are the proposed changes by the supplier. You can accept or reject the changes.',
          originalValue: 'Original value',
          proposedValue: 'Proposed value',
        },
        errorMsg: {
          missing_rej_reason: 'Rejection reason missing',
          missing_delivery_information_line:
            'Missing delivery information for line %{lineId}',
          delivered_quantity_too_big:
            'Delivered quantity is bigger than line quantity',
          delivered_quantity_too_low:
            'Delivered quantity is lower than line quantity',
          negative_numbers: 'Negative numbers are not allowed',
          same_delivery_dates:
            'Delivery dates are the same as another entry. Please combine them',
          incorrect_quantity: "Quantity can't be higher than initial quantity",
          actual_date_mandatory: 'The actual delivery date is mandatory',
          dates_not_in_range:
            'The actual delivery must be between the requested start date and end date',
          dates_not_in_range_with_extra:
            'The actual delivery must be between the requested start date and end date + %{extra} days',
          dates_not_in_range_in_line:
            'Line %{lineId} : the actual delivery must be between the requested start date and end date',
          dates_not_in_range_with_extra_in_line:
            'Line %{lineId} : the actual delivery must be between the requested start date and end date + %{extra} days',
          incorrect_start_date: 'Incorrect start delivery date',
          incorrect_end_date: 'Incorrect end delivery date',
          price_higher_than_tolerance:
            'The new price can only be increased by %{greaterThan}% of the current price at most.',
          price_lower_than_tolerance:
            'The new price can only be lowered by %{lessThan}% of the current price at most.',
          item_quantity:
            'Delivered quantity is higher than line quantity for line %{lineId}. Please fix it before submitting',
          item_quantity_lower:
            'Delivered quantity is lower than line quantity for line %{lineId}. Please fix it before submitting',
          invalid_quantity_value:
            'The delivered quantity value entered is invalid',
          actual_date_mandatory_line:
            'The actual delivery date is missing for line %{lineId}',
          delivery_dates_inconsistency_line:
            'The delivery dates are inconsistent for line %{lineId}',
          missing_requested_start_or_end_dates_line:
            'Missing mandatory requested start or end date for line %{lineId}',
          end_date_must_be_in_future:
            "The requested end date must be in the future or equal to today's date.",
          start_date_must_be_in_future:
            "The requested start date must be in the future or equal to today's date.",
          dates_overlap: 'The requested start date and end date are not valid.',
        },
      },
      invoice_matching: {
        invoiceHoldDecisionTask: {
          title: 'התאמת חשבונית',
          subtitle: 'נא אשר או דחה את החשבונית הבאה:',
          cockpit: {
            title: 'אשר או דחה את החשבונית',
            button: {
              approve: 'אשר',
              reject: 'דחה',
            },
            nwayMatchingOfTheinvoice: 'מנגנון התאמה',
            invoiceCluster: 'מסמכים שלוקחים חלק במנגנון ההתאמה',
            comment: 'יש להקיש הערה על מנת לקבל גישה לכפתורי אשר/דחה:',
            invoiceClusterDetails:
              'החשבונית, הודעות קבלה (מקושרות לפי סדר), וכן חשובניות שהתקבלו בעבר וקשורות להודעות הקבלה',
            i2oInvoiceClusterDetails:
              'The invoice, its orders, and may be previous accepted invoices on the same orders.',
            raceConditionOnInvoiceApproval:
              'כבר אישרת את החשבונית, אבל משתמש אחר התאים חלק מהשורות באישור הקבלה, פעולה שגרמה לקונפליקט. עליך לאשר/לדחות שוב',
            i2oRaceConditionOnInvoiceApproval:
              'You have already accepted this invoice but someone else matched some of the order lines in the meantime, causing a conflict. You need to accept/reject it again.',
            incompleteCluster:
              'יתכן וההתאמה לא הושלמה כיוון שכל המסמכים המעורבים לא נטענו בזמן (הודעות קבלה חסרות?)',
            i2oIncompleteCluster:
              'The matching may be incomplete as all the involved documents have not been retrieved before the time limit (missing orders?).',
            usedExchangeRate: 'שערי חליפין בהם נעשה שימוש בהתאמה:',
            element: 'פריט',
            rules: 'חוקים',
            acceptedMinusInvoiced: 'אושר - כבר חוייב',
            unitaryNetPriceAlgo: 'net price = gross - discount + green tax',
            match: 'התאמה',
            quantityRule: 'כמות חשבונית <= סכום כמויות של הודעות קבלה',
            i2oQuantityRule:
              'Invoice quantity <= sum of matched order quantities',
            unitaryPrice: 'מחיר פריט',
            unitaryPriceRule: 'מחיר פריט בחשבונית <= מחיר פריט בהזמנה',
            i2oUnitaryPriceRule:
              'Invoice unitary net price <= order unitary price',
            correcting: 'בתיקון',
            correctionChain:
              'חשבונית רכישה לתיקון וכל חשבוניות התיקון שקשורות אליה',
            errors: {
              PartialNwayMatchingException:
                'הושגה התאמה חלקית בלבד, עקב שגיאות בשורות החשבונית:',
              InvoiceLineWithNegativeQuantity:
                'שורת חשבונית עם כמות שלילית %{invoiceId} בשורה: %{lineId}',
              CorrectiveInvoiceWithNoReferencedInvoiceId:
                'חשבונית תיקון ללא מספר קישור בחשבונית לתיקון: %{id}',
              CorrectiveWithoutSameOrderRefAsCommercial:
                'חשבונית תיקון ללא אותה הזמנה בחשבונית רכישה: %{correctiveId}, ' +
                '%{correctiveOrderId} - %s{commercialId}, %{commercialOrderId}',
              RecadvsWithDuplicateID: 'אישורי רכישה בצביר התאמה עם אותו מספר!',
              RecadvWithDuplicateLineID: 'אישורי רכישה עם מספרי שורות כפולים',
              InvoiceWithDuplicateLineID: 'חשבונית עם מספרי שורה כפולים',
              InvoiceWithNoRequiredTopOrderRef:
                'חשבונית ללא מספר הזמנה (בראש)!',
              clusteringPeriodElapsed:
                'הפסק להמתין לאישורי קבלה: תקופת ההמתנה הסתיימה!',
              i2oClusteringPeriodElapsed:
                'Stop waiting for orders: period elapsed!',
              waitingForCorrectedInvoicePeriodElapsed:
                'הפסק להמתין לאישור חשבונית: תקופת ההמתנה הסתיימה!',
              waitingForRecadvPeriodElapsed:
                'הפסק להמתין לאישורי קבלה: תקופת ההמתנה הסתיימה!',
              i2oWaitingForOrderPeriodElapsed:
                'Stop waiting for orders: period elapsed!',
            },
            wrongSgrTaxes:
              'The items were perfectly matched but some SGR taxes in the invoice lines do not match the ones in the receipt advices',
          },
        },
        cancellationDecisionTask: {
          title: 'ביטול חשבונית',
          subtitle: 'נא אשר או דחה את חשבונית הביטול הבאה:',
          cockpit: {
            title: 'אשר או דחה את חשבונית הביטול',
            button: {
              cancelInvoice: 'אישור ביטול',
              rejectCancellation: 'דחיית ביטול',
            },
            invoiceAcceptance:
              'החשבונית %{acceptance} בתאריך %{date} ע״י %{user}',
            automaticInvoiceAcceptance:
              'החשבונית %{acceptance} באופן אוטומטי בתאריך %{date}.',
            accepted: 'אושר',
            rejected: 'נדחה',
            withTheComment: 'עם ההערה:',
            invoiceNotFound: 'החשבונית לביטול לא נמצאה (מספר: %{ref}).',
            cancelInvoiceWithNoRef:
              'חשבונית ביטול זו לא מקושרת לחשבונית המקור.',
            comment: 'הערה',
            theCancelInvoice: 'חשבונית ביטול',
            theInvoiceToCancel: 'החשבונית לבטל',
            theCancelInvoiceDetails: 'פרטי חשבונית ביטול והיעדים שלה',
          },
        },
      },
    },
    uploadFiles: {
      uploadBox:
        "גרור ושחרר את הקובץ לצרף או <span class='filepond--label-action'> בחר מהמחשב </span>",
      preparing: 'בתהליך הכנה',
      ready: 'מוכן',
      clickToRemove: 'הקש להסרה',
      clickToCancel: 'הקש לביטול',
      fileTooLarge: 'קובץ גדול מדי',
      maxFileSizeIs: 'גודל מקסימלי מאושר {filesize}',
      shortFileTooLarge: 'גדול מדי',
      shortMaxFileSizeIs: 'גודל מקסימלי 30 מ״ב',
      uploadError: 'שגיאה במהלך העלאה',
      loadError: 'שגיאה במהלך הורדה',
      loadInitError: 'שגיאה במהלך טעינת תיאור קובץ',
      tapToRetry: 'הקש על העיגול כדי לנסות שוב',
      uploadRefreshInfo:
        'Uploaded files will soon be available in the attachment list. Please refresh manually.',
      successfullyCreated:
        'קובץ הועלה! הקובץ יהיה זמין ברשימת המסמכים הקרובים כשהעיבוד יסתיים!',
      removeFile: 'קובץ הוסר',
      uploadInitError: 'Upload fonctionality not currently available.',
      maxFilesReached: 'Maximum number of attached files has been reached.',
    },
    ubl: {
      packageMarkerID: 'EAN-128 or other format',
      packagingTypeCode: {
        pallet: 'pallet',
        box: 'box',
        carton: 'carton',
      },
      payment_means_code: {
        bank_transfer: 'העברה בנקאית',
        cash: 'מזומן',
        collection: 'אוסף',
      },
      payment_terms_settlement_period_code: {
        day: 'D - יום',
        weekDay: 'WD - יום בשבוע',
        month: 'M - חודש',
        year: 'Y - שנה',
      },
    },
    analytics: {
      dashboardMenuGroup: 'דשבורד',
      analytics: 'ניתוח',
      activitySummary: 'סיכום פעילות',
      timePeriod: 'תקופה',
      monthly: 'חודשי',
      daily: 'יומי',
      monthlySummary: 'סיכום פעילות חודשית',
      dailySummary: 'סיכום פעילות יומית',
      month: 'חודש',
      day: 'בחר תאריך',
      totalProcessed: 'מסמכים שעובדו',
      processedByType: 'מסמכים שעובדו לפי סוג',
      tableSummary:
        'הגרף מציג את סך המסמכים שהתקבלו ונשלחו במהלך התקופה שנבחרה.',
      processedByCompany: 'מסמכים שעובדו לפי חברה',
      documentType: 'בחר סוג מסמך',
      chartSummary: 'הגרף מציג את 10 החברות הראשונות בלבד.',
      type: 'סוג',
      documentsReceived: 'מסמכים שהתקבלו',
      documentsSent: 'מסמכים שנשלחו',
    },
    audit_trail: {
      show: 'מעקב ביקורת',
      title: 'מעקב ביקורת',
      categories: {
        FLOW_STARTED: 'עיבוד החל',
        DOCUMENT_RECEIVED: 'מסמך התקבל',
        DOCUMENT_SENT: 'מסמך נשלח',
        THIRD_PARTY_ACKNOWLEDGMENT: 'אישור צד ג',
        DOCUMENT_REPRESENTATION_GENERATED: 'תצוגה הופקה',
        DOCUMENT_ERROR: 'שגיאה',
        DOCUMENT_VALIDATION: 'Document validation',
        WAIT_FOR_USER_ACTION: 'ממתין לפעולת משתמש',
        USER_ACTION_DONE: 'פעולת משתמש הסתיימה',
        USER_ACTION_DELETED: 'Action cancelled',
        FLOW_END: 'סוף עיבוד',
        INCIDENT: 'תקלה',
      },
      types: {
        // See audit-trail-1.0.sql for the event types allowed into the trail.
        P2P_INVOICE_FORMAT_ERROR: 'Bad format!',
        P2P_INVOICE_REJECTED_BY_RECIPIENT: 'Rejected by buyer',
        P2P_INVOICE_PAID_BY_RECIPIENT: 'Paid',
        P2P_INVOICE_RECEIVED_BY_RECIPIENT: 'Reception acknowledged by buyer',
        P2P_INVOICE_RECEIVED_BY_BSP:
          'Reception acknowledged by Business Service Provider',
        P2P_INVOICE_REJECTED_BY_BSP: 'Rejected by Business Service Provider',
        P2P_INVOICE_CONTENT_ERROR: 'Content error!',
        P2P_INVOICE_CONTENT_WARNING: 'Content warning!',
        P2P_INVOICE_SENT: 'Invoice sent to buyer',
        P2P_INVOICE_RECEIVED_BY_DX: 'Received by DocProcess',
        P2P_INVOICE_END: 'End of processing',
        P2P_INVOICE_WAIT_STATUS: 'Waiting for buyer acknowledgment',
        P2P_INVOICE_ACCEPTED_BY_RECIPIENT: 'Accepted by buyer',
        P2P_INVOICE_WAITING_FOR_RECIPIENT: 'Waiting for buyer action',
        P2P_INVOICE_SENT_TO_REGULATOR: 'Invoice sent to the fiscal authority',
        P2P_INVOICE_WAIT_STATUS_FROM_REGULATOR:
          'Waiting for fiscal authority response',
        P2P_INVOICE_STATUS_FROM_REGULATOR: 'Fiscal authority response',
        P2P_INVOICE_PARTIALLY_PAID_BY_RECIPIENT: 'Partially paid by recipient',
        P2P_INVOICE_RECEIVED_AND_VALIDATED_BY_BSP:
          'Received and validated by Business Service Provider',

        P2P_INVOICE_UNDER_QUERY: 'Pending info',
        P2P_INVOICE_CONDITIONALLY_ACCEPTED: 'Conditionally accepted',
        P2P_INVOICE_IN_PROCESS: 'In process on recipient side',
        P2P_INVOICE_SET_GROUP: 'Invoice group has been set',

        P2P_ORDER_CONTENT_ERROR: 'Content error!',
        P2P_ORDER_FORMAT_ERROR: 'Bad format!',
        P2P_ORDER_RECEIVED_BY_DX: 'Received by DocProcess',
        P2P_ORDER_SENT: 'Order sent to supplier',
        P2P_ORDER_RECEIVED_BY_RECIPIENT: 'Reception acknowledged by supplier',
        P2P_ORDER_END: 'End of processing',

        P2P_ORDER_NOT_CHANGED: 'The order was not modified by the supplier',
        P2P_ORDER_CHANGED: 'The order was modified by the supplier',
        P2P_ORDER_RECEIVED_BY_SUPPLIER:
          'The order was received by the supplier',
        P2P_ORDER_REJECTED_BY_RECIPIENT:
          'The order was rejected by the recipient',
        P2P_ORDER_ACCEPTED_BY_RECIPIENT:
          'The order was accepted by the recipient',
        P2P_ORDER_ACCEPTED_BY_SUPPLIER:
          'The order was accepted by the supplier',
        P2P_ORDER_REJECTED_BY_SUPPLIER:
          'The order was rejected by the supplier',

        P2P_RECADV_CONTENT_ERROR: 'Content error!',
        P2P_RECADV_FORMAT_ERROR: 'Bad format!',
        P2P_RECADV_RECEIVED_BY_DX: 'Received by DocProcess',
        P2P_RECADV_SENT: 'Receipt advice sent to supplier',
        P2P_RECADV_RECEIVED_BY_RECIPIENT: 'Reception acknowledged by supplier',
        P2P_RECADV_END: 'End of processing',

        P2P_DESADV_CONTENT_ERROR: 'Content error!',
        P2P_DESADV_FORMAT_ERROR: 'Bad format!',
        P2P_DESADV_RECEIVED_BY_DX: 'Received by DocProcess',
        P2P_DESADV_SENT: 'Despatch advice sent to buyer',
        P2P_DESADV_RECEIVED_BY_RECIPIENT: 'Reception acknowledged by buyer',
        P2P_DESADV_END: 'End of processing',

        USER_TASK_CREATED: {
          invoiceHoldDecisionTask: 'Manual reconciliation requested',
          cancellationDecisionTask: 'Manual cancellation requested',
          costCenterAllocationTask:
            'Failed to fully allocate the invoice lines! Manual allocation requested',
          invoiceValidationTask: 'Manual validation requested',
          manualEnrichment: 'Manual invoice enrichment requested',
          manualIdentification: 'Manual invoice group selection requested',
          orderSelection: 'Manual order selection requested',
        },
        USER_TASK_COMPLETED: {
          invoiceHoldDecisionTask: 'Reconciliation task done',
          cancellationDecisionTask: 'Cancellation task done',
          costCenterAllocationTask: 'Manual allocation done',
          invoiceValidationTask: 'Manual validation done',
          manualEnrichment: 'Manual invoice enrichment done',
          manualIdentification: 'Manual invoice group selection done',
          orderSelection: 'Manual order selection done',
        },
        USER_TASK_DELETED: {
          invoiceHoldDecisionTask: 'Reconciliation task deleted',
          cancellationDecisionTask: 'Cancellation task deleted',
          invoiceValidationTask: 'Validation task reassigned',
          manualEnrichment: 'Manual invoice enrichment deleted',
          manualIdentification: 'Manual invoice group selection deleted',
          orderSelection: 'Manual order selection deleted',
        },

        I2PR_STARTED: 'Matching with receipt advices started',
        I2PR_SUCCESS: 'Matching approved',
        I2PR_ERROR: 'Matching in error',
        I2PR_REJECTED: 'Matching rejected',
        I2PR_SKIPPED: 'Matching skipped',

        I2O_STARTED: 'Matching with orders started',
        I2O_SUCCESS: 'Matching approved',
        I2O_ERROR: 'Matching in error',
        I2O_REJECTED: 'Matching rejected',
        I2O_SKIPPED: 'Matching skipped',

        ALLOCATION_STARTED: 'Try to automatically allocate general expenses',
        ALLOCATION_DONE: 'Allocation registered',
        AUTOMATIC_ALLOCATION_DONE: 'Automatic allocation passed',

        // V2 events:
        // NEW => 'FLOW_STARTED'
        PROCESSED: 'Processed',
        ACCOUNT_CHECKED: 'Account checked',
        DELIVERED_FROM_FORMAT_ERROR: 'Delivered (format error)',
        REJECTED_BY_CUSTOMER: 'Rejected by customer',
        RECEIVED_BY_CUSTOMER: 'Received by customer',
        VALIDATED: 'Validated',
        DELIVERED_FROM_CONTENT_ERROR: 'Content Error!',
        DELIVERED: 'Sent',
        NOTIFIED: 'Notified',
        RECEIVED_BY_DX: 'Received by DocProcess',
        ACCEPTED_BY_CUSTOMER: 'Accepted by customer',
        PENDING_TO_CUSTOMER: 'Pending to customer',
      },
      noTrail: 'אין מעקב ביקורת',
      descriptions: {},
    },
    messagesCenter: {
      state: {
        DRAFT: 'Draft',
        PUBLISHED: 'Published',
        title: 'State',
        help: "Message visibility. The message can be created as DRAFT and won't be visible until an edition setting it as PUBLISHED. Setting its state to PUBLISHED at creation time will make it visible immediately",
      },
      topic: {
        title: 'Topic',
        help: "Defines a topic to identify your message. This topic is displayed in the user's notifications menu",
      },
      language: {
        title: 'Language',
        help: "The message visibility takes into account the user's language choice. You can then specialize the message according to the language",
      },
      link: {
        text: 'More details',
        href: 'More info link',
        public: 'Public Link',
        knowledgeBase: 'Knowledge base link',
        help: 'Optional "more info" link to an external public link (Knowledge base link = false) or a link to the knowledge base (Knowledge base link = true)',
        show: 'Don’t show again',
      },
      type: {
        BANNER: 'Banner',
        DOWNTIME: 'Downtime',
        MARKETING: 'News',
        GENERAL: 'Operations',
        title: 'Type',
        help: 'Message category. An <<Operations>> or a <<Marketing>> message is visible on the home page for all users.',
      },
      content: {
        title: 'Message content',
        help: 'Message content limited to 300 characters and which could be multilignes. Click on Show button for an idea of the rendering',
      },
    },
  }, // End of Hebrew DxMessages
  resources: {
    // Override Hebrew react-admin reserved messages
    'management/company': {
      name: 'Companies',
    },
    'management/dxrole': {
      name: 'Role',
    },
    'management/formattype': {
      name: 'Format type',
    },
    'management/supplier': {
      name: 'Suppliers',
    },
    'management/customer': {
      name: 'Customers',
    },
    'management/productusage': {
      name: 'Products',
    },
    'management/supportedproducts': {
      name: 'Supported Products',
    },
    'management/address': {
      name: 'Addresses',
    },
    'management/bufferaddress': {
      name: 'Address import',
    },
    'management/contact': {
      name: 'Contact',
    },
    'management/dropdowndefinition': {
      name: 'Dropdown Definition',
    },
    'management/dxproduct': {
      name: 'DX Product',
    },
    'management/accountrelation': {
      name: 'Account relation',
    },
    'management/peppol': {
      name: 'Peppol',
    },
    'management/v2invoice': {
      name: 'V2 Invoices',
    },
    'management/v2order': {
      name: 'V2 Orders',
    },
    'management/v2receiptadvice': {
      name: 'V2 Receipt Advices',
    },
    'management/v2despatchadvice': {
      name: 'V2 Despatch Advices',
    },
    'efactura/monitoring/error': {
      name: 'Efactura Content Error',
    },
    'efactura/monitoring/rejected': {
      name: 'Efactura Rejected Documents',
    },
    usermgmt: {
      name: 'Accounts',
    },
    customers: {
      name: 'לקוחות',
      fields: {
        id: 'קוד',
        name: 'שם',
      },
    },
    issuers: {
      name: 'מנפיקים',
      fields: {
        id: 'קוד',
        name: 'שם',
      },
    },
    invoice: {
      name: 'חשבוניות',
    },
    despatchAdvice: {
      name: 'הודעות משלוח',
    },
    recadv: {
      name: 'הודעות קבלה',
    },
    feedbackMessage: {
      name: 'הודעות משוב',
    },
    attachment: {
      name: 'מסמכים מצורפים',
    },
    order: {
      name: 'הזמנות',
    },
    delfor: {
      name: 'תחזיות',
    },
    cntcnd: {
      name: 'חוזים',
    },
    catlog: {
      name: 'קטלוגים',
    },
    documents: {
      name: 'כל המסמכים',
    },
    waybill: {
      name: 'CMR',
    },
    webWaybill: {
      name: 'CMR',
    },
    webInvoice: {
      name: 'חשבוניות',
    },
    webDespatchAdvice: {
      name: 'הודעות משלוח',
    },
    webReceiptAdvice: {
      name: 'Receipt Advices',
    },
    webOrder: {
      name: 'הזמנות',
    },
    userprofiles: {
      name: 'פרופילי משתמש',
    },
    tasks: {
      name: 'מטלות',
      list: {
        title: 'המטלות שלי',
      },
      edit: {
        title: 'המטלה שלך',
      },
    },
    'configuration-message': {
      name: 'Messages',
    },
  }, // End of Override Hebrew react-admin reserved messages
  Forbidden: 'אסור',
  Unauthorized: 'לא מורשה',
  NoDocsFound: 'לא נמצאו מסמכים',
};

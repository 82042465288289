import {
  ArrayInput,
  DxTheme,
  TextInput,
  colors,
  useInput,
} from '@dx-ui/dx-common';
import { Button, Dialog, DialogProps, Grid, Tooltip } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { useState } from 'react';
import { FunctionField, useTranslate } from 'react-admin';
import { OptionsParams, TemplateModelElement } from '../../types';
import { FormField } from './FormField';
import CustomSimpleFormIterator from './OrderSimpleFormIterator';

const useStyles = makeStyles<DxTheme>(
  (theme) => ({
    linesMaxWidth: {
      overflowX: 'auto',
      overflowY: 'hidden',
    },
    root: {
      position: 'relative',
      width: '100%',
    },
    disabled: {
      cursor: 'default',
    },
    textFieldInput: {
      width: '14em',
      /* Chrome, Safari, Edge, Opera */
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      /* Firefox */
      '-moz-appearance': 'textfield',
      cursor: 'pointer',
    },
    clearButton: {
      width: 24,
      height: 24,
    },
    clearIcon: {
      height: 16,
      width: 0,
    },
    visibleClearIcon: {
      width: 16,
    },
    countIcon: {
      width: '24px',
      height: '24px',
      borderRadius: '50%',
      borderColor: colors.black65,
      borderWidth: '1px',
      borderStyle: 'solid',
      marginRight: 8,
      marginTop: -2,
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
  { name: 'InnerArrayLinesTable' }
);

const renderItems = (items, otherProps) =>
  items?.map((item, idx) => {
    return (
      <FormField
        {...otherProps}
        key={`${item.name}_${idx}`}
        source={item.source} // need to set it here
        field={item}
      />
    );
  });

const createNewLine = (field: TemplateModelElement) => {
  return () => {};
};

export const RenderInput = React.forwardRef<any, any>((props, ref) => {
  const { inputRef, value: inputValue, id, ...other } = props;
  const classes = useStyles(props);
  const value = inputValue.value || inputValue;
  return (
    <FunctionField
      {...other}
      key={id}
      id={id}
      ref={ref}
      render={() => {
        return (
          value?.length > 0 && (
            <div className={classes.countIcon}>
              <div>{value.length}</div>
            </div>
          )
        );
      }}
    />
  );
});

/**
 * Renders an Inner lines table
 */
const InnerArrayLinesTable = (props) => {
  const { field, readOnly: readOnlyForm, basePath, margin, InputProps } = props;

  const translate = useTranslate();
  const [open, setOpen] = useState(false);
  const classes = useStyles(props);
  const options: OptionsParams = field.options;
  const readOnly = !!readOnlyForm || !!options?.readOnly;
  const {
    input,
    meta: { touched, error, valid },
  } = useInput(props);

  const sanitizeArrayInputProps = ({
    selectValues,
    template,
    classes,
    ...rest
  }: any) => rest;

  const sanitizeItemProps = ({ field, classes, ...rest }: any) => rest;

  let disabled: boolean = false;
  const handleClick = (event) => {
    setOpen(true);
  };

  const onBlur = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
  };

  const handleClose: DialogProps['onClose'] = (event, reason) => {
    // close Dialog box only for a click on CLOSE button.
    if (reason) return;
    setOpen(false);
  };

  const RenderButton = () => {
    return (
      <div className={classes.root}>
        <Tooltip
          title={translate('ra.action.open_menu', {
            _: 'Open menu',
          })}
          enterDelay={500}
        >
          <div
            className={classNames({
              [classes.disabled]: disabled,
            })}
            aria-pressed={open ? 'true' : 'false'}
            tabIndex={disabled ? undefined : 0}
            role='button'
            aria-owns={open ? `dialog-${input.name || ''}` : undefined}
            aria-haspopup='true'
            onBlur={onBlur}
            onClick={disabled || readOnly ? undefined : handleClick}
          >
            <TextInput
              key={`${input.name}_Inner`}
              margin={margin}
              InputLabelProps={{
                shrink: input.value.length !== 0 ? true : false,
              }}
              {...props}
              inputProps={{ className: classes.textFieldInput }}
              InputProps={{
                ...InputProps,
                inputComponent: RenderInput,
                value: input.value,
                ...{ key: `${input.name}_Inner` },
              }}
              source={`${input.name}_Inner`}
              error={!!(touched && error)}
              helperText={touched && error}
              onBlur={onBlur}
              titleSuffix={field.businessTerms}
              label={
                options?.title &&
                field.label &&
                translate(field.label, { _: field.label })
              }
              style={options.style ? options.style : undefined}
              isRequired={field.required}
              validate={
                field.required
                  ? () => {
                      if (!input.value) {
                        return translate('ra.validation.required');
                      }
                      if (input.value?.length === 0) {
                        return translate('ra.validation.required');
                      }
                      return undefined;
                    }
                  : undefined
              }
            />
          </div>
        </Tooltip>
      </div>
    );
  };

  return (
    <Grid container item direction='column' spacing={2}>
      {<Grid item>{RenderButton()}</Grid>}
      <Grid item>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby={`dialog-${input.name}`}
          aria-describedby={`dialog-${input.name}`}
          maxWidth='md'
          fullWidth={true}
          id={`dialog-${input.name}`}
          key={`InnerArrayLinesTable_dialog_${input.name}`}
        >
          <DialogTitle>
            {translate(field.label, {
              _: '',
            })}
          </DialogTitle>
          <DialogContent>
            <Grid container direction='column'>
              {field.items && (
                <Grid item className={classNames(classes.linesMaxWidth)}>
                  <ArrayInput
                    {...sanitizeArrayInputProps(props)}
                    label={''}
                    key={`InnerArrayLinesTable_${input.name}`}
                  >
                    <CustomSimpleFormIterator
                      disableAdd={readOnly || !(options?.addable ?? true)}
                      disableRemove={readOnly || !(options?.removable ?? true)}
                      noComputation={options?.noComputationAddRemove ?? true}
                      createNewLine={createNewLine(field)}
                      key={`InnerArrayLinesTable_iterator_${input.name}`}
                    >
                      {renderItems(field.items, {
                        ...{ ...sanitizeItemProps(props), basePath },
                      })}
                    </CustomSimpleFormIterator>
                  </ArrayInput>
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpen(false);
              }}
              disabled={!valid}
            >
              {translate('ra.action.close')}
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </Grid>
  );
};

export default InnerArrayLinesTable;

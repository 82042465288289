import { Constants } from '@dx-ui/dx-common';
import { DataProvider } from 'ra-core';
export interface Catalog {
  supplierId: number;
  supplierCif: string;
  clientId: number;
  clientCif: string;
  description: string;
  version: string;
  catalogLinesDTO: CatalogLine[];
}
export interface CatalogLine {
  id: number;
  lineNumber: number;
  contractSubdivision: string;
  note: string;
  orderableIndicator: string;
  orderableUnit: string;
  contentUnitQuantity: number;
  orderQuantityIncrementNumeric: number;
  minimumOrderQuantity: number;
  maximumOrderQuantity: number;
  packLevelCode: string;
  lineValidityPeriod: string;
  description: string;
  packQuantity: number;
  packSizeNumeric: number;
  name: string;
  additionalInformation: string;
  brandName: string;
  modelName: string;
  buyersItemIdentification: string;
  sellersItemIdentification: string;
  manufacturersItemIdentification: string;
  standardItemIdentification: string;
  catalogueItemIdentification: string;
  additionalItemIdentification: string;
  originCountry: string;
  classifiedTaxCategory: string;
  additionalItemProperty: string;
  manufacturerParty: string;
  dimension: string;
  repacedRelatedItem: string;
  deliveryUnit: string;
  eanConversion: boolean;
  itemPrice: string;
  greenTax: string;
  userName: string;
  refersTo: string;
  vatNumber: number;
}
export class CatalogService {
  /**
   * Builds the service instance
   * @param {*} dataProvider React Admin data provider
   */
  _catalogLines: CatalogLine[] | undefined;
  _catalog: Catalog | undefined;
  _currentBuyerId: string | undefined;
  _currentSupplierId: string | undefined;
  constructor(public dataProvider: DataProvider) {}

  private static _instance: CatalogService;
  static getInstance(dataProvider: DataProvider) {
    if (!this._instance) {
      this._instance = new CatalogService(dataProvider);
    }
    return this._instance;
  }

  get catalogLines(): CatalogLine[] | undefined {
    return this._catalogLines;
  }

  set catalogLines(catalogLines: CatalogLine[] | undefined) {
    this._catalogLines = catalogLines;
  }

  get catalog(): Catalog | undefined {
    return this._catalog;
  }

  set catalog(catalog: Catalog | undefined) {
    this._catalog = catalog;
  }

  get currentBuyerId(): string | undefined {
    return this._currentBuyerId;
  }

  set currentBuyerId(buyerId: string | undefined) {
    this._currentBuyerId = buyerId;
  }

  get currentSupplierId(): string | undefined {
    return this._currentSupplierId;
  }

  set currentSupplierId(supplierId: string | undefined) {
    this._currentSupplierId = supplierId;
  }

  /**
   * gets catalog details for a relation supplier - buyer
   * @param buyerId
   */
  async getCatalog(buyerId: string): Promise<Catalog | undefined> {
    // load Catalog
    if (!this._catalog || this._currentBuyerId !== buyerId) {
      try {
        const payload = { buyerId: buyerId };
        const response = await this.dataProvider[Constants.API_GET_CATALOG](
          Constants.RESOURCE_CATALOG,
          payload
        );

        this._catalog = response.data;
        this._currentBuyerId = buyerId;
        return response.data;
      } catch (ex) {
        // eslint-disable-next-line no-console
        console.warn('No available catalog : ' + ex);
        return undefined;
      }
    } else {
      return this._catalog;
    }
  }

  /**
   * gets catalog lines for a relation supplier - buyer
   * @param buyerId
   * @param supplierId
   */
  async getCatalogLines(
    buyerId: string,
    supplierId: string
  ): Promise<CatalogLine[] | undefined> {
    if (buyerId === undefined || '' === buyerId || supplierId === undefined) {
      return undefined;
    }
    // Retrieves Catalog
    if (
      !this._catalogLines ||
      this._currentBuyerId !== buyerId ||
      this._currentSupplierId !== supplierId
    ) {
      try {
        const payload = { buyerId: buyerId, supplierId: supplierId };
        const response = await this.dataProvider[Constants.API_GET_CATALOG](
          Constants.RESOURCE_CATALOG,
          payload
        );

        const result: Catalog = response.data;

        // returns lines
        this._catalogLines = result.catalogLinesDTO;
        this._currentBuyerId = buyerId;
        this._currentSupplierId = supplierId;
        return result.catalogLinesDTO;
      } catch (ex) {
        // eslint-disable-next-line no-console
        console.warn('No available catalog : ' + ex);
        return undefined;
      }
    } else {
      return this._catalogLines;
    }
  }
}

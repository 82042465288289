import ActionButtonField from './ActionButtonField';
import ArrayLinesTable from './ArrayLinesTable';
import AutocompleteTextInputField from './AutocompleteTextInputField';
import BooleanInputField from './BooleanInputField';
import ContainerField from './ContainerField';
import DateInputField from './DateInputField';
import { FieldTypes } from './FieldTypes';
import GLNInputField from './GLNInputField';
import InnerArrayLinesTable from './InnerArrayLinesTable';
import LayoutSpacer from './LayoutSpacer';
import MessageDisplayField from './MessageDisplayField';
import NumberDisplayField from './NumberDisplayField';
import NumberInputField from './NumberInputField';
import OrderLinesTable from './OrderLinesTable';
import ReferenceInputField from './ReferenceInputField';
import SearchSelectInputField from './SearchSelectInputField';
import SelectArrayInputField from './SelectArrayInputField';
import SelectInputField from './SelectInputField';
import SelectTaxArrayInputField from './SelectTaxArrayInputField';
import SingleDateInputField from './SingleDateInputField';
import TextInputField from './TextInputField';
import TimeInputField from './TimeInputField';

const InvalidField = (props) => {
  return <span>???</span>;
  // return <JSONPretty id='json-pretty' data={props}></JSONPretty>;
};

// const DebugField = (props) => {
//   return (
//     <Grid item key={props.field.name} xs={12}>
//       {props.field.name} - {props.field.type}
//     </Grid>
//   );
// };

export const FormField = (props) => {
  const { field } = props;

  switch (field.type) {
    case FieldTypes.Container:
      return <ContainerField {...props} />;
    case FieldTypes.TextInput:
      return <TextInputField {...props} />;
    case FieldTypes.OrderLinesTable:
      return <OrderLinesTable {...props} />;
    case FieldTypes.ArrayLinesTable:
      return <ArrayLinesTable {...props} />;
    case FieldTypes.InnerArrayLinesTable:
      return <InnerArrayLinesTable {...props} />;
    case FieldTypes.SelectInput:
      return <SelectInputField {...props} />;
    case FieldTypes.SelectArrayInput:
      return <SelectArrayInputField {...props} />;
    case FieldTypes.SelectTaxArrayInput:
      return <SelectTaxArrayInputField {...props} />;
    case FieldTypes.SearchSelectInput:
      return <SearchSelectInputField {...props} />;
    case FieldTypes.AutocompleteTextInput:
      return <AutocompleteTextInputField {...props} />;
    case FieldTypes.LayoutSpacer:
      return <LayoutSpacer {...props} />;
    case FieldTypes.DateInput:
      return <DateInputField {...props} />;
    case FieldTypes.SingleDateInput:
      return <SingleDateInputField {...props} />;
    case FieldTypes.ReferenceInput:
      return <ReferenceInputField {...props} />;
    case FieldTypes.GLNInput:
      return <GLNInputField {...props} />;
    case FieldTypes.NumberInput:
      return <NumberInputField {...props} />;
    case FieldTypes.NumberDisplay:
      return <NumberDisplayField {...props} />;
    case FieldTypes.TimeInput:
      return <TimeInputField {...props} />;
    case FieldTypes.BooleanInput:
      return <BooleanInputField {...props} />;
    case FieldTypes.ActionButton:
      return <ActionButtonField {...props} />;
    case FieldTypes.MessageDisplay:
      return <MessageDisplayField {...props} />;
    // return <DebugField {...props} />;

    // return <span key={props.field.name}>Spacer: {props.field.name}</span>;
    default:
      return <InvalidField />;
  }
};

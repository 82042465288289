import { DomainMessages } from './DomainMessages';
/**
 * Romanian I18N messages.
 * In order to not alterate w/ react-admin messages, the application messages must not be defined
 * under ${locale}:ra namespace (where locale = en, ro fr , etc ..).
 * Moreover, ${locale}:resources is the namespace reserved to interact w/ react-admin resources label and title.
 * See : https://marmelab.com/react-admin/Translation.html#translating-resource-and-field-names
 */
export const roDomainMessages: DomainMessages = {
  dxMessages: {
    // Romanian Application messages
    dashboard: {
      categories: {
        general: 'General',
        'nway-matching': 'N-way matching',
        'cash-collection': 'Cash collection',
      },
      ConfigurationDescription:
        'Personalizează-ți tabloul de bord trăgând și plasând widget-urile de mai jos pe pagina principală, mutându-le și eliminându-le după cum este necesar.',
      ConfigurationSingletonDescription:
        'Această pictogramă din dreapta sus a unor widget-uri înseamnă că îl poți adăuga de mai multe ori pe tabloul de bord și poți modifica setările, astfel încât să se afișeze date diferite.',
      DesignYourHomePage: 'Personaliza tabloul de bord',
      LearnMore: 'Află mai multe',
      noAccessRightForDocXchange:
        'You do not own enough access rights to access docXchange. ' +
        'Your administrator may have forgotten to switch it on into your account. ' +
        'Please, contact him if you think you should.',
      NoData: 'Nu există date',
      NoWidgetToAdd: 'No widget to add, all of them are already on screen.',
      OnErrorContactSupport:
        'Ups, a apărut o eroare. Vă rugăm să reîncercați mai târziu sau să contactați serviciul de asistență DocProcess (support@doc-process.com) [%{error}]',
      Welcome: 'Bun venit %{firstname}',
      widgets: {
        DocumentReceptions: {
          title: 'Documente, pe zi și tip',
          during: 'Perioadă',
          NoReception: 'Niciun document găsit pentru criteriile selectate.',
        },
        InvoiceAcceptanceSummary: {
          AutoApproved: 'Auto. approved',
          ManuallyApproved: 'Manually approved',
          Rejected: 'Rejected',
          Cancelled: 'Cancelled',
          i2pr: 'Invoice/receipt advices matchings',
          i2o: 'Invoice/orders matchings',
          SetEarlierDate: 'Select an earlier date.',
          Since_le: 'De',
        },
        InvoiceCount: {
          Count: 'Facturi (%{on})',
          On: 'în data de',
          rejected: '%{count} resp.',
        },
        DocumentCountPerDayAndStatus: {
          INVOICE: {
            title: 'Facturi, pe zi și status',
          },
          ORDER: {
            title: 'Comenzi, pe zi și status',
          },
          RECADV: {
            title: 'NIR-uri, pe zi și status',
          },
          DESADV: {
            title: 'Avize de expediție, pe zi și status',
          },
          during: 'Perioadă',
          NoReception: 'Niciun document găsit pentru criteriile selectate.',
          status: {
            Accepted_by_Customer: 'Acceptată',
            Content_valid: 'Conținut validat',
            Dispute: 'Disputa',
            Format_valid: 'Format validat',
            No_credit: 'Fara credit',
            No_status: 'Alte',
            Outage: 'Întrerupere',
            Pending_Enrichments: 'Îmbogățiri în așteptare',
            Paid_by_recipient: 'Plătit',
            Pending_to_Customer: 'În așteptare din partea destinatar',
            Received_by_Customer: 'Primit',
            Received_by_DX: 'Primit de DocProcess',
            Rejected_by_Customer: 'Respins',
            Received_by_Recipient: 'Primit',
            Rejected_by_DX: 'Respins de DocProcess',
            Sent: 'Trimis',
            Transmitted_by_DX: 'Transmis prin DocProcess',
            Matching_in_Progress: 'Matching în curs',
          },
        },
        InvoiceWaitingForDocuments: {
          Count: 'Invoices waiting for documents',
        },
        InvoiceWaitingForApproval: {
          Count: 'Invoices waiting for approval',
        },
        InvoiceWaitingForYourApproval: {
          Count: 'Invoices waiting for your approval',
          overdue: 'overdue',
        },
        OrderCount: {
          Count: 'Comenzi (%{on})',
          On: 'în data de',
        },
        PendingTaskCount: {
          Count: 'Sarcini în așteptare',
          Since_le: 'Since',
        },
        RecadvCount: {
          Count: 'NIR-uri (%{on})',
          On: 'în data de',
        },
        DesadvCount: {
          Count: 'Avize de expediție (%{on})',
          On: 'în data de',
        },
        TotalVariationOnAcceptedInvoiceLines: {
          title: 'Total variation on accepted amounts',
          helper:
            'A positive amount means that you paid more than the exact prices.',
          Since: 'De',
          Since_helper: 'The date when to start computing the differences',
        },
        WelcomeBanner: { LastLogin: 'Ultima dvs. autentificare' },
        CashCollectionProjectionOverTime: {
          title: 'Proiectarea numerarului în curs',
          due: 'Neplătită L',
          overdue: 'Depăşit',
          amount: 'Cantitate',
        },
        TotalAmountOwedTopTen: {
          title: 'Suma totală datorată (primii 10 clienți)',
          overdue: 'Depăşit',
          amount: 'Cantitate',
          intervals: {
            current: 'Neplătită',
            overdue_1_30: 'restante de la 1 - 30 de zile',
            overdue_31_60: 'restante de la 31 - 60 de zile',
            overdue_61_90: 'restante de la 61 - 90 de zile',
            overdue_91_180: 'restante de la 91 - 180 de zile',
            overdue_beyond_180: 'restante > 180 de zile',
          },
        },
        InformBanner: {
          title:
            'Lista documentelor aflate în procesare poate fi diferită de cea afișată în grafice.',
        },
        DocumentCompany: {
          INVOICE: 'Facturi pentru',
          ORDER: 'Comenzi pentru',
          RECADV: 'Note de intrare recepție pentru',
          DESADV: 'Avize de expediție pentru',
        },
        Tooltip: {
          message: 'click pentru a vedea detaliile pe companie',
        },
        Columns: {
          company: 'Companie',
          status: 'Status',
          count: 'Număr',
        },
        WidgetTitle: {
          name: 'Titlul diagramei',
        },
        ListButton: {
          title: 'Tabel',
        },
        SummaryTitle: {
          title: 'Rezumat pentru ',
        },
        Date: {
          month: 'Lună',
          year: 'An',
        },
        AppsWidget: {
          title: 'Aplicatiile mele',
        },
        Display: {
          title: 'Afișare',
          days: 'Zile',
          months: 'Luni',
          years: 'Ani',
        },
        DateRange: {
          title: 'Interval de date',
          last7Days: 'Ultimele 7 zile',
          last14Days: 'Ultimele 14 zile',
          thisMonth: 'Luna aceasta',
          lastMonth: 'Luna trecută',
          selectMonth: 'Selectează luna',
          thisQuarter: 'Trimestrul curent',
          lastQuarter: 'Trimestrul precedent',
          thisYear: 'Anul curent',
          lastYear: 'Anul precedent',
          selectYear: 'Selectează anul',
          last3Years: 'Ultimii 3 ani',
        },
        TableButton: {
          title: 'Afişare sub formă de tabel',
        },
        SavedQueries: {
          title: 'Preferințe salvate',
          business: {
            title: 'Întreprindere',
          },
        },
        DisplayDocuments: {
          title: 'Documente recente',
          INVOICE: {
            title: 'Ultimele facturi',
          },
          ORDER: {
            title: 'Ultimele comenzi',
          },
          DESADV: {
            title: 'Ultimele avize de expediție',
          },
          RECADV: {
            title: 'Ultimele note de intrare recepție',
          },
          documentType: {
            INVOIC: 'Factură',
            ORDERS: 'Comandă',
            ORDER: 'Comandă',
            RECADV: 'Notă intrare recepție',
            DESADV: 'Aviz de expediție',
          },
          recipient: 'Destinatar',
          issuer: 'Emitent',
          status: 'Status',
          issueDate: 'Dată emitere',
          document: 'Tip Document',
          button: 'deschide în căutare',
          clear: 'Șterge',
          limitMessage:
            'Notă: sunt afișate doar ultimele 100 de documente pentru fiecare tip selectat',
        },
      },
    },
    landingPage: {
      empty: {
        click: 'Apăsați pe',
        drop: 'si adăugati widge-urile dorite',
        openedConfigurationDrop: 'Aruncați widget-urile',
      },
      message: {
        post: 'Postat',
      },
    },
    pspMessage: 'Eşti sigur că vrei să sincronizezi?',
    catalogList: {
      menu: 'Cataloage',
    },
    catalogEdit: {
      menu: 'Editare cataloage',
    },
    v2administration: {
      menu: 'V2 Administration',
    },
    peppol: {
      menu: 'PEPPOL',
      accessPoint: {
        title: 'Peppol Participant ID',
      },
      schemaId: {
        title: 'Peppol: ID Schemă',
      },
      value: {
        title: 'Peppol: Valoare',
      },
      company_identification: {
        title: 'Cod TVA destinatar',
      },
      company_name: {
        title: 'Nume companie destinatar',
      },
      issuer_identification: {
        title: 'Cod TVA emitent',
      },
      country: {
        title: 'Țară destinatar',
      },
      error: 'Nu poate fi şters.',
    },
    monitoring: {
      menuMonitoring: 'Monitorizare',
      efactura: {
        incidentId: 'Id incident',
        filename: 'Nume fisier',
        reason: 'Motiv',
        status: 'Status',
        userId: 'Id utiliazator',
        date: 'Data',
        eventDateStart: 'Data eveniment inceput',
        eventDateEnd: 'Data eveniment sfarsit',
        ignore: 'Ignora',
        supplierNotified: 'Notifica furnizor',
        createTask: 'Deschide tichet Jira',
      },
    },
    companyManagement: {
      menu: 'Companii',
      menuV2: 'utilizator și companie (V2)',
      globalView: 'V2 Vedere Globală',
      documentSearch: 'V2 Căutare Documente',
      redirect: 'Mergi la',
      contractedProducts: 'Produse contractate',
      suppliers: 'Furnizori',
      customers: 'Clienți',
      contacts: 'Contacte',
      account: {
        title: 'Companie',
        name: 'Nume companie',
        vatCode: 'Cod fiscal',
        registryNumber: 'Numar de inregistrare',
        corporateStock: 'Suma stoc corporativ',
        uidIdentification: 'UID Identification',
        idstatus: 'Status',
        idformattype: 'Tip format',
        idformattypedesadv: 'Tip format desadv',
        supplierId: 'Id furnizor',
        sync: 'SYNC',
        success: 'Compania a fost sincronizata cu succes',
        error: 'Eroare la sincronizare',
        isCompanyAdmin: 'Admin companie',
        v3Synchronize: 'Sincronizare V3 planificata',
        country: 'Țară',
        detailsTooltip:
          'Această opțiune este disponibilă doar pentru companii ANAF.',
        detailsTooltipRo:
          'Această opțiune este disponibilă doar pentru România.',
        getDetails: 'obține detalii',
        downloadCsvTemplate: 'Descărcați șablonul CSV',
        processing: 'Se procesează ...',
        language: 'Limbă',
      },
      users: {
        userId: 'Id utilizator',
        role: 'Rolul Utilizatorului',
        title: 'Utilizatori',
        help: 'O listă cu toate rolurile care pot fi atribuite utilizatorului. Un rol este disponibil dacă compania are acces la feature și rolul de utilizator este atribuit.',
        isCompanyAdmin: 'Administrator companie',
      },
      contact: {
        title: 'Contact',
        lastName: 'Nume',
        firstName: 'Prenume',
        phone: 'Telefon',
        mail: 'E-mail',
      },
      dropdowndef: {
        title: 'Definitie derulanta',
        description: 'Descriere',
        idtype: 'Tip id',
        value: 'Valoare',
        delete_dropdown_error:
          'Nu poate fi sters, produsul selectat este în curs de utilizare.',
      },
      formattype: {
        comment: 'Comentariu',
        description: 'Descriere',
        idDocumentType: 'Tip Document',
        delete_formattype_error:
          'Nu poate fi sters, tipul de format selectat este în curs de utilizare.',
      },
      dxrole: {
        name: 'Nume',
        adminOnly: 'Admin',
        delete_dxrole_error:
          'Nu poate fi sters, rolul selectat este în curs de utilizare.',
      },
      dxProduct: {
        title: 'DX Product',
        description: 'Descriere',
        comments: 'Comentarii',
        flgActive: 'flgActive',
        idProductGroup: 'Id grup produs',
        delete_product_error:
          'Nu poate fi sters, elementul selectat este în curs de utilizare.',
      },
      accountRelation: {
        title: 'Relații de cont',
        customer: 'Client',
        customerId: 'ID Client',
        supplier: 'Furnizor',
        supplierId: 'ID Furnizor',
        suppliercode4customer: 'Codul furnizorului pentru clienți',
        delete_account_error:
          'Nu poate fi sters, contul selectat este în curs de utilizare.',
      },
      addresses: {
        title: 'Adrese',
        addressType: 'Tip Adresa',
        street: 'Strada',
        district: 'Judet',
        additionalStreet: 'Strada aditionala',
        buildingNumber: 'Numar cladire',
        postBox: 'Cutie postala',
        city: 'Oras',
        postalCode: 'Cod postal',
        financialAccount: 'IBAN',
        bank: 'Banca',
        gln: 'GLN',
        country: 'Cod Tara',
        flgBilling: 'Adresa facturare',
        flgShipping: 'Adresa livrare',
        flgLogistic: 'Adresa logistica',
      },
      addressBuffer: {
        delete: 'STERGE',
        message: 'Adresa a fost sincronizata cu succes',
      },
      productUsage: {
        product: 'Produs',
        title: 'Produse Contractate',
        totalusage: 'Total consumat',
        totalcredit: 'Total credit',
        thresold: 'Prag alerta',
        advailible: 'Disponibil',
      },
      supportedProducts: {
        product: 'Produs',
        title: 'Produse Suportate',
        delete_supportedproduct_error:
          'Nu poate fi sters, produsul selectat este în curs de utilizare.',
      },
      audit: {
        tsChange: 'Ultima modificare realizata la',
        userIdChange: 'Ultima modificare realizata de',
      },
      commonDocumentColumns: {
        currentState: 'Stare curentă',
        dxuid: 'DXUID',
        sourceCif: 'Sursă CIF',
        destinationCif: 'Destinație CIF',
        originalFileName: 'Nume fișier original',
        ingestDate: 'Dată înregistrare',
        ingestDateBefore: 'Dată înregistrare înainte',
        ingestDateAfter: 'Dată înregistrare după',
        invoiceId: 'ID factură',
        orderId: 'ID comanda',
        receiptAdviceId: 'ID notă intrare recepție',
        despatchAdviceId: 'ID aviz de expediție',
        issuingDate: 'Data începere',
        issuingDateStart: 'Data începere emitere',
        issuingDateEnd: 'Data sfârșit emitere',
        customerName: 'Client',
        supplierName: 'Furnizor',
      },
    },
    invoices: {
      title: 'Facturi',
      document_label: 'Factură',
      edit_title: 'Editare factură ',
      create_title: 'Creați factură',
      show_title: 'Factura : ',
      status_history_title: 'Istoricul statutului',
      payment_method: 'Metoda de plată',
      payment_means_code: 'Metoda de plată',
      due_date: 'Data scadentă',
      billing_period: 'Perioadă facturare',
      iban: 'IBAN',
      bank: 'Banca',
      initialInvoice: 'Factura inițială',
      lines: 'Linie',
      lineId: 'ID linie de comandă',
      invoiceNumber: 'Număr factură',
      delivery: 'Livrare',
      note: 'Notă',
      accounting_code_code: 'Cod costuri contabile',
      deliveryDate_IssueDate_error:
        'Data livrării trebuie să fie inferioară sau egală cu data emiterii facturii',
      issueDate_DueDate_error:
        'Data scadentă trebuie să fie superioară sau egală cu data emiterii facturii',
      referenceIssueDate_issueDate_error:
        'Data scadentă trebuie să fie superioară sau egală cu data scadentă a facturii de referință',
      issueDate_error: 'Data emiterii nu poate depăși data curentă',
      dateWithoutId_error: 'Această dată nu poate fi setată fără ID-ul aferent',
      fmdlinenote_error:
        'Va rugam adaugati valoarea notei "Invoicing period: yyyyMMdd-yyyyMMdd" \n. Exemplu. Invoicing period: 20200701-20200731',
      sgrWithoutRef_error: 'Vă rugăm să definiți câmpul "produsului"',
      valueWithoutName_error:
        'Această valoare nu poate fi definită fără numele asociat',
      orderIdSalesOrderID_error:
        'Vă rugăm să introduceți valoarea "NA" pentru Număr comandă (BT-13), deoarece nu poate fi lăsată necompletată',
      tax_summary_vat_percent: 'Cota TVA (%)',
      tax_summary_taxable_amount: 'Baza impozabilă (%{currencyID})',
      tax_summary_tax_amount: 'Valoare TVA',
      tax_category_code: 'Codul categoriei fiscale',
      fetchBuyerDetailsButtonLabel: 'Completeaza informatiile',
      fetchBuyerDetailsTooltip:
        'Adu informatiile despre companie pe baza codului fiscal',
      taxCategory: {
        standard: '(S) Standard',
        reverse: '(AE) Taxare inversă',
        collection: 'TVA la încasare',
        exempt: '(E) Scutit de TVA',
        freeExport: '(G) Articol de export gratuit, taxa nu se percepe',
        exemptionWithDeductibility: 'Scutit cu drept de deducere',
        exemptWithoutDeduction: 'Scutit fara drept de deducere',
        zeroVat: '(Z) Zero TVA',
        triangleTransaction: 'Tranzacție triunghiulară',
        proRata: 'Tax normal pro rata',
        specialRegime: 'Regim special (art. 1521, 1522)',
        specialExemption: 'Reg. scutire specială art 311 CF',
        shorteningSpec: 'Reg. specificație de scurtare 312 CF',
        purchaseWithInstallation:
          'Achiziții UE de bunuri cu instalare/asamblare',
        outsideOfScope: '(O) Servicii în afara domeniului de aplicare al taxei',
        EEAIntraCommunity:
          '(K) scutite de TVA pentru livrările intracomunitare de bunuri și servicii în SEE',
        L: '(L) Impozitul indirect general al Insulelor Canare',
        M: '(M) Taxa pentru producție, servicii și import în Ceuta și Melilla',
        B: '(B) Transferat (TVA), în Italia',
        '00': '(00) 0 %',
        '01': '(01) 6 %',
        '02': '(02) 12 %',
        '03': '(03) 21 %',
        '45': '(45) Reverse charge - Contractor',
        NA: '(NA) Exempt (miscelleanous)',
        FD: '(FD) Financial discount',
        SC: '(SC) Small company',
        '00_44': '(00/44) 0% Clause 44',
        '03_SE': '(03/SE) Standard exchange ',
        MA: '(MA) Margin',
        '46_GO': '(46/GO) ICD Goods',
        '47_TO': '(47/TO) ICD Manufacturing cost',
        '47_AS': '(47/AS) ICD Assembly',
        '47_DI': '(47/DI) ICD Distance',
        '47_SE': '(47/SE) ICD Services',
        '44': '(44) ICD Services B2B',
        '46_TR': '(46/TR) ICD Triangle a-B-c',
        '47_EX': '(47/EX) Export non E.U.',
        '47_EI': '(47/EI) Indirect export',
        '47_EE': '(47/EE) Export via E.U.',
        NS: '(NS) Not subject to VAT invoice/credit note',
        OSS_G: '(OSS-G) OSS Goods',
        OSS_S: '(OSS-S) OSS Services',
        OSS_I: '(OSS-I) OSS Import',
      },
      tax_reason_code: 'Codul motivului fiscal',
      taxReasonCode: {
        VATEX_EU_AE:
          '(AE) => VATEX-EU-AE: Încărcare inversă Acceptă norma EN 16931-1 BR-AE-10',
        VATEX_EU_D:
          '(E) => VATEX-EU-D: Achiziție intracomunitară din mijloace de transport second hand Mijloace de transport second hand',
        VATEX_EU_F:
          '(E) => VATEX-EU-F: Achiziție intracomunitară de bunuri second hand Bunuri second hand',
        VATEX_EU_G:
          '(G) => VATEX-EU-G: Exportul în afara UE acceptă EN 16931-1 BR-G-10',
        VATEX_EU_I:
          '(E) => VATEX-EU-I: Achiziție intracomunitară de opere de artă Opere de artă',
        VATEX_EU_IC:
          '(K) => VATEX-EU-IC: Aprovizionarea intracomunitară acceptă regula EN 16931-1 BR-IC-10',
        VATEX_EU_O:
          '(O) => VATEX-EU-O: Nu fac obiectul TVA-ului Suportă EN 16931-1 BR-O-10',
        VATEX_EU_J:
          '(E) => VATEX-EU-J: Achiziție intracomunitară de obiecte de colecție și antichități Obiecte de colecție și antichități',
        VATEX_EU_79_C:
          '(E) => VATEX-EU-79-C: Scutit în baza articolului 79 litera c din Directiva 2006/112/CE a Consiliului',
        VATEX_EU_132:
          '(E) => VATEX-EU-132: Scutit în baza articolului 132 din Directiva Consiliului 2006/112/CE',
        VATEX_EU_143:
          '(E) => VATEX-EU-143: Scutit în baza articolului 143 din Directiva Consiliului 2006/112/CE',
        VATEX_EU_148:
          '(E) => VATEX-EU-148: Scutit în baza articolului 148 din Directiva Consiliului 2006/112/CE',
        VATEX_EU_151:
          '(E) => VATEX-EU-151: Scutit în baza articolului 151 din Directiva Consiliului 2006/112/CE',
        VATEX_EU_309:
          '(E) => VATEX-EU-309: Scutit în baza articolului 309 din Directiva Consiliului 2006/112/CE',
        VATEX_FR_FRANCHISE:
          '(E) => VATEX-FR-FRANCHISE: France domestic VAT franchise in base',
        VATEX_FR_CNWVAT:
          'VATEX-FR-CNWVAT: France domestic Credit Notes without VAT',
        BETE_45: 'BETE-45: Reverse charge - Contractor',
        BETE_EX: ' BETE-EX: Exempt',
        BETE_FD: 'BETE-FD: Financial discount',
        BETE_SC: 'BETE-SC: Small company',
        BETE_00_44: 'BETE-00/44: 0% Clause 44',
        BETE_03_SE: 'BETE-03/SE: Standard exchange',
        BETE_MA: 'BETE-MA: Margin',
        BETE_46_GO: 'BETE-46/GO: Intra-community supply - Goods',
        BETE_47_TO: 'BETE-47/TO: Intra-community supply - Manufacturing cost',
        BETE_47_AS: 'BETE-47/AS: Intra-community supply - Assembly',
        BETE_47_DI: 'BETE-47/DI: Intra-community supply - Distance',
        BETE_47_SE: 'BETE-47/SE: Intra-community supply - Services',
        BETE_44: 'BETE-44: Intra-community supply - Services B2B',
        BETE_46_TR: 'BETE-46/TR: Intra-community supply - Triangle a-B-c',
        BETE_47_EX: 'BETE-47/EX: Export non E.U.',
        BETE_47_EI: 'BETE-47/EI: Indirect export',
        BETE_47_EE: 'BETE-47/EE: Export via E.U.',
        BETE_NS: 'BETE-NS: Not subject to VAT',
      },
      taxReasonCodeTooltip: {
        VATEX_EU_AE:
          'Încărcare inversă Acceptă norma EN 16931-1 BR-AE-10 (VATEX-EU-AE) - Folosiți numai cu codul categoriei TVA AE',
        VATEX_EU_D:
          'Achiziție intracomunitară din mijloace de transport second hand Mijloace de transport second hand (VATEX-EU-D)' +
          ' - Indicație că TVA a fost plătită în conformitate cu dispozițiile tranzitorii aplicabile - A se utiliza numai cu codul de categorie de TVA E',
        VATEX_EU_F:
          'Achiziție intracomunitară de bunuri second hand Bunuri second hand (VATEX-EU-F)' +
          ' - Indicarea aplicarii regimului marjei de TVA la bunurile second-hand. - A se utiliza numai cu codul categoriei TVA E',
        VATEX_EU_G:
          'Exportul în afara UE acceptă EN 16931-1 BR-G-10 (VATEX-EU-G) - Folosiți numai cu codul categoriei de TVA G',
        VATEX_EU_I:
          'Achiziție intracomunitară de opere de artă Opere de artă (VATEX-EU-I)' +
          ' - Indicarea aplicării regimului marjei de TVA pentru opere de artă. - A se utiliza numai cu codul categoriei TVA E',
        VATEX_EU_IC:
          'Aprovizionarea intracomunitară acceptă regula EN 16931-1 BR-IC-10 (VATEX-EU-IC) - Folosiți numai cu codul categoriei TVA K',
        VATEX_EU_O:
          'Nu fac obiectul TVA-ului Suportă EN 16931-1 BR-O-10 (VATEX-EU-O) - De utilizat numai cu codul categoriei TVA O',
        VATEX_EU_J:
          'Achiziție intracomunitară de obiecte de colecție și antichități Obiecte de colecție și antichități (VATEX-EU-J)' +
          ' - Indicație că s-a aplicat regimul marjei de TVA pentru obiectele de colecție și antichități. - A se utiliza numai cu codul categoriei de TVA E',
        VATEX_EU_79_C:
          'Scutit în baza articolului 79 litera c din Directiva 2006/112/CE a Consiliului (VATEX-EU-79-C) - A se utiliza numai cu codul categoriei TVA E',
        VATEX_EU_132:
          'Scutit în baza articolului 132 din Directiva Consiliului 2006/112/CE (VATEX-EU-132) - A se utiliza numai cu codul categoriei TVA E',
        VATEX_EU_143:
          'Scutit în baza articolului 143 din Directiva Consiliului 2006/112/CE (VATEX-EU-143) - A se utiliza numai cu codul categoriei TVA E',
        VATEX_EU_148:
          'Scutit în baza articolului 148 din Directiva Consiliului 2006/112/CE (VATEX-EU-148) - A se utiliza numai cu codul categoriei TVA E',
        VATEX_EU_151:
          'Scutit în baza articolului 151 din Directiva Consiliului 2006/112/CE (VATEX-EU-151) - A se utiliza numai cu codul categoriei TVA E',
        VATEX_EU_309:
          'Scutit în baza articolului 309 din Directiva Consiliului 2006/112/CE (VATEX-EU-309) - A se utiliza numai cu codul categoriei TVA E',
        VATEX_FR_FRANCHISE:
          'France domestic VAT franchise in base (VATEX-FR-FRANCHISE). For domestic invoicing in France',
        VATEX_FR_CNWVAT:
          'France domestic Credit Notes without VAT, due to supplier forfeit of VAT for discount (VATEX-FR-CNWVAT). For domestic Credit Notes only in France',
        BETE_45: 'Reverse charge - Contractor (BETE-45)',
        BETE_EX: 'Exempt (BETE-EX)',
        BETE_FD: 'Financial discount (BETE-FD)',
        BETE_SC: 'Small company (BETE-SC)',
        BETE_00_44: '0% Clause 44 (BETE-00/44)',
        BETE_03_SE: 'Standard exchange (BETE-03/SE)',
        BETE_MA: 'Margin (BETE-MA)',
        BETE_46_GO: 'Intra-community supply - Goods (BETE-46/GO)',
        BETE_47_TO: 'Intra-community supply - Manufacturing cost (BETE-47/TO)',
        BETE_47_AS: 'Intra-community supply - Assembly (BETE-47/AS)',
        BETE_47_DI: 'Intra-community supply - Distance (BETE-47/DI)',
        BETE_47_SE: 'Intra-community supply - Services (BETE-47/SE)',
        BETE_44: 'Intra-community supply - Services B2B (BETE-44)',
        BETE_46_TR: 'Intra-community supply - Triangle a-B-c (BETE-46/TR)',
        BETE_47_EX: 'Export non E.U. (BETE-47/EX)',
        BETE_47_EI: 'Indirect export (BETE-47/EI)',
        BETE_47_EE: 'Export via E.U. (BETE-47/EE)',
        BETE_NS: 'Not subject to VAT (BETE-NS)',
      },
      charge_reason_code:
        'Codul motivului taxei suplimentare la nivelul documentului',
      chargeReasonCode: {
        AA: 'AA - Promovare/Publicitate',
        AAA: 'AAA - Telecomunicații',
        AAC: 'AAC - Modificări tehnice',
        AAD: 'AAD - Producerea de produse personalizate/la cerere',
        AAE: 'AAE - Cheltuieli',
        AAF: 'AAF - În afara sediului',
        AAH: 'AAH - Procesare suplimentară',
        AAI: 'AAI - Atestare',
        AAS: 'AAS - Acceptare',
        AAT: 'AAT - Livrare urgentă',
        AAV: 'AAV - Construcție specială',
        AAY: 'AAY - Instalații aeroportuare',
        AAZ: 'AAZ - Concesie',
        ABA: 'ABA - Stocare obligatorie',
        ABB: 'ABB - Eliminare combustibil',
        ABC: 'ABC - Realimentare în aer',
        ABD: 'ABD - Ore suplimentare',
        ABF: 'ABF - Prelucrare',
        ABK: 'ABK - Diverse',
        ABL: 'ABL - Ambalare suplimentară',
        ABN: 'ABN - Dunaj',
        ABR: 'ABR - Contaneizare',
        ABS: 'ABS - Ambalaj de carton',
        ABT: 'ABT - Împachetare Hessian',
        ABU: 'ABU - Ambalaj din polietilenă',
        ACF: 'ACF - Alte tratamente',
        ACG: 'ACG - Tratament de emailare',
        ACH: 'ACH - Tratament termic',
        ACI: 'ACI - Tratament de placare',
        ACJ: 'ACJ - Vopsire',
        ACK: 'ACK - Șlefuire',
        ACL: 'ACL - Amorsare',
        ACM: 'ACM - Tratament de conservare',
        ACS: 'ACS - Montare',
        ADC: 'ADC - Consolidare',
        ADE: 'ADE - Conosament',
        ADJ: 'ADJ - Airbag',
        ADK: 'ADK - Transfer',
        ADL: 'ADL - Foaie/Hârtie de protecție/izolare',
        ADM: 'ADM - Legare',
        ADN: 'ADN - Repararea sau înlocuirea de ambalaj returnabil deteriorat',
        ADO: 'ADO - Logistică eficientă',
        ADP: 'ADP - Comercializare',
        ADQ: 'ADQ - Gamă de produse',
        ADR: 'ADR - Alte servicii',
        ADT: 'ADT - Preluare/Ridicare',
        ADW: 'ADW - Boală cronică',
        ADY: 'ADY - Introducere de produs nou',
        ADZ: 'ADZ - Livrare directă',
        AEA: 'AEA - Diversiune',
        AEB: 'AEB - Deconectare',
        AEC: 'AEC - Distribuție',
        AED: 'AED - Manipularea încărcăturii periculoase',
        AEF: 'AEF - Închirieri și leasinguri',
        AEH: 'AEH - Diferențial de locație',
        AEI: 'AEI - Realimentarea aeronavelor',
        AEJ: 'AEJ - Combustibil livrat în depozit',
        AEK: 'AEK - Plată la livrare',
        AEL: 'AEL - Serviciul de procesare al comezilor mici',
        AEM: 'AEM - Sevicii administrative',
        AEN: 'AEN - Garanție',
        AEO: 'AEO - Colectare și reciclare',
        AEP: 'AEP - Colectare taxe din drepturi de autor',
        AES: 'AES - Serviciul de inspecție veterinară',
        AET: 'AET - Constribuții asigurări sociale',
        AEU: 'AEU - Beneciar medicamente compensate/gratuite',
        AEV: 'AEV - Serviciul de protecție a mediului',
        AEW: 'AEW - Serviciul de curățire a mediului',
        AEX: 'AEX - Serviciul național de procesare a cecurilor în afara zonei de cont',
        AEY: 'AEY - Sistem național de plată',
        AEZ: 'AEZ - Sistem național de plată în domeniul conturilor',
        AJ: 'AJ - Ajustări',
        AU: 'AU - Autentificare',
        CA: 'CA - Catalogare',
        CAB: 'CAB - Transport cu tracțiune animală',
        CAD: 'CAD - Certificare',
        CAE: 'CAE - Certificat de conformitate',
        CAF: 'CAF - Certificat de origine',
        CAI: 'CAI - Tăiere',
        CAJ: 'CAJ - Serviciu consular',
        CAK: 'CAK - Colectări de la clienți',
        CAL: 'CAL - Realizare ștate de plată',
        CAM: 'CAM - Transport de numerar',
        CAN: 'CAN - Serviciu de home banking',
        CAO: 'CAO - Serviciu de acord bilateral',
        CAP: 'CAP - Serviciu de brokeraj în asigurări',
        CAQ: 'CAQ - Generare de cecuri',
        CAR: 'CAR - Comercializare în amplasament preferențial',
        CAS: 'CAS - Ridicare cu macaraua',
        CAT: 'CAT - Serviciu de personalizare a culorii',
        CAU: 'CAU - Sortare',
        CAV: 'CAV - Colectare și reciclare de baterii',
        CAW: 'CAW - Taxă/Tarif serviciu take back/reciclare produse',
        CAX: 'CAX - Eliberat de controlul calității',
        CAY: 'CAY - Reținut la controlul calității',
        CAZ: 'CAZ - Embargoul privind controlul calității',
        CD: 'CD - Încărcătură mașină',
        CG: 'CG - Curățare',
        CS: 'CS - Timbrarea țigărilor',
        CT: 'CT - Numărare și renumărare',
        DAB: 'DAB - Apect/Design',
        DAC: 'DAC - Deducere sortimentare',
        DAD: 'DAD - Șofer responsabil cu descărcarea',
        DAF: 'DAF - Debitor comun',
        DAG: 'DAG - Deducere dealer',
        DAH: 'DAH - Indemnizație/Bonificație trasnferabilă consumatorului',
        DAI: 'DAI - Dezvoltarea afacerii',
        DAJ: 'DAJ - Indemnizație de introducere',
        DAK: 'DAK - Promoție multi-buy/de tip achiziții multiple',
        DAL: 'DAL - Parteneriat',
        DAM: 'DAM - Gestionarea retururilor',
        DAN: 'DAN - Comandă minimă nu a fost atinsă',
        DAO: 'DAO - Deducere limită de vânzare',
        DAP: 'DAP - Reducere la vânzare angro',
        DAQ: 'DAQ - Comision de transfer acreditive documentare',
        DL: 'DL - Livrare',
        EG: 'EG - Inscripționare',
        EP: 'EP - Urgentare',
        ER: 'ER - Aigurare de risc valutar',
        FAA: 'FAA - Fabricare',
        FAB: 'FAB - Taxe vamale de egalizare',
        FAC: 'FAC - Manipularea mărfurilor voluminoase',
        FC: 'FC - Serviciu de transport al mărfii',
        FH: 'FH - Încărcare/Manipulare',
        FI: 'FI - Finanțare',
        GAA: 'GAA - Șlefuire',
        HAA: 'HAA - Tubulatură',
        HD: 'HD - Manipulare',
        HH: 'HH - Ridicare și remorcare',
        IAA: 'IAA - Instalare',
        IAB: 'IAB - Instalare și garanție',
        ID: 'ID - Livrare până la ușă',
        IF: 'IF - Inspecție/Control',
        IR: 'IR - Instalare și instruire',
        IS: 'IS - Facturare',
        KO: 'KO - Koshering',
        L1: 'L1 - Măsurare/Numărare/Cântărire a încărcăturii de către operator',
        LA: 'LA - Etichetare',
        LAA: 'LAA - Muncă',
        LAB: 'LAB - Reparație și retur',
        LF: 'LF - Legalizare',
        MAE: 'MAE - Montare',
        MI: 'MI - Factura de servicii electronice (mail)',
        ML: 'ML - Factura de servicii electronice pentru fiecare locație (mail)',
        NAA: 'NAA - Containere nereturnabile',
        OA: 'OA - Conectori pentru cabluri exterioare',
        PA: 'PA - Factură la livrare',
        PAA: 'PAA - Fosfatare',
        PC: 'PC - Ambalare',
        PL: 'PL - Paletizare',
        RAB: 'RAB - Reambalare',
        RAC: 'RAC - Reparație',
        RAD: 'RAD - Container returnabil',
        RAF: 'RAF - Reaprovizionare',
        RE: 'RE - Reprogramarea livrării',
        RF: 'RF - Recondiționare',
        RH: 'RH - Închiriere vagon feroviar',
        RV: 'RV - Încărcare',
        SA: 'SA - Recuperare încărătură',
        SAA: 'SAA - Transport și manipulare',
        SAD: 'SAD - Ambalare specială',
        SAE: 'SAE - Ștanțare',
        SAI: 'SAI - Descărcare',
        SG: 'SG - Ambalaj termocontractabil',
        SH: 'SH - Manipulare specială',
        SM: 'SM - Finisaj special',
        SU: 'SU - Instalare',
        TAB: 'TAB - Închiriere cisternă',
        TAC: 'TAC - Testare',
        TT: 'TT - Transport - facturare terță parte',
        TV: 'TV - Transport asigurat de furnizor',
        V1: 'V1 - Servicii drop yard',
        V2: 'V2 - Servicii drop dock',
        WH: 'WH - Depozitare',
        XAA: 'XAA - Combinați transportul în aceeași zi',
        YY: 'YY - Ridicare separată',
        ZZZ: 'ZZZ - Definit de comun acord',
      },
      discount_reason_code: 'Deduceri la nivelul documentului',
      discountReasonCode: {
        '41': '41 - Bonus pentru finalizarea lucrărilor înainte de termen',
        '42': '42 - Alt bonus',
        '60': '60 - Reducere acordată de producător consumatorului',
        '62': '62 - Datorită status-ului de militar',
        '63': '63 - Datorită unui accident de muncă',
        '64': '64 - Acord special',
        '65': '65 - Discount datorită unei erori de producție',
        '66': '66 - Discount datorită deschiderii unui nou magazin',
        '67': '67 - Discount pentru mostră',
        '68': '68 - Discount pentru un produs la sfârsit de gamă',
        '70': '70 - Discount pentru INCOTERM',
        '71': '71 - Deducere pentru depășirea volumului de vânzare',
        '88': '88 - Deducere pentru un consum mai mare de material',
        '95': '95 - Discount',
        '100': '100 - Rebat special',
        '102': '102 - Discount pe termen lung',
        '103': '103 - Discount temporar',
        '104': '104 - Discount standard',
        '105': '105 - Per cifra de afaceri anuală',
      },
      taxes: {
        label: 'Taxă unitară',
        SGT_Amount: 'Taxă zahăr',
        SGT_Percent: 'Taxă zahăr %',
        GT_Amount: 'Taxă verde',
        GT_Percent: 'Taxă verde %',
      },
      commercialInvoice: '380 - Factură comercială',
      cancelInvoice: '381 - Factură storno',
      correctiveInvoice: '384 - Factură corecție',
      selfbillingInvoice: '389 - Autofactură',
      accountingInvoice: '751 - Factură - informaţii în scopuri contabile',
      missingExchangeRate: 'Vă rugăm să introduceți cursul valutar',
      missingTargetCurrencyCode: 'Vă rugăm să introduceți codul monedei țintă',
      applyExchangeRateButtonLabel: 'Aplică cursul valutar',
      applyExchangeRateTooltip:
        'Aplicați cursul valutar pentru toate rândurile facturii',
      efactura: {
        efactura_choice_label: 'Opțiuni e-Factura',
        efactura_send: 'Trimitere e-Factura',
        efactura_no_send: 'Nu trimite e-Factura',
        efactura_only: 'doar e-Factura',
      },
      custom: {
        multimedia: {
          internal_transaction: 'Tranzacție internă',
          intra_community: 'Achiziție intracomunitară',
          import_services: 'Servicii de import',
          transport_invoice: 'Factură de transport taxabilă pe DVI',
        },
      },
      businessProcessType: {
        '1': 'Marfă',
        '2': 'Servicii sau alte achiziții',
        '3': 'Reduceri comerciale și financiare',
      },
      taxRepresentative: 'Reprezentant fiscal',
      documentLevelCharges: 'Taxe la nivel de document',
      documentLevelDiscounts: 'Deduceri la nivel de document',
      primaryAccountNumberID: 'Numărul contului principal al cardului de plată',
      orderReferenceSalesOrderId: 'Referinta dispozitiei de vânzare',
    },
    customers: {
      title: 'Clienți',
    },
    issuers: {
      title: 'Emitenți',
    },
    role: {
      meniu: 'Roluri',
      domain: 'Domeniu',
      document: 'Documente',
    },
    despatchAdvices: {
      title: 'Avize de expediție',
      document_label: 'Aviz de expediție',
      edit_title: 'Editare aviz de expediție ',
      create_title: 'Creați aviz de expediție',
      show_title: 'Aviz de expediție : ',
      number: 'Nr : ',
      despatchAdviceNumber: 'Număr aviz de expediție',
      shippingContactName: 'Nume persoană de contact',
      shippingContactPhone: 'Telefon de contact ',
      shippingContactEmail: 'Poștă electronică de contact',
      despatchSupplierPartyID: 'ID furnizor de expediere',
      shipmentId: 'ID expediere',
      shipment: 'Expediere',
      grossVolumeMeasure: 'Volumul brut',
      grossVolumeMeasureUnitCode: 'Unitate de măsură',
      netVolumeMeasure: 'Volumul net',
      netVolumeMeasureUnitCode: 'Unitate de măsură',
      totalNumberofGoods: 'Numărul total de mărfuri',
      totalNumberofGoodsUnitCode: 'Unitate de măsură',
      customsDeclaredValueAmount: 'Suma declarată a valorii vamale',
      customsDeclaredValueAmountCurrency: 'Moneda',
      specialInstructions: 'Instrucțiuni particulare',
      exportCountryCode: 'Țara exportatoare',
      shipmentStageId: 'D-ul etapei de expediere',
      transportModeCode: 'Codul modului de transport',
      transportMeansTypeCode: 'Mijloace de transport pentru etapa de expediere',
      transportMeansTypeCodeListId:
        'Lista de identificare a mijloacelor de transport',
      carrierCode: 'Codul operatorului',
      carrierName: 'Numele operatorului',
      trackingId: 'ID-ul de urmărire expediere',
      actualDeliveryDate: 'Data de livrare efectivă',
      actualDeliveryTime: 'Ora efectivă de livrare',
      requestedDeliveryDate: 'Data de livrare solicitată',
      requestedDeliveryTime: 'Ora de livrare solicitată',
      estimatedArrivalDate: 'Data estimata a sosirii',
      estimatedArrivalTime: 'Ora estimată de sosire',
      handlingUnitTypeCode: 'Unitate de măsurare a unității de transport',
      handlingUnitShippingMarks: 'Tipul de marcare, codificat',
      handlingUnitPackageQuantity: 'Numarul de pachete',
      handlingUnitPackageQuantityUnitCode:
        'Unitate de măsură pentru numărul pachetului',
      handlingUnitPackageReturnableMaterialIndicator:
        'Tipul pachetului (returnabil sau nu)',
      handlingUnitPackagePackingLevelCode: 'Codul nivelului de ambalare',
      handlingUnitPackagePackagingTypeCode: 'Tipul identificării coletului',
      missingLineOrderIDref: 'Vă rugăm să introduceți ID-ul comenzii',
      logisticOnly: 'Doar logistic',
      logisticOnlyMessage:
        'Acesta este un aviz de expediere logistică, deci nu va ajunge la cumpărător prin canalul EDI. Singurul scop pentru aceasta este acela de a permite expedierea și tipărirea expedierii',
    },
    receiptAdvices: {
      title: 'Note de intrare recepție',
      document_label: 'Notă de intrare recepție',
      document_label_return: 'Notă de retur',
      document_label_ack: 'Notă de confirmare recepție',
      palletReceiptNotice: '352 - Notificare receptie paleti',
      receiptAdviceSubType: '632 - Notă de intrare recepție',
      goodsReturnSubType: '35E - Notă de retur',
      ackReceiptSubType: '352 - Notă de confirmare recepție',
      create_goods_return_title: 'Creare notă de retur',
      edit_goods_return_title: 'Editare notă de retur ',
      create_title: 'Creare notă de intrare recepție',
      edit_title: 'Editare notă de intrare recepție ',
      show_title: 'Notă de intrare recepție : ',
      number: 'Nr : ',
      goodsReturnID: 'ID notă de intrare recepție',
      receiptAdviceID: 'ID Notă de intrare recepție',
      goodsReturnIssueDate: 'Dată emitere',
      receiptAdviceType: 'Tip notă de intrare recepție',
      goodsReturnReason: 'Motiv retur',
      licensePlateId: 'Număr de înmatriculare vehicul/(e)',
      productService: 'Produs / Serviciu',
      customerContact: 'Contact client',
      supplierContact: 'Contact furnizor',
      returnReasonAlteredMerchandise: 'Marfă deteriorată',
      returnReasonMissingMerchandise: 'Marfă lipsă',
      returnReasonExtraQuantity: 'Cantitate suplimentară',
    },
    feedbackMessages: {
      title: 'Mesaje',
      document_label: 'Mesaj',
      edit_title: 'Editare mesaj ',
      show_title: 'Mesaj : ',
      no_docs: 'Fără mesaje',
    },
    linkedDocuments: {
      no_docs: 'Fără documente',
    },
    attachments: {
      title: 'Atașamente',
      document_label: 'Atașament',
      edit_title: 'Editare atașament ',
      show_title: 'Atașament : ',
      no_docs: 'Fără Atașamente',
    },
    orders: {
      title: 'Comenzi',
      document_label: 'Comandă',
      edit_title: 'Editare comandă ',
      create_title: 'Creare comandă',
      show_title: 'Comandă : ',
      orderNumber: 'Număr comandă',
      initialOrder: '105 - Comanda inițială',
      updatedOrder: '231 - Comanda actualizata',
      updatedOrderNotModified: '320 - Confirmare de comandă',
      finalOrder: '220 - Comanda finala',
      crossDocking: '50E - Cross-docking',
      goodsReturn: '70E - Aviz de retur marfa',
      orderType: 'tipul comenzii',
    },
    forecast: {
      title: 'Planificari livrare',
      document_label: 'Planificari livrare',
      edit_title: 'Editare planificari livrare ',
      show_title: 'Planificari livrare : ',
    },
    contract: {
      title: 'Contract',
      document_label: 'Contract',
      edit_title: 'Editare contract ',
      show_title: 'Contract : ',
    },
    catalog: {
      title: 'Catalog',
      document_label: 'Catalog',
      edit_title: 'Editare catalog ',
      show_title: 'Catalog : ',
    },
    apm: {
      title:
        'Mergeți la cabina de clasificare a cheltuielilor pentru a vedea această sarcină',
      subheader:
        'Vă rugăm să discutați cu consilierul dvs. de vânzări pentru mai multe informații',
    },
    apmValidation: {
      title: 'Mergeți la Validation Cockpit pentru a vedea această sarcină',
      subheader: 'Vă rugăm să aprobați sau să respingeți factura de mai jos:',
      open_cockpit: 'Deschide Cockpit',
    },
    waybill: {
      title: 'CMRs (toate)',
      document_label: 'CMR',
      document_sublabel: 'Scrisoare de transport',
      edit_title: 'Editare CMR',
      create_title: 'Creați CMR',
      show_title: 'CMR : ',
      number: 'Număr document',
      carrierParty: 'TRANSPORTATOR',
      consigneeParty: 'DESTINATAR',
      consignorParty: 'EXPEDITOR',
      freightForwarderParty: 'TRANSPORTATORI SUCESIVI',
      note: 'Comentarii',
      partyTile: {
        vatIdentification: 'Cod fiscal',
        name: 'Nume',
        street: 'Stradă',
        additionalStreet: 'Strada 2',
        number: 'Număr',
        city: 'Oraș',
        postalCode: 'Cod poștal',
        countrySubEntityCode: 'Județ',
        countryCode: 'Țară',
        contactName: 'Contact',
        phone: 'Telefon',
        email: 'Email',
      },
      loadingTable: {
        serviceDescription: 'Tip transport',
        loadingNumber: 'Ordin de încărcare',
        licensePlate: 'Număr de înmatriculare vehicul/(e)',
        marksAndNumber: 'Mărci și numere',
        packageNumber: 'Nr de colete',
        packingMethod: 'Mod de ambalare',
        goodsNature: 'Natura mărfii',
        remarks: 'Observații',
        loadingLocation: 'LOCUL DE INCĂRCARE',
        unloadingLocation: 'LOCUL DE DESCĂRCARE',
        loadingDate: 'Dată încărcare',
        unloadingDate: 'Dată descărcare',
        costs: {
          transportCostBeforeFees: 'Cost total transport, fără taxe',
          transportFees:
            'Taxe de transport (suma tuturor taxelor: vamale, port, taxe pod, alte taxe)',
          transportCostIncludingFees: 'Cost total transport, cu taxe',
        },
        addressName: 'Nume adresă',
      },
      serviceDescription: {
        glassSale: 'Vanzare sticlă',
        glassAcquisition: 'Achiziție sticla',
        culletsReturn: 'Retur cioburi',
        shelvesReturn: 'Retur stelaje',
        fees: 'Taxe',
      },
      issueDate_loadingDate_error:
        'Trebuie să fie mai mică sau egală cu data emiterii facturii',
      unloadingDate_loadingDate_error:
        'Trebuie să fie mai mare sau egală cu data încărcării',
    },
    dxDocuments: {
      title: 'Toate documentele',
      document_label: 'Document',
      edit_title: 'ediție document ',
      show_title: 'Document : ',
    },
    state: {
      completed: 'Terminat',
      suspended: 'Suspendat',
      active: 'Activ',
    },
    appSwitcher: {
      DocProcess: {
        label:
          'Gestionați-vă documentele și fluxurile de lucru ale afacerii dumneavoastră',
      },
      DxArchive: {
        label: 'Archivează-ți documentele',
      },
      DxCatalog: {
        label: 'Gestionează-ți cataloagele de produse',
      },
      ExpenseClassificationCockpit: {
        label: 'Alocați cheltuieli pe facturile dvs',
      },
      ReconciliationCockpit: {
        label: 'Gestionați-vă conturile contabile',
      },
      DxContract: {
        label: 'Gestionați-vă contractele',
      },
      DxUpload: {
        label: 'conector inteligent DocProcess',
      },
      EtransportCockpit: {
        label: 'Declara-ti transportul rutier in Romania',
      },
      C4Cockpit: {
        label: 'Gestionați-vă documentele efactura',
      },
      EmagCockpit: {
        label: 'Gestionați-vă documentele efactura',
      },
      CoraCockpit: {
        label: 'Gestionați-vă documentele efactura',
      },
    },
    user_menu: {
      settings: 'Setări',
      language: 'Limba',
      select_language: 'Selectați o limbă',
      account_preferences: 'Cont utilizator',
      AccountNotFound: 'Contul nu a fost găsit!',
      AccountToImpersonate: 'Cont pentru a uzurpa identitatea',
      AccountToImpersonateHelper: 'e-mail sau autentificare <ENTER>',
      SwitchCompany: 'Schimbă compania',
    },
    help_menu: {
      title: 'Asistenţă',
      userGuide: 'Ghid utilizator',
      feedbacks: 'Spune-ne părerea ta',
      mailto:
        'mailto:feedback@doc-process.com?subject=Feedback cu privire la %{DxApp}',
    },
    menu: {
      home: 'Pagina principală',
      documents: 'Documente',
      create: 'Creare documente',
      administration: 'Administrare',
      tasks: 'Sarcini',
      tooltip: 'Vizualizarea documentelor',
    },
    buttons: {
      unselect: 'Deselectați',
      preview: 'Previzualizare',
      more: 'Mai multe',
      related_documents: 'Documente relaționale și mesaje',
      download: 'Descarcă',
      ediDocument: 'Document EDI',
      createNewDocument: 'Creare',
      cloneInvoice: 'Duplicare factură',
      cancelInvoice: 'Anulare factură',
      correctiveInvoice: 'Corectare factură',
      send: 'Trimite',
      convertToInvoice: 'Transformare în factură',
      convertToDespatchAdvice: 'Transformare in aviz de expediție',
      convertToReceiptAdvice: 'Transformare in aviz de recepție',
      editInvoice: 'Editare factură',
      editDespatchAdvice: 'Editare aviz de expediție',
      editWayBill: 'Editare CMR',
      editReceiptAdvice: 'Editare aviz de recepție',
      editGoodsReturn: 'Editare notă de retur',
      editOrder: 'Editare comandă',
      cloneWayBill: 'Duplicare CMR',
      cloneDespatchAdvice: 'Duplicare aviz de expediție',
      cloneDocument: 'Duplicare document',
      appsSwitcher: 'Comutator de aplicații',
      messagesCenter: 'Managementul mesajelor',
    },
    inputs: {
      min_input_length: 'Minimum %{minLength} caractere',
      add_new_entry: 'Adăugare "%{entry}"',
    },
    error_messages: {
      must_be_greater: 'Trebuie să fie mai mare decât %{target}',
      phone_number_invalid: 'Numărul de telefon este incorect',
      generic_error:
        'A apărut o eroare neașteptată. Vă rugăm să încercați din nou mai târziu.',
      form_load_error: 'O eroare s-a produs la încărcarea datelor formularului',
      form_save_error: 'O eroare s-a produs la salvarea datelor formularului',
      form_invalid_template:
        'Formularul nu poate fi afișat datorită unei erori interne. Vă rugăm să contactați suportul.',
      max_decimals: 'Vă rugăm utilizați maximum %{decimals} zecimale',
      type_number: 'Valorea trebuie să fie un număr',
      unable_to_display:
        'Documentul nu este încă disponibil. Vă rugăm să încercați din nou mai târziu.',
      quantities_use_same_sign:
        'Cantitățile pot fi doar toate positive sau toate negative',
      non_zero: 'Valoarea nu poate fi egală cu 0',
      negative: 'Valoarea trebuie să fie negativă.',
      invalid_DXUID: 'DXUID incorect',
      invalid: 'Incorect',
      fixed_length: 'Trebuie să conțină exact %{fixedLength} cifre',
      line_error: 'Linia conține una sau mai multe erori.',
      OnError_contact_the_support:
        'Ups, a apărut o eroare. Te rugăm să reîncerci mai târziu sau să contactezi serviciul ' +
        'de asistență DocProcess (support-fr@doc-process.com) [%{error}]',
      address_already_exist: 'Adresa există deja pentru acest GLN',
      company_already_exists: 'Numele companiei sau codul TVA deja exista.',
      company_info_not_found: 'Informațiile despre companie nu au fost găsite',
      email_already_exists: 'Această adresă de email este deja înregistrată.',
      product_usage_already_exists: 'Acest produs este deja înregistrat.',
      impossible_delete_company:
        'Imposibil de șters compania, mai întai trebuie ca dependințele să fie șterse',
      vat_not_found: 'Atenție, codul TVA nu a fost găsit în directorul public',
      not_enough_rights: 'Nu aveți drepturi pentru această operațiune',
      invalid_character: 'caracter invalid: %{unwanted}',
      fieldTobeSet: '%{label} trebuie, de asemenea, completat',
    },
    processStatus: {
      ACCEPTED_BY_CUSTOMER: 'Acceptat de destinatar',
      ACCEPTED_BY_RECIPIENT: 'Acceptat de destinatar',
      ACCOUNT_CHECKED: 'Recepționat de DocProcess',
      ALLOCATION_IN_PROGRESS: 'Alocarea curentă',
      CONTENT_ERROR: 'Eroare conținut',
      DELIVERED: 'Transmis',
      DELIVERED_FROM_CONTENT_ERROR: 'Eroare conținut',
      DELIVERED_FROM_FORMAT_ERROR: 'Eroare format',
      ENRICHED: 'Recepționat de DocProcess',
      FORMAT_ERROR: 'Eroare format',
      INVALID_MATCHING: 'Neconformă',
      LOCALLY_DELIVERED: 'Recepționat de DocProcess',
      MATCHING_ERROR: 'Matching în eroare',
      MATCHING_IN_PROGRESS: 'Matching în curs',
      NEW: 'Recepționat de DocProcess',
      NO_CREDIT: 'Lipsă credit',
      NORMALIZED: 'Recepționat de DocProcess',
      NOTIFIED: 'Transmis',
      PAID_BY_RECIPIENT: 'Plătit de destinatar',
      PARTIAL_MATCHED: 'Parțial conformă',
      PARTIALLY_PAID_BY_RECIPIENT: 'Plătit parțial de destinatar',
      PENDING_ENRICHMENTS: 'Asteaptă imbogățire',
      PENDING_RECADV: 'Asteaptă imbogățire',
      PENDING_SCHEDULED_DELIVERY: 'Transmis',
      PENDING_TO_CUSTOMER: 'In așteptare la destinatar',
      PENDING_CORRECTION: 'In așteptare corecție',
      RECEIVED_BY_CUSTOMER: 'Recepționat de destinatar',
      RECEIVED_BY_DX: 'Recepționat de DocProcess',
      RECEIVED_BY_RECIPIENT: 'Recepționat de destinatar',
      RECEIVED_BY_SUPPLIER: 'Recepționat de furnizor',
      REJECTED_BY_CUSTOMER: 'Respins de destinatar',
      REJECTED_BY_RECIPIENT: 'Respins de destinatar',
      REJECTED_BY_SUPPLIER: 'Respins de furnizor',
      RECEIVED_BY_BSP: 'Recepționat de Service Provider',
      RECEIVED_AND_VALIDATED_BY_BSP: 'Primit și validat de Service Provider',
      REJECTED_BY_BSP: 'Respins de Service Provider',
      SUCCESSFUL_MATCHED: 'Conformă',
      UNDER_QUERY: 'Asteptam informatii suplimentare',
      CONDITIONALLY_ACCEPTED: 'Acceptat condiționat',
      IN_PROCESS: 'Fiind prelucrat',
      SENT: 'Transmis',
      SUSPENDED: 'Lipsă credit',
      VALIDATED: 'Recepționat de DocProcess',
      WAITING_FOR_RECIPIENT: 'In așteptare la destinatar',
      DRAFT: 'Schiţă',
      openAuditTrail: {
        tooltip:
          'Faceți clic pentru a deschide fereastra cu istoricul evenimentelor și pentru a obține mai multe informații despre starea documentului',
      },
    },
    readStatus: {
      NEW: 'Nou',
      DOWNLOADED: 'Descărcat',
      READ: 'Citit',
      CONVERTED: 'Convertit',
    },
    invoiceTypes: {
      FAM: 'FAM : Autofactură marfă',
      FAS: 'FAS : Autofactură servicii',
      FCG: 'FCG : Factură cheltuieli generale',
      FIM: 'FIM : Factură imobilizări',
      FMD: 'FMD : Factură discount',
      FME: 'FME : Factură marfă externă',
      FMF: 'FMF : Factură marfă',
      FSR: 'FSR : Factură servicii',
      FGO: 'FGO : Factura de cheltuieli generale cu comanda',
    },
    processDocumentFormatTypes: {
      ORIGINAL: 'Original',
      ISSUER_ORIGINAL: 'Original',
      IMAGE: 'Imagine',
      ISSUER_IMAGE: 'Imagine',
      RECIPIENT_IMAGE: 'Imagine',
      TARGET: 'Destinație',
      DX: 'UBL',
    },
    archivePeriod: {
      1: '1',
      10: '10',
      Permanent: 'Permanent',
    },
    documentTypeCode: {
      APERAK: 'Mesaj',
      CATLOG: 'Catalog',
      CNTCND: 'Contract',
      DESADV: 'Aviz de expediție',
      DELFOR: 'Planificări livrare',
      INVOIC: 'Factură',
      INVOICE: 'Factură',
      ORDER: 'Comandă',
      ORDERS: 'Comandă',
      RECADV: 'Notă de intrare recepție (NIR)',
      ATTACH: 'Atașament',
      WAYBIL: 'CMR',
    },
    regulatorExtraDetails: {
      '1': 'E-Factura Trimise',
      '10': 'E-Factura Primite',
      '2': 'Trimis de PEPPOL',
      '20': 'Primit prin PEPPOL',
      '3': 'EDI Trimise',
      '30': 'EDI Primite',
    },
    regulatorStatusCode: {
      '0': 'Acceptat de autoritatea fiscală',
      '10': 'Respins de autoritatea fiscală',
      '20': 'Eroare internă a sistemului autoritatea fiscală',
      '40': 'În așteptare la autoritatea fiscală',
      '101': 'e-factura descărcată din SPV',
      '102': 'Eroare fiscală',
      '103': 'Fără EDI',
      '104': 'În așteptare de EDI',
      '105': 'În așteptare pentru completare manuală',
      '106': 'Conținut neconform',
      '107': 'Factura EDI asociată',
      '108': 'Factura EDI neasociată',
      '109': 'e-factura livrată către cumpărător',
      '110': 'e-factura acceptată de cumpărător',
      '111': 'e-factura respinsă de cumpărător',
      '112': 'e-factura în așteptarea cumpărătorului',
    },
    taxPointDateCode: {
      '3': 'Data emiterii facturii',
      '35': 'Data reala a livrarii',
      '432': 'Suma platita in aceeasi zi',
    },
    headers: {
      accountingCost: 'Cost contabilitate',
      companyLegalForm: 'Forma juridică companie',
      id: 'ID',
      manufacturersItemIdentification: 'Cod producator',
      transactionType: 'Tipul tranzacției',
      packageSequence: 'ID secvență împachetare',
      numberOfPackages: 'Număr pachete',
      packingLevelCode: 'Secvență ambalare',
      packagingTypeCode: 'Tipul coletului',
      packageID: 'ID-ul pachetului (SSCC)',
      deliveryNoteReferenceNumber: 'Numărul bonului de livrare',
      certNumberBioProduct: 'Nr cert prod BIO',
      packageIDC: 'Cod SSCC',
      packageMarkerID: 'ID marcare pachet',
      shippingContainer: 'Calificator pentru ID pachet',
      bestBefore: 'Dată perimare',
      expiryDate: 'Dată expirare',
      lotNumberID: 'Număr lot',
      tradeCode: 'Număr registrul comerțului',
      supplierBank: 'Bancă furnizor',
      clientBank: 'Bancă cumparator',
      packingType: 'Tip ambalaj',
      UmUnitPriceBase: 'Unitate de masură bază pentru preț unitar',
      unitPriceBase: 'Bază pentru prețul unitar',
      deliveryQuantityCode: 'Cod unitate cantitate livrată',
      deliveryQuantity: 'Cantitate livrată',
      unitsNumber: 'Număr de unitați pe bax',
      invoiceTypeCode: 'Cod tip factură',
      duration: 'Durată',
      periodType: 'Tip perioadă',
      referenceReporting: 'Raportare la eveniment de referință',
      referenceEvent: 'Eveniment referință',
      paymentDeadline: 'Termen de plată',
      paymentMeansCode: 'Cod mijloace de plată UN/ECE 4461',
      paymentMeansID: 'Termen de plată (TP)',
      addition: 'Completare factură',
      customer: 'Client',
      customerId: 'ID client',
      status: 'Status',
      documentName: 'Nume de fișier',
      documents: 'Documente relaționale',
      documentNumber: 'Număr document',
      issueDate: 'Dată emitere',
      sender: 'Expeditor',
      senderId: 'ID expeditor',
      recipientName: 'Destinatar',
      recipientId: 'ID destinatar',
      receiverId: 'ID Receptor',
      createdBy: 'Creat de',
      modifiedBy: 'Modificat de',
      modificationDate: 'Dată modificare',
      modifiedAt: 'Dată modificare',
      creationDate: 'Dată creare',
      createdAt: 'Dată creare',
      documentTypeCode: 'Tip document',
      documentId: 'ID document',
      document: 'Document',
      excisePayerCode: 'Cod platitor acciza',
      issuerName: 'Emitent',
      archivePeriod: 'Perioadă arhivare',
      archiveDate: 'Dată arhivare',
      supplier: 'Furnizor',
      supplierId: 'ID furnizor',
      buyer: 'Cumpărător',
      buyerId: 'ID cumpărător',
      buyerVatIdentifier: 'Cod de TVA client',
      buyerReference: 'Referinta Cumparatorului',
      startedAt: 'A început la',
      deliveryDate: 'Dată livrare',
      actualDeliveryDate: 'Data de livrare efectivă',
      deliveryHour: 'Ora livrare',
      parties: 'Participanți',
      orders: 'Comenzi',
      number: 'Num.',
      totalWithVat: 'Total cu TVA',
      totalWithoutVat: 'Total fără TVA',
      totalVat: 'Total TVA',
      vat: 'VAT',
      vatPercent: 'VAT (%)',
      quantity: 'Cantitate',
      quantityLidl: 'Numar de cutii/baxuri',
      price: 'Preț',
      invoiceNumber: 'Numărul facturii',
      invoiceId: 'Număr factură',
      invoiceDate: 'Dată factură',
      orderId: 'Număr comandă',
      orderDate: 'Dată comandă',
      forecastId: 'Număr planificare',
      attachment: 'Atașament',
      invoiceType: 'Tip factură',
      processDocumentFormatType: 'Tip format document',
      originalFileName: 'Nume fișier original',
      adherentUniqueIdentifier: 'ID unic aderent',
      issuerId: 'ID emitent',
      gln: 'Cod GLN',
      deliveryLocationName: 'Nume locație livrare',
      locationAddress: 'Adresă livrare',
      address: 'Adresă',
      currency: 'Moneda',
      processStatus: 'Status proces',
      readStatus: 'Status citire',
      receiptAdviceId: 'ID notă intrare de recepție',
      receiptAdviceDate: 'Dată notă intrare de recepție',
      despatchAdviceId: 'ID aviz de expediție',
      despatchAdviceDate: 'Dată aviz de expediție',
      contractId: 'ID contract',
      contractDate: 'Dată contract',
      dxuid: 'DXUID',
      documentReferenceDxuid: 'DXUID doc. referință',
      attachmentParentDxuid: 'DXUID doc. referință',
      show_metadata: 'Metadata',
      description: 'Descriere',
      referenceInvoiceId: 'Factura de referință',
      referenceInvoiceDate: 'Factura de referință dată',
      codeClient: 'Cod produs client',
      codeStandard: 'Cod produs EAN',
      itemClassificationCode: 'Cod clasificare articol',
      codeSupplier: 'Cod produs furnizor',
      codeBuyer: 'Cod produs client',
      productCodeRef: 'Cod ref produsului',
      um: 'U.M',
      messageDetails: 'Detalii mesaj',
      buyerCustomer: 'Comandat de',
      supplierReceiverCode: 'Cod furnizor',
      supplierName: 'Nume furnizor',
      numberOfLines: 'Nr. linii',
      user: 'Utilizator',
      streetName: 'Stradă',
      additionalStreet: 'Stradă suplimentară',
      buildingNumber: 'Nr clădire',
      cityName: 'Oraș',
      postalZone: 'Cod poștal',
      countryCode: 'Țară',
      discount: 'Reducere (%)',
      discountAmount: 'Suma reducerii',
      taxAmount: 'Suma taxei',
      taxType: 'Tip de taxă',
      greenTax: 'Tax verde',
      sugarTax: 'Taxa zahăr',
      sgrTax: 'S.G.R.',
      unitaryGreenTax: 'Taxa verde unitară',
      paymentTermsQualifier: 'Calificator',
      paymentTermsStartEvent: 'Start eveniment',
      paymentTermsReferenceEventCode: 'Cod eveniment de referință',
      paymentTermsSettlementPeriodCode: 'Cod perioadă',
      paymentType: 'Cod mijloace de plată UN/ECE 4461',
      paymentTermsSettlementPeriodDurationMeasure: 'Perioadă',
      paymentTerms: 'Plăți',
      identification: 'Identificare',
      linkedDocuments: 'Documente legate',
      carrierPartyName: 'Numele transportorului',
      consignorPartyName: 'Numele expeditorului',
      loadingLocationAddress: 'Adresa locului de încarcare',
      loadingLocationGln: 'GLN loc de încarcare',
      loadingLocationName: 'Nume loc de încarcare',
      unloadingLocationAddress: 'Adresa locului de descărcare',
      unloadingLocationGln: 'GLN loc de descărcare',
      unloadingLocationName: 'Nume loc de descărcare',
      extendedInvoiceId: 'Număr factură',
      extendedInvoiceIssueDate: 'Factură dată',
      extendedInvoiceDxuid: 'Factură DXUID',
      paymentExchangeRateSourceCurrencyCode: 'Codul monedei sursă',
      paymentExchangeRateTargetCurrencyCode: 'Codul monedei țintă',
      paymentExchangeRateCalculationRate: 'Cursul valutar',
      shippingLocation: 'Locația de expediere',
      shipment: 'Expediere',
      shipmentInformation: 'Informații de expediere și de contact',
      shipmentStage: 'Etapei de expediere',
      shipmentDelivery: 'Urmărire expediere',
      shipmentTransportHandlingUnit: 'Unității de transport',
      orderReferenceSalesOrderId: 'ID-ul comenzii furnizorului',
      orderLineReferenceLineId: 'Referință linie comandă',
      orderTypeCode: 'Codul tipului de comandă',
      unitaryPrice: 'Preț unitar',
      fiscalCode: 'Cod fiscal',
      contactName: 'Nume',
      contact: 'Contact',
      contactPhone: 'Téléphone',
      contactNote: 'Note',
      contactIDCardSeries: 'Serie card',
      contactIDCardNumber: 'Număr card',
      contactIDCardIssuer: 'Furnizor card',
      measureUnit: 'Unitate de măsură',
      documentSubTypeCode: 'Subtip document',
      name: 'Nume',
      regulatorId: 'ID descărcare autoritatea fiscală',
      regulatorStatusCode: 'Stare autoritatea fiscală',
      regulatorExtraDetails: 'Trimis/Primit',
      regulatorUploadId: 'ID încărcare autoritatea fiscală',
      regulatorUploadDate: 'Date încărcare autoritatea fiscală',
      grossWeightMeasure: 'Greutate Bruta',
      grossVolumeMeasure: 'Volum brut',
      heightMeasure: 'Înălțime brută',
      heightMeasureUm: 'Înălțime U.M.',
      grossWeightMeasureUm: 'Greutate U.M.',
      grossVolumeMeasureUm: 'Volum U.M.',
      heightMeasureAttributeId: 'Atribut inaltime',
      grossWeightMeasureAttributeId: 'Artibut greuate',
      grossVolumeMeasureAttributeId: 'Atribut volum',
      quantityPerPallet: 'Cantitate maxima cutii/baxuri per palet',
      quantityUnitPerPallet: 'U.M. cantitate',
      customizationID: 'ID Personalizare',
      profileID: 'ID Profil',
      projectReferenceID: 'Referința proiectului',
      contactElectronicMail: 'Email',
      sendPDFToElectronicMail: 'Email pentru trimiterea PDF',
      taxPointDate: 'Data exigibilitate TVA',
      taxPointDateCode: 'Codul datei exigibilitate TVA',
      businessProcessType: 'Tipul de proces de afaceri',
      payeeParty: 'Nume beneficiar',
      taxRepresentativeCompanyID:
        'Identificator TVA al reprezentantului fiscal al Vânzătorului',
      taxRepresentativeName: 'Nume reprezentant',
      additionalDocumentReference: 'Document de referință suplimentară',
      addressLine: 'Informații de adresare complementară',
      invoicePeriodEndDate: 'Data de sfârșit a perioadei de facturare',
      invoicePeriodStartDate: 'Data de început a perioadei de facturare',
      additionalItemProperties: 'Atributii suplimentare articolului',
      additionalItemPropertyName: 'Numele atributului articolului',
      additionalItemPropertyValue: 'Valoarea atributului articolului',
      baseQuantity: 'Cantitate de bază',
      tradeRegistryNumber: 'Număr de înregistrare',
    },
    list: {
      columns: 'Coloane',
      search: 'Căutare',
      default: 'Implicit',
      addFilter: 'Mai multe filtre',
      clearAll: 'Stergeți toate',
      goToPage: 'Mergeți la pagină',
      paginationHelp:
        'Sugestie: Puteți folosi tastele STÂNGA și DREAPTA pentru a naviga între pagini sau puteți apăsa tasta „g” pentru a merge la o anumită pagină',
    },
    filter: {
      date: {
        datePicker: 'Alege datele ',
        today: 'Azi',
        allTime: 'Resetare data',
        yesterday: 'Ieri',
        thisWeek: 'Săptămâna asta',
        last7days: 'Ultimele 7 zile',
        lastWeek: 'Săptămâna trecută',
        last14days: 'Ultimele 14 zile',
        thisMonth: 'Luna asta',
        lastMonth: 'Luna trecută',
        last30days: 'Ultimele 30 de zile',
      },
      numberRange: {
        from: 'de la',
        to: 'la',
      },
      issuerLabels: {
        name: 'Emitent',
        id: 'ID Emitent',
      },
      recipientLabels: {
        name: 'Destinatar',
        id: 'ID Destinatar',
      },
      receiver: {
        id: 'ID Receptor',
      },
      savedQueriesSaveBtn: 'Salvare căutare',
      savedQueriesMenu: 'Căutari salvate',
      savedQueriesSaveText: 'Denumire căutare',
      savedQueriesDeleteTitle: 'Ștergere : %{savedQuery}',
      removeQueryRemoveText: 'Doriți sa ștergeți căutarea?',
      dateRange: {
        from: 'De la:',
        to: 'Până la:',
        invalidRange: 'Interval de timp nevalid',
      },
      apply: 'Aplică',
    },
    pdf: {
      loadError: 'Imposibil de previzualizat documentul',
      page: 'Pagina',
      of: 'din',
    },
    showMetadata: {
      error: 'Imposibil de vizualizat metadate',
      aperakDetailsError: 'Imposibil de afișat detalii mesaj',
    },
    download: {
      error: 'Eroare: nu se pot obține reprezentările documentelor',
      errorBulk: 'Eroare: nu se pot descărca documentele selectate',
      inProgress: 'Descărcare în curs',
      completed: 'Descărcarea finalizată.',
      warningLimit:
        'Numărul maxim de selecții este limitat la 200 de elemente.',
    },
    delete: {
      error: 'Eroare: nu se pot șterge documentele selectate',
      errorBulk: 'Eroare: nu se pot șterge documentele selectate',
      inProgress: 'Ștergere în curs',
      completed: 'Ștergere finalizată.',
      warningLimit:
        'Numărul maxim de selecții este limitat la 200 de elemente.',
    },
    messages: {
      update_profile_title: 'Actualizare date profil',
      no_apps_purchased: 'Ați achiziționat vreo aplicație?',
    },
    webForm: {
      saved: 'Document ID *** %{documentId} *** salvat ...',
      savedWithNoId: 'Document salvat ...',
      errorSaving: 'Imposibil de salvat documentul ...',
      noDocumentId: 'Vă rugăm să identificați documentul înainte de a salva',
      sent: 'Document trimis ...',
      errorSending: 'Imposibil de trimis documentul ...',
      errorLoadTemplate: 'Nu a fost găsit un şablon de formular valid',
      saveConfirmTitle: 'Salvare document',
      saveConfirmMessage:
        'Formularul conține câmpuri invalide. Doriți să continuați salvarea?',
      sendUnknownError: 'S-a produs o eroare. Vă rugăm să contactați suportul.',
      sendInvalidUblError:
        'Eroare de format de document. Vă rugăm să contactați suportul.',
      sendErrorTitle: 'Eroare',
      deleteLine: 'Ștergeți numărul de linie %{lineNumber}',
      deleteLineTooltip: 'Șterge',
      deleteLineConfirm: 'Ești sigur?',
      deleteLineNotifySuccess: 'Numărul rândului %{lineNumber} a fost șters',
      popup: {
        title: 'Știai ?',
        content:
          'Știai că poți converti o comandă într-o factură din meniul "Comenzi"?',
        moreInfoTextLink: 'Află aici cum',
        moreInfoTextLinkUrl:
          'https://support.doc-process.com/hc/ro/articles/8880637306397-%C8%98tiai-c%C4%83-po%C8%9Bi-converti-automat-o-comand%C4%83-%C3%AEntr-o-factur%C4%83-',
        checkboxDismissText: 'Nu mai afișa acest mesaj altă dată',
      },
      unitCode: {
        EA: 'EA - Unitate',
        H87: 'H87 - Bucată',
        XPP: 'XPP - Articol în vrac',
        XBX: 'XBX - Cutie',
        XPA: 'XPA - Pachet',
        XPX: 'XPX - Palet',
        XBG: 'XBG - Sac',
        SET: 'SET - Set',
        KGM: 'KGM - Kilogram',
        KMT: 'KMT - Kilometru',
        GRM: 'GRM - Gram',
        '27': '27 - Tona teoretică',
        LTR: 'LTR - Litru',
        MLT: 'MLT - Mililitru',
        MTQ: 'MTQ - Metru cub',
        MTK: 'MTK - Metru pătrat',
        LM: 'LM - Metru liniar',
        DAY: 'DAY - Ziua',
      },
    },
    export: {
      visibleColumns: 'Export Coloane Vizibile',
      allColumns: 'Exportați Toate Coloanele',
    },
    auth: {
      companySelectionAtLogin:
        'Voi aparțineți mai multor companii. Selectați cea în care doriți să vă conectați.',
      crt_password: 'Parola curentă',
      crt_password_required: 'Parola curentă este obligatorie',
      crt_password_invalid: 'Parolă incorectă',

      new_password: 'Noua parolă',
      new_password_required: 'Noua parolă este obligatorie',

      confirm_password: 'Confirmare parolă',
      confirm_password_required: 'Confirmarea noii parole este obligatorie',

      lost_password_title: 'Am uitat parola',
      lost_password_desc:
        'Introduceți numele de utilizator pentru a cere schimbarea parolei',
      lost_password_email_notif:
        'Dacă un cont există pentru acest nume de utilizator, un email cu instrucțiuni tocmai a fost trimis',
      lost_password_error:
        'O eroare s-a produs în urma cererii de schimbare a parolei',

      change_password_title: 'Schimbare parolă',
      change_password_confirmation:
        'Parola dumneavoastră a fost schimbată cu succes. De acum, vă puteți conecta utilizănd noua parolă.',
      change_password_expired:
        'Adresa utilizată pentru schimbarea parolei a expirat. Parola nu a fost schimbată.',
      change_password_error:
        'O eroare s-a produs în urma încercării de schimbare a parolei. Nici o schimbare nu s-a efectuat.',
      change_password_match_error:
        'Cele două parole introduse nu corespund. Vă rugăm reîncercați.',

      password_criteria_error:
        'Noua parolă introdusă nu respectă toate criteriile de securitate.',
      password_criteria_alert_title:
        'Politica privind parola pentru procesarea documentelor',
      password_criteria_text:
        'Pentru a asigura un nivel bun de securitate, parola aleasă trebuie să îndeplinească următoarele criterii:',
      password_criteria_length: 'Să conțină cel puțin 8 caractere',
      password_criteria_lowercase: 'Să conțină cel puțin o minusculă',
      password_criteria_uppercase: 'Să conțină cel puțin o majusculă',
      password_criteria_digit: 'Să conțină cel puțin o cifră',
      password_criteria_special:
        'Să conțină cel puțin un caracter special: ! & @ # : ? + * - /  $ ( )',
      token_too_many_attempts:
        'Numărul maxim de cereri a fost depășit. Încercați din nou în 30 minute.',
      token_invalid_or_already_used:
        'Cererea dumneavoastră a expirat sau nu este validă.',
    },
    myAccount: {
      email: 'Email',
      enabled: 'Active',
      firstName: 'Prenume',
      lastName: 'Nume',
      phoneNumber: 'Număr de telefon',
      roles: 'Roluri',
      profile_update_success:
        'Datele utilizatorului (cu excepția adresei email) au fost actualizate cu succes. Dacă adresa de email a fost schimbată, e necesară o confirmare suplimentară. Verificați noua adresă de email pentru instrucțiuni.',
      profile_update_error:
        'Datorită unei erori, actualizarea datelor utilizatorului nu este posibilă.',
      create_user_success:
        'Utilizatorul a fost creat cu succes. A fost trimis un e-mail la adresa indicată pentru mai multe instrucțiuni.',
      create_user_success_synchronize:
        'Utilizatorul a fost creat cu succes. Un e-mail va fi trimis la adresa dată în momentul în care compania va fi sincronizată cu V3.',
      create_user_error: 'Nu s-a putut crea utilizatorul',
      delete_user_error: 'Nu se poate șterge acest utilizator: %{error}',
      delete_customer_admin_error:
        'Este obligatoriu sa existe cel putin un administrator.',
      email_update_success: 'Adresa email a fost actualizată cu succes.',
      email_update_error:
        'Datorită unei erori, actualizarea adresei email nu este posibilă.',
      companyName: 'Companie',
      generate_password_reset_link_button:
        'Genereaza link de resetare a parolei',
    },
    task: {
      documentId: 'ID document',
      documentType: 'Tip',
      due: 'Data scadentă',
      start_after: 'Începeți după',
      start_before: 'Începeți înainte',
      description: 'Descriere',
      start_since: 'Începeți de atunci',
      due_in: 'Data scadentă',
      orderId: 'ID comandă',
      now: 'Acum',
      anyInvoice: 'Factura',
      commercialInvoice: 'Factură marfă',
      cancelInvoice: 'Factura de storno',
      correctiveInvoice: 'Factură corectivă',
      serviceInvoice: 'Factura FSR',
      order: 'Comenzi',
      order_negotiation: 'Negocierea comenzii clientului',
      in_one_day: 'Intr-o zi',
      in_three_days: 'In trei zile',
      in_one_week: 'Într-o saptamană',
      in_two_weeks: 'În două săptămâni',
      in_one_month: 'Într-o lună',
      in_three_months: 'În trei luni',
      in_six_months: 'Peste șase luni',
      in_one_year: 'Într-un an',
      button: {
        approve: 'Aprobă factura',
        reject: 'Respinge factura',
        open: 'Deschideți sarcina',
      },
      invoiceToCancel: 'Factura de anulat',
      invoiceMatched: 'Factura potrivită',
      invoiceNotMatched: 'Factura nu se potrivește',
      title: 'Trebuie să aprobați sau să refuzați factura',
      matched: 'Grupuri de documente',
      request: {
        success: 'Succes!',
      },
      comment: {
        message: 'Vă rugăm să oferiți un comentariu',
      },
      taskCountError: 'Gestionarea sarcinilor nu este disponibilă',
      saintgobainui: {
        rejectReason: {
          incorrect_order_number: 'Număr comandă incorect',
          incorrect_deliverynote_number: 'Număr aviz incorect',
          incorrect_loading_location: 'Loc de încărcare incorect',
          incorrect_unloading_location: 'Loc de descărcare incorect',
          incorrect_transport_cost: 'Cost transport incorect',
          incorrect_invoice_currency: 'Moneda facturii incorectă',
        },
        label: 'Motivul respingerii',
        title: 'Factură și CMR-urile asociate',
        additionalInfo: 'Informații suplimentare',
        errorMsg: {
          missing_sap_id:
            'SAP ID lipsește. Trebuie să adăugați unul pentru a aproba această sarcină',
          max_sap_id_length: 'SAP ID nu poate excede 10 caractere',
          missing_rej_reason:
            'Motivul respingerii lipsește. Vă rugăm să selectați una din lista derulantă',
          rej_reason_with_accept:
            'Nu puteți selecta un motiv de respingere sau adăuga informații suplimentare atunci când acceptați o factură. Vă rugăm să le eliminați înainte de a continua',
          sapid_with_rej:
            'Nu puteți adăuga un SapId atunci când respingeți o factură. Vă rugăm să le eliminați înainte de a continua',
        },
      },
      multimedia: {
        card: {
          title: 'Validați factura',
          subheader: 'Vă rog să aprobați sau să respingeți factura de mai jos:',
        },
      },
      reginamaria: {
        card: {
          supplier: {
            title: 'Negocierea comenzii furnizorilor',
            subheader:
              'Vă rugăm să modificați comanda sau să o trimiteți fără modificări',
          },
          client: {
            title: 'Negocierea comenzii clientului',
            subheader: 'Vă rugăm să aprobați sau să respingeți comanda',
          },
        },
        table: {
          title: 'Modificați comanda #%{orderId}',
          deliveryTitle: 'Livrare',
          orderTitle: 'Articole comandate',
          startDate: 'Data de inceput solicitata',
          endDate: 'Data de final solicitata',
          actualDeliveryDate: 'Data reală de livrare',
          quantity: 'Cantitate',
          unitCode: 'U.M.',
          itemId: 'ID item',
          itemDesc: 'Descriere item',
          buyerIdentification: 'Identificarea cumparatorului',
          price: 'Pret unitar fara TVA',
          expirationDate: 'Data de expirare',
          numberOfDeliveries: 'Numar de livrari',
          deliveryId: 'ID livare',
          comment: 'Comentariu',
          emptyDeliveryTable: 'Fara data de livrare',
          emptyOrderTable: 'Nu au fost gasite date in comanda',
          deleteVerification: 'Sunteti sigur ca doriti sa stergeti acest rand?',
          bulkButton: 'Aplica la toate',
          modificationsApplied: 'S-au aplicat modificări',
          addRemoveColumns: 'Adăugați sau eliminați coloane',
          nRowsSelected: '{0} rând(e) selectat(e)',
          searchTooltip: 'Căutare',
          searchPlaceholder: 'Căutare',
          showColumnsTitle: 'Afișați coloanele',
          labelRowsSelect: 'rânduri',
          firstTooltip: 'Prima pagină',
          previousTooltip: 'Pagina anterioară',
          nextTooltip: 'Pagina următoare',
          lastTooltip: 'Ultima pagină',
          labelDisplayedRows: '{from}-{to} din {count}',
          addTooltip: 'Adăugați',
          deleteTooltip: 'Șterge',
          editTooltip: 'Editați',
          cancelTooltip: 'Anulare',
          saveTooltip: 'Salva',
          deleteText: 'Sigur ștergeți acest rând?',
          lineStatusCode: 'Starea liniei',
        },
        lineStatusCode: {
          NO_CHANGE: 'Neschimbat',
          NOT_ACCEPTED: 'Nu este acceptat',
          UPDATED: 'Modificat',
        },
        client: {
          tooltip:
            'Daca liniile nu sunt evidentiate in verde, procesul a adăugat datele lipsă pe linie',
        },
        toolbar: {
          accept: 'Accept',
          send: 'Trimis',
          reject: 'Respins',
        },
        buttons: {
          undo: 'Anulați ștergerea',
        },
        key: {
          infoSupplier:
            'Adăugați orice modificări in iniile de comandă din tabelul de mai jos. Dupa ce ati terminat, puteți trimite comanda sau dacă nu sunteți mulțumit, o puteți respinge.',
          infoClient:
            'Acestea sunt modificarile propuse de furnizor. Le poti accepta sau respinge.',
          originalValue: 'Valoare orginala',
          proposedValue: 'Valoare propusa',
        },
        errorMsg: {
          missing_rej_reason: 'Motivul respingerii lipseste',
          missing_delivery_information_line:
            'Lipsesc informații de livrare pentru linia %{lineId}',
          delivered_quantity_too_big:
            'Cantitatea livrată este mai mare decât cantitatea pe linie',
          delivered_quantity_too_low:
            'Cantitatea livrată este mai mică decât cantitatea pe linie',
          negative_numbers: 'Numere negative nu sunt permise',
          same_delivery_dates: 'Te rugam sa ne combini',
          incorrect_quantity:
            'Cantitatea nu poate fi mai mare decat cantitatea initiala.',
          actual_date_mandatory: 'Data reală de livrare este obligatorie',
          dates_not_in_range:
            'Livrarea efectivă trebuie să fie între data de început și data de încheiere solicitată',
          dates_not_in_range_with_extra:
            'Livrarea efectivă trebuie să fie între data de început și data de încheiere (+ %{extra} de zile) solicitată',
          dates_not_in_range_in_line:
            'linia %{lineId} : livrarea efectivă trebuie să fie între data de început și data de încheiere solicitată',
          dates_not_in_range_with_extra_in_line:
            'linia %{lineId} : livrarea efectivă trebuie să fie între data de început și data de încheiere (+ %{extra} de zile) solicitată',
          incorrect_start_date: 'Data de începere incorectă',
          incorrect_end_date: 'Data de încheiere incorectă',
          price_higher_than_tolerance:
            'Noul pret poate fi majorat cu cel mult %{greaterThan}% din prețul actual.',
          price_lower_than_tolerance:
            'Noul pret poate fi redus doar cu %{lessThan}% din prețul actual.',
          item_quantity:
            'Cantitatea livrată este mai mare decât cantitatea de linie pentru linia %{lineId}. Vă rugăm să o remediați înainte de a trimite',
          item_quantity_lower:
            'Cantitatea livrată este mai mică decât cantitatea de linie pentru linie %{lineId}. Vă rugăm să o remediați înainte de a trimite',
          invalid_quantity_value:
            'Valoarea cantității livrate introdusă este nevalidă',
          actual_date_mandatory_line:
            'Data reală de livrare lipsește pentru linie %{lineId}',
          end_date_must_be_in_future:
            'Data de încheiere solicitată trebuie să fie în viitor sau egală cu data de astăzi.',
          delivery_dates_inconsistency_line:
            'Datele de livrare sunt inconsecvente pentru linie %{lineId}',
          missing_requested_start_or_end_dates_line:
            'Data de început sau de încheiere a perioadei dorite lipsește pentru linia %{lineId}',
          start_date_must_be_in_future:
            'Data de începere solicitată trebuie să fie în viitor sau egală cu data de astăzi.',
          dates_overlap:
            'Data de început și data de încheiere solicitate nu sunt valide.',
        },
      },
      invoice_matching: {
        invoiceHoldDecisionTask: {
          title: 'Reconciliere factură',
          subtitle: 'Vă rog să aprobați sau respingeți factura de mai jos:',
          cockpit: {
            title: 'Aprobă sau respinge factura',
            button: {
              approve: 'Aprobă',
              reject: 'Respinge',
            },
            nwayMatchingOfTheinvoice: 'Reconciliere',
            invoiceCluster: 'Documente implicate în reconciliere',
            comment:
              'Este necesar să introduceți un comentariu pentru a debloca butoanele Aprobă / Respinge:',
            invoiceClusterDetails:
              'Factura, notele de intrare-recepție asociate (legate prin identificator comandă) și facturi anterioare acceptate pe aceleași note de intrare-recepție.',
            i2oInvoiceClusterDetails:
              'Facturi, comanda asociate și facturi anterioare acceptate pe aceleași comandă.',
            raceConditionOnInvoiceApproval:
              'Ați acceptat această factură, dar alt utilizator a reconciliat între timp o parte din liniile notei de intrare-recepție, cauzând un conflict. Este necesar să o acceptați/respingeți din nou.',
            i2oRaceConditionOnInvoiceApproval:
              'Ați acceptat această factură, dar alt utilizator a reconciliat intre timp o parte din liniile notei de comandă, cauzând un conflict. Este necesar sa o acceptați/respingeți din nou.',
            incompleteCluster:
              'Reconcilierea poate fi incompletă deoarece toate documentele implicate nu au fost disponibile inainte de expirarea termenului limită (lipsa note de intrare-recepție).',
            i2oIncompleteCluster:
              'Reconcilierea poate fi incompletă deoarece toate documentele implicate nu au fost disponibile inainte de expirarea termenului limită (lipsa comandă).',
            usedExchangeRate: 'Curs valutar utilizat în reconciliere:',
            element: 'Element',
            rules: 'Reguli',
            acceptedMinusInvoiced: 'acceptat - deja facturat',
            unitaryNetPriceAlgo: 'preț net = brut - discount + taxa verde',
            match: 'Reconciliere',
            quantityRule:
              'Cantitatea facturată <= suma cantităților recepționate reconciliate',
            i2oQuantityRule:
              'Cantitatea facturată <= suma cantităților comandate',
            unitaryPrice: 'Pret unitar',
            unitaryPriceRule:
              'Pret unitar facturat <= preț unitar net din nota de intrare-recepție, taxă inclusă',
            i2oUnitaryPriceRule:
              'Pret unitar net facturat <= preț unitar din comandă',
            correcting: 'Corectând',
            correctionChain:
              'Factura comercială pentru a corecta și toate facturile sale corective',
            errors: {
              PartialNwayMatchingException:
                'Ați obținut o potrivire parțială n-way din cauza erorilor de pe liniile de facturare:',
              InvoiceLineWithNegativeQuantity:
                'Linie de factură cu cantitate negativă:% {invoiceId} la linie:% {lineId}',
              CorrectiveInvoiceWithNoReferencedInvoiceId:
                'Factură corectivă fără ID de referință pe cea de corectat:% {id}',
              CorrectiveWithoutSameOrderRefAsCommercial:
                'Corectiv fără aceeași referință de comandă ca reclamă:% {correctiveId},' +
                '%{correctiveOrderId} - %{commercialId}, %{commercialOrderId}',
              RecadvsWithDuplicateID:
                'Primiți sfaturi în clusterul de potrivire n-way cu același ID!',
              RecadvWithDuplicateLineID:
                'Sfaturi de primire cu ID de linie duplicat',
              InvoiceWithDuplicateLineID: 'Factură cu ID de linie duplicat',
              InvoiceWithNoRequiredTopOrderRef:
                'Factură fără referința obligatorie (de sus) a comenzii!',
              clusteringPeriodElapsed:
                'Nu mai așteptați sfaturile de primire din cauza perioadei trecute!',
              i2oClusteringPeriodElapsed:
                'Nu mai așteptați sfaturile de primire din cauza perioadei trecute!',
              waitingForCorrectedInvoicePeriodElapsed:
                'Nu mai așteptați corectarea facturii: durata scursă!',
              waitingForRecadvPeriodElapsed:
                'Nu mai așteptați sfaturile de primire: durata scursă!',
              i2oWaitingForOrderPeriodElapsed:
                'Nu mai așteptați sfaturile de primire: durata scursă!',
            },
            wrongSgrTaxes:
              'Articolele au fost perfect potrivite dar unele taxe SGR din liniile de factură nu se potrivesc cu cele din avizele de primire',
          },
        },
        cancellationDecisionTask: {
          title: 'Anulare factură',
          subtitle:
            'Vă rog să aprobați sau să respingeți factura de storno de mai jos:',
          cockpit: {
            title: 'Aprobă au respinge factura de storno',
            button: {
              cancelInvoice: 'Aprobă stornare',
              rejectCancellation: 'Respinge stornare',
            },
            invoiceAcceptance:
              'Această factură a fost %{acceptance} în data %{date} de către %{user}',
            automaticInvoiceAcceptance:
              'Această factură a fost automat %{acceptance} în data %{date}.',
            accepted: 'acceptată',
            rejected: 'respinsă',
            withTheComment: 'cu comentariul:',
            invoiceNotFound:
              'Factura de storno (referință: %{ref}) nu a fost gasită.',
            cancelInvoiceWithNoRef:
              'Această factură de storno nu are referinta la factura pe care o storneaza.',
            comment: 'Comentariu',
            theCancelInvoice: 'Factura storno',
            theInvoiceToCancel: 'Factura de stornat',
            theCancelInvoiceDetails:
              'Detalii ale facturii storno și ale țintei sale',
          },
        },
      },
    },
    uploadFiles: {
      uploadBox:
        "Drag'n'Drop fișierul ce va fi atașat sau <span class='filepond--label-action'> Browse </span>",
      preparing: 'Pregătire',
      ready: 'Gata',
      clickToRemove: 'Click pentru ștergere',
      clickToCancel: 'Click pentru anulare',
      fileTooLarge: 'Fișierul este prea mare',
      maxFileSizeIs: 'Dimensiunea maximă a fișierului este {filesize}',
      shortFileTooLarge: 'Prea mare',
      shortMaxFileSizeIs: 'Dimensiunea maximă este {filesize}',
      uploadError: 'Eroare în timpul încărcării',
      loadError: 'Eroare în timpul descărcării',
      loadInitError: 'Obținerea descrierii fișierului a eșuat',
      tapToRetry: 'Faceți clic pe cerc pentru a încerca din nou',
      uploadRefreshInfo:
        'Fișierele încărcate recent vor fi disponibile în lista de atașamente în curând. Utilizați butonul de reîncărcare date.',
      successfullyCreated:
        'Fișierul a fost încărcat! Ar trebui să fie disponibil în lista de atașamente în curând',
      removeFile: 'Fișierul a fost șters',
      uploadInitError:
        'Funcționalitatea de încărcare a fișierelor nu e disponibilă.',
      maxFilesReached: 'Limita maximă de fișiere atașate a fost atinsă.',
    },
    ubl: {
      packageMarkerID: 'EAN-128 sau alt format',
      packagingTypeCode: {
        pallet: 'palet',
        box: 'cutie',
        carton: 'carton',
      },
      payment_means_code: {
        bank_transfer: 'Transfer bancar',
        cash: 'Numerar',
        collection: 'Încasare',
      },
      payment_terms_settlement_period_code: {
        day: 'D - zi',
        weekDay: 'WD - zi lucratoare',
        month: 'M - luna',
        year: 'Y - an',
      },
    },
    analytics: {
      dashboardMenuGroup: 'Tablouri de bord',
      analytics: 'Analize',
      activitySummary: 'Rezumatul activității',
      timePeriod: 'Perioadă de timp',
      monthly: 'lunar',
      daily: 'zilnic',
      monthlySummary: 'Rezumat activitate lunară',
      dailySummary: 'Rezumat activitate zilnică',
      month: 'Luna',
      day: 'Selectionează data',
      totalProcessed: 'Documente procesate',
      processedByType: 'Documente procesate / tip document',
      tableSummary:
        'Afișează numărul total al documentelor primite sau trimise în perioada selecționată.',
      processedByCompany: 'Documente procesate / companie',
      documentType: 'Selecționează tip document',
      chartSummary: 'Afișează doar primele 10 companii.',
      type: 'Tip document',
      documentsReceived: 'Documente primite',
      documentsSent: 'Documente trimise',
    },
    audit_trail: {
      show: 'Detalii procesare',
      title: 'Detalii procesare',
      categories: {
        FLOW_STARTED: 'Start procesare',
        DOCUMENT_RECEIVED: 'Document recepţionat',
        DOCUMENT_SENT: 'Document trimis',
        THIRD_PARTY_ACKNOWLEDGMENT: 'Terţă parte notificată',
        DOCUMENT_REPRESENTATION_GENERATED: 'Document interpretat',
        DOCUMENT_ERROR: 'Eroare în document',
        DOCUMENT_VALIDATION: 'Validare document',
        WAIT_FOR_USER_ACTION: 'Așteaptă acţiune utilizator',
        USER_ACTION_DONE: 'Acţiune finalizată',
        USER_ACTION_DELETED: 'Acţiune anulată',
        FLOW_END: 'Procesare finalizată',
        INCIDENT: 'Incident',
      },
      types: {
        P2P_INVOICE_FORMAT_ERROR: 'Format eronat!',
        P2P_INVOICE_REJECTED_BY_RECIPIENT: 'Respinsă de destinatar',
        P2P_INVOICE_PAID_BY_RECIPIENT: 'Platită',
        P2P_INVOICE_RECEIVED_BY_RECIPIENT: 'Primită de cumpărător',
        P2P_INVOICE_RECEIVED_BY_BSP: 'Primită de Business Service Provider',
        P2P_INVOICE_REJECTED_BY_BSP: 'Respinsă de Business Service Provider',
        P2P_INVOICE_CONTENT_ERROR: 'Conţinut eronat!',
        P2P_INVOICE_CONTENT_WARNING: 'Avertisment de conținut!',
        P2P_INVOICE_SENT: 'Trimisă la destinatar',
        P2P_INVOICE_RECEIVED_BY_DX: 'Recepţionată de DocProcess',
        P2P_INVOICE_END: 'Procesare finalizată',
        P2P_INVOICE_WAIT_STATUS: 'Așteaptă confirmare primire cumpărător',
        P2P_INVOICE_ACCEPTED_BY_RECIPIENT: 'Acceptată de cumpărător',
        P2P_INVOICE_WAITING_FOR_RECIPIENT: 'Așteaptă răspuns cumpărător',
        P2P_INVOICE_SENT_TO_REGULATOR:
          'Factura trimisa catre autoritatea fiscală',
        P2P_INVOICE_WAIT_STATUS_FROM_REGULATOR:
          'Așteaptă răspuns autoritatea fiscală',
        P2P_INVOICE_STATUS_FROM_REGULATOR: 'Răspuns autoritatea fiscală',
        P2P_INVOICE_PARTIALLY_PAID_BY_RECIPIENT: 'Plătit parțial de destinatar',
        P2P_INVOICE_RECEIVED_AND_VALIDATED_BY_BSP:
          'Primit și validat de Business Service Provider',

        P2P_INVOICE_UNDER_QUERY: 'Asteptam informatii suplimentare',
        P2P_INVOICE_CONDITIONALLY_ACCEPTED: 'Acceptat condiționat',
        P2P_INVOICE_IN_PROCESS: 'Fiind prelucrat de destinatar',
        P2P_INVOICE_SET_GROUP: 'Grupul de facturi a fost setat',

        P2P_ORDER_CONTENT_ERROR: 'Conţinut eronat!',
        P2P_ORDER_FORMAT_ERROR: 'Format eronat!',
        P2P_ORDER_RECEIVED_BY_DX: 'Recepţionată de DocProcess',
        P2P_ORDER_SENT: 'Trimisă la furnizor',
        P2P_ORDER_RECEIVED_BY_RECIPIENT: 'Primită de furnizor',
        P2P_ORDER_END: 'Procesare finalizată',

        P2P_ORDER_NOT_CHANGED: 'Comanda nu a fost modificată de furnizor',
        P2P_ORDER_CHANGED: 'Comanda a fost modificată de furnizor',
        P2P_ORDER_RECEIVED_BY_SUPPLIER: 'Comanda a fost primită de furnizor',
        P2P_ORDER_REJECTED_BY_RECIPIENT:
          'Comanda a fost respinsă de cumpărător',
        P2P_ORDER_ACCEPTED_BY_RECIPIENT:
          'Comanda a fost acceptată de cumpărător',
        P2P_ORDER_ACCEPTED_BY_SUPPLIER: 'Comanda a fost acceptata de furnizor',
        P2P_ORDER_REJECTED_BY_SUPPLIER: 'Comanda a fost respinsă de furnizor',

        P2P_RECADV_CONTENT_ERROR: 'Conţinut eronat!',
        P2P_RECADV_FORMAT_ERROR: 'Format eronat!',
        P2P_RECADV_RECEIVED_BY_DX: 'Recepţionat de DocProcess',
        P2P_RECADV_SENT: 'Trimis la furnizor',
        P2P_RECADV_RECEIVED_BY_RECIPIENT: 'Primit de furnizor',
        P2P_RECADV_END: 'Procesare finalizată',

        P2P_DESADV_CONTENT_ERROR: 'Conţinut eronat!',
        P2P_DESADV_FORMAT_ERROR: 'Format eronat!',
        P2P_DESADV_RECEIVED_BY_DX: 'Recepţionat de DocProcess',
        P2P_DESADV_SENT: 'Trimis la cumpărător',
        P2P_DESADV_RECEIVED_BY_RECIPIENT: 'Primit de cumpărător',
        P2P_DESADV_END: 'Procesare finalizată',

        USER_TASK_CREATED: {
          invoiceHoldDecisionTask: 'Necesită reconciliere manuală',
          cancellationDecisionTask: 'Necesită anulare manuală',
          costCenterAllocationTask:
            'Nu s-au alocat integral liniile de factură! Este necesara alocarea manuala',
          invoiceValidationTask: 'Se solicită validarea manuală',
          manualEnrichment: 'Se solicită îmbogățirea manuală a facturii',
          manualIdentification:
            'Se solicită selecția manuală a grupului de facturi',
          orderSelection: 'Se solicită selecția manuală a comenzii',
        },
        USER_TASK_COMPLETED: {
          invoiceHoldDecisionTask: 'Reconciliere finalizată',
          cancellationDecisionTask: 'Anulare finalizată',
          costCenterAllocationTask: 'Alocare manuala efectuata',
          invoiceValidationTask: 'Validare manuală efectuată',
          manualEnrichment: 'Îmbogățirea manuală a facturii efectuată',
          manualIdentification:
            'Selectarea manuală a grupului de facturi efectuată',
          orderSelection: 'Selecția manuală a comenzii este finalizată',
        },
        USER_TASK_DELETED: {
          invoiceHoldDecisionTask: 'Reconciliere respinsă',
          cancellationDecisionTask: 'Acţiune de anulare ștearsă',
          invoiceValidationTask: 'Sarcina de validare a fost reatribuită',
          manualEnrichment: 'Îmbogățirea manuală a facturii a fost ștearsă',
          manualIdentification:
            'Selectarea manuală a grupului de facturi a fost ștearsă',
          orderSelection: 'Selectarea manuală a comenzii a fost ștearsă',
        },

        I2PR_STARTED: 'Start reconciliere cu aviz de recepţie',
        I2PR_SUCCESS: 'Reconciliere aprobată',
        I2PR_ERROR: 'Eroare de reconciliere',
        I2PR_REJECTED: 'Reconciliere respinsă',
        I2PR_SKIPPED: 'Reconciliere ignorată',

        I2O_STARTED: 'Reconciliere începută cu comenzi',
        I2O_SUCCESS: 'Reconciliere aprobată',
        I2O_ERROR: 'Eroare de reconciliere',
        I2O_REJECTED: 'Reconciliere respinsă',
        I2O_SKIPPED: 'Reconciliere ignorată',

        ALLOCATION_STARTED: 'Incercare alocare automata',
        ALLOCATION_DONE: 'Alocare inregistrata',
        AUTOMATIC_ALLOCATION_DONE: 'Alocarea automata efectuata cu succes',

        PROCESSED: 'Procesat',
        ACCOUNT_CHECKED: 'Cont verificat',
        DELIVERED_FROM_FORMAT_ERROR: 'Eroare format',
        REJECTED_BY_CUSTOMER: 'Respins de cumpărător',
        RECEIVED_BY_CUSTOMER: 'Recepționat de destinatar',
        VALIDATED: 'Validat',
        DELIVERED_FROM_CONTENT_ERROR: 'Conţinut eronat',
        DELIVERED: 'Trimis',
        NOTIFIED: 'Notificat',
        RECEIVED_BY_DX: 'Recepţionat de DocProcess',
        ACCEPTED_BY_CUSTOMER: 'Acceptat de cumpărător',
        PENDING_TO_CUSTOMER: 'Așteaptă decizie cumpărător',
      },
      noTrail: 'Fără proces de audit.',
      descriptions: {},
    },
    messagesCenter: {
      state: {
        DRAFT: 'Draft',
        PUBLISHED: 'Publicat',
        title: 'Stat',
        help: 'Vizibilitatea postării. Postarea poate fi creată ca DRAFT și nu va fi vizibilă până când o modificare o setează la PUBLICAT. Setarea stării sale la PUBLICAT în momentul creării o va face vizibilă imediat',
      },
      topic: {
        title: 'Subiect',
        help: 'Setează un subiect pentru a vă identifica mesajul. Acest subiect este afișat în meniul de notificări al utilizatorului',
      },
      language: {
        title: 'Limba',
        help: 'Vizibilitatea mesajelor ține cont de alegerea limbii a utilizatorului. Deci, puteți specializa mesajul în funcție de limbă',
      },
      link: {
        text: 'Mai multe informații',
        href: 'Mai multe informații URL',
        knowledgeBase: 'URL bază de cunoștințe',
        public: 'Adresa URL publică',
        help: 'Link opțional "Mai multe informații" către o adresă URL publică externă (URL bază de cunoștințe = fals) sau un URL către baza de cunoștințe (URL bază de cunoștințe = adevărat)',
        show: 'Ascunde acest mesaj',
      },
      type: {
        BANNER: 'Banner',
        DOWNTIME: 'Downtime',
        MARKETING: 'News',
        GENERAL: 'Operations',
        title: 'Tip',
        help: 'Categoria mesajului. Un mesaj <<Operations>> sau <<Marketing>> este vizibil pe pagina principală pentru toți utilizatorii.',
      },
      content: {
        title: 'Conținutul mesajului',
        help: 'Conținutul mesajului este limitat la 300 de caractere și poate fi definit pe mai multe rânduri. Faceți clic pe butonul Afișare pentru a reda rezultatul',
      },
    },
  }, // End of Romanian DxMessages
  resources: {
    // Override Romanian react-admin reserved messages
    'management/company': {
      name: 'Companii',
    },
    'management/supplier': {
      name: 'Furnizori',
    },
    'management/formattype': {
      name: 'Tip format',
    },
    'management/dxrole': {
      name: 'Role',
    },
    'management/customer': {
      name: 'Clienti',
    },
    'management/productusage': {
      name: 'Produse Contractate',
    },
    'management/supportedproducts': {
      name: 'Produse Suportate',
    },
    'management/address': {
      name: 'Adrese',
    },
    'management/bufferaddress': {
      name: 'Import de adrese',
    },
    'management/contact': {
      name: 'Contact',
    },
    'management/dropdowndefinition': {
      name: 'Lista derulanta',
    },
    'management/dxproduct': {
      name: 'Produs DX',
    },
    'management/accountrelation': {
      name: 'Relații de cont',
    },
    'management/peppol': {
      name: 'Peppol',
    },
    'management/v2invoice': {
      name: 'V2 Facturi',
    },
    'management/v2order': {
      name: 'V2 Comenzi',
    },
    'management/v2receiptadvice': {
      name: 'V2 Note de intrare recepție',
    },
    'management/v2despatchadvice': {
      name: 'V2 Avize de expediție',
    },
    'efactura/monitoring/error': {
      name: 'Efactura Erori Continut',
    },
    'efactura/monitoring/rejected': {
      name: 'Efactura Documente Rejectate',
    },
    usermgmt: {
      name: 'Conturi',
    },
    customers: {
      name: 'Clienți',
      fields: {
        id: 'id',
        name: 'Nume',
      },
    },
    issuers: {
      name: 'Emitenți',
      fields: {
        id: 'id',
        name: 'Nume',
      },
    },
    invoice: {
      name: 'Facturi',
    },
    despatchAdvice: {
      name: 'Avize de expediție',
    },
    recadv: {
      name: 'Note de intrare recepție',
    },
    feedbackMessage: {
      name: 'Mesaj de proces',
    },
    attachment: {
      name: 'Atașamente',
    },
    order: {
      name: 'Comenzi',
    },
    delfor: {
      name: 'Planificari livrare',
    },
    cntcnd: {
      name: 'Contract',
    },
    catlog: {
      name: 'Catalog',
    },
    documents: {
      name: 'Toate documentele',
    },
    waybill: {
      name: 'CMR',
    },
    webWaybill: {
      name: 'CMR',
    },
    webInvoice: {
      name: 'Facturi',
    },
    webDespatchAdvice: {
      name: 'Avize de expediție',
    },
    webReceiptAdvice: {
      name: 'Note de intrare recepție',
    },
    webOrder: {
      name: 'Comenzi',
    },
    userprofiles: {
      name: 'Profil utilizator',
    },
    tasks: {
      name: 'Sarcinile mele',
      list: {
        title: 'Sarcinile mele',
      },
      edit: {
        title: 'Sarcina ta',
      },
    },
    'configuration-message': {
      name: 'Mesaje',
    },
  }, // End of override Romanian react-admin reserved messages
  Forbidden: 'Interzis',
  Unauthorized: 'Acces neautorizat',
  NoDocsFound: 'Ne pare rău, nu s-au găsit documente',
};
